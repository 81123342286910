import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Stepper,
  Step,
  Checkbox,
  InputAdornment,
  IconButton,
  CheckboxProps,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  MenuItem,Select
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, makeStyles, styled, Theme, ThemeProvider } from "@material-ui/core/styles";
import { 
  calendarIcon, 
  upload, 
  uploadIcon, 
  check, inAndOut, addIcon, seperator1,thumb, keyboard, arrowStep3,arrow, smallInfoIcon, createGameFolder} from "./assets";
import { 
  Add, 
  Close,
  InfoOutlined
 } from "@material-ui/icons";
import InputField from "../../../components/src/InputField.web";
import DropDownGroup from "../../../components/src/DropDownGroup";
import ModalContent from "../../../components/src/ModalContent";
import DatePicker from "react-multi-date-picker";
import Share from "../../share/src/Share.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
  overrides: {
    MuiButton: {
      contained: {
      },
      root: {
        textTransform: "none"
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: ""
      }
    },
    MuiStepper: {

      root: {
        padding: "2px",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "#252527"
      }
    },
    MuiTypography: {
      root: {
        "&.infoTitle": {
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 400,
          lineHeight: "24.69px",
          letterSpacing: "-0.005em",
          textAlign: "center",
          color: "#CFD1D4"

        },
        "&.inputLabel": {
          color: '#CFD1D4',
          gap: 8
        }
      }
    },

    MuiDialog: {
      paperWidthSm: {
        maxWidth: 800
      }
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        "&:first-child": {
          paddingTop: 0
        }
      }
    },

  }
});
// Customizable Area End

import BulkUploadingController, {
  Props,
  configJSON,
  baseURL,
  Keyboard,
} from "./BulkUploadingController";
import { sampleAvatar } from "../../../../packages/blocks/contentmanagement/src/assets";
import { Account, ProductItem } from "../../../../packages/blocks/share/src/ShareController.web";




export default class BulkUploading extends BulkUploadingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  userType = localStorage.getItem("typeSport");

  renderErrorMsg = (errorMsg: any) => {
    return (<>{!!errorMsg && <Box style={{ display: "flex", justifyContent: "flex-end" }}>
      {
        <span
          className="validationMsg"
          style={{
            visibility: errorMsg
              ? "visible"
              : "hidden",
            fontFamily: 'Poppins',
            color: '#FF1919',
            fontWeight: 400,
            fontSize: '1rem',
          }}
        >
          {errorMsg
            ? errorMsg
            : "error"}
        </span>
      }
    </Box>}</>)
  }
  renderStepOneForm = () => {

    if (this.userType === 'eSports' && this.props.uploadType === 'gameFolder') {
      return (
        <>
          <Box className="wrapStepOneForm">
            <InputItem>
              <div className="flex3">
                <Typography variant="body1" className="inputLabel optional">
                Custom Name{" "}
                <img src={smallInfoIcon} width={24} height={24} alt='' />
                {" "}
                <Typography component="span" className="optional-text">(Optional)</Typography>
              </Typography>
              </div>
              
              <div className="flex4">
                <InputField
                name="customName"
                type="text"
                value={this.state.createGameFolderForm.customName}
                className="MuiFilledInput-underline inputField"
                isHideMsgError={true}
                onChange={this.handleCreateGameFolderFormChange}
              /></div>
            </InputItem>

            <InputItem>
              <Typography variant="body1" className="inputLabel" >
                My Team Name
              </Typography>
              
              <InputField
                testID="teamNameInput"
                name="myTeamName"
                type="text"
                value={this.state.createGameFolderForm.myTeamName}
                className="MuiFilledInput-underline inputField"
                isHideMsgError={true}
                onChange={this.handleCreateGameFolderFormChange}
              />
            </InputItem>
            {this.renderErrorMsg(this.state.errorMessageOn.myTeamName)}
            <InputItem >
              <Typography variant="body1" className="inputLabel">Opponent's Team Name</Typography>
              
              <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                <InputField
                  name="opponentsTeamName"
                  type="text"
                  value={this.state.createGameFolderForm.opponentsTeamName}
                  className="MuiFilledInput-underline opponentInputField"
                  onChange={this.handleCreateGameFolderFormChange}
                  isHideMsgError={true}
                  placeholder="Enter Opponent's Team Name"
                />

                <Button onClick={this.onRandomsClick} variant="outlined" style={{
                  color: "#FF881A", borderColor: "FF881A", padding: "10px 16px", borderRadius: 10, height: 55, width: 126, fontSize: 16, fontFamily: "Montserrat"
                }}>
                  Randoms
                </Button>
              </div>
            </InputItem>
            {this.renderErrorMsg(this.state.errorMessageOn.opponentsTeamName)}
            <InputItem>
              <Typography variant="body1" className="inputLabel optional">
                Game Due Date{" "}
                <img src={smallInfoIcon} width={24} height={24} alt='' />
                {" "}
                <Typography component="span" className="optional-text">(Optional)</Typography>
              </Typography>
              
              <DatePicker
                data-testid="create-game-folder-due-date"
                format="DD/MM/YYYY"
                minDate={new Date()}
                value={this.state.createGameFolderForm.gameDueDate}
                onChange={this.handleDateInputField}
                placeholder="DD/MM/YYYY"
                render={<CustomInput />}
                inputClass="custom-input"
                arrow={false}
              />
            </InputItem>
            <InputItem className="pd5">
            <Typography variant="body1" className="inputLabel flex3">Game Type</Typography>
            <div className="flex4">
                <DropDownGroup options={this.state.gameTypeOptions} isStep1={true} placeholder={"Select Game Type"}handleChangeFunction={this.handleChangeGameType} name="gameType" value={this.state.createGameFolderForm.gameType}/>
                </div>
            </InputItem>
            
            {this.renderErrorMsg(this.state.errorMessageOn.gameType)}
          </Box>
        </>
      )
    }
    return (
      <>
        <Box style={{ display: "flex", flexDirection: "column", padding: "16px 66px", gap: 8 }}>
          <InputItem >
          <div className="flex3">
                <Typography variant="body1" className="inputLabel optional">
                Custom Name{" "}
                <img src={smallInfoIcon} width={24} height={24} alt='' />
                {" "}
                <Typography component="span" className="optional-text">(Optional)</Typography>
              </Typography>
              </div>
            <div className="flex4">
              <InputField
                name="customName"
                type={"text"}
                value={this.state.formValue.customName}
                className="MuiFilledInput-underline inputField"
                onChange={this.changeHandler}
                isHideMsgError={true}
                placeholder="Enter Custom Name"
              />
            </div>
          </InputItem>
          {this.userType !== "eSports" && 
          <InputItem >
            <Typography variant="body1" className="inputLabel flex3">Game Score</Typography>
            <div style={{display:"flex",flex:5,justifyContent:"space-between",alignItems:"center"}}>
              <InputField
                data-testID="gameScore1"
                name="gameScore1"
                type={"text"}
                value={this.state.formValue.gameScore1}
                className="MuiFilledInput-underline gameScore1"
                isHideMsgError={true}
                onChange={this.changeHandler}
                placeholder="Home"

              />
              <span style={{ color: 'white' }}>&#8212;</span>
              <InputField
                data-testID="gameScore2"
                name="gameScore2"
                type={"text"}
                value={this.state.formValue.gameScore2}
                className="MuiFilledInput-underline gameScore2"
                isHideMsgError={true}
                onChange={this.changeHandler}
                placeholder="Opponents"
              />
            </div>
          </InputItem>
            
          }
          {this.userType !== "eSports" && this.renderErrorMsg(this.state.errorMessageOn.gameScore)}
          <InputItem >
            <Typography variant="body1" className="inputLabel flex3">My Team Name</Typography>
            <div className="flex4">
              <InputField
                testID="teamNameInput"
                name="myTeamName"
                type={"text"}
                value={this.state.formValue.myTeamName}
                className="MuiFilledInput-underline inputField"
                isHideMsgError={true}
                onChange={this.changeHandler}
              />
            </div>
          </InputItem>
          {this.renderErrorMsg(this.state.errorMessageOn.myTeamName)}
          <InputItem >
            <Typography style={{ flex: 3 }} variant="body1" className="inputLabel">Opponent's Team Name</Typography>
            <div style={{ display: "flex", flex: 4, gap: 4, alignItems: "center" }}>
              <InputField
                testID="opponentTeamNameInput"
                name="opponentsTeamName"
                type={"text"}
                value={this.state.formValue.opponentsTeamName}
                className="MuiFilledInput-underline opponentInputField"
                onChange={this.changeHandler}
                isHideMsgError={true}
                placeholder="Enter Opponent's Team Name"
              />
              <Button onClick={this.onRandomsClick} variant="outlined" style={{
                color: "#FF881A", borderColor: "FF881A", padding: "10px 16px", borderRadius: 10, height: 55, width: 126, fontSize: 16, fontFamily: "Montserrat"
              }}>
                Randoms
              </Button>
            </div>
          </InputItem>
          {this.renderErrorMsg(this.state.errorMessageOn.opponentsTeamName)}
          <InputItem>
              <div className="flex3">
                <Typography variant="body1" className="inputLabel optional">
                Game Date{" "}
                {this.state.userType === "eSports" && (
                  <>
                    <img src={smallInfoIcon} width={24} height={24} alt='' />
                    {" "}
                    <Typography component="span" className="optional-text">(Optional)</Typography>
                  </>
                )}
              </Typography>
              </div>
            <div className="flex4">
              <DatePicker
                data-testid="updateDate"
                format="DD/MM/YYYY"
                value={this.state.formValue.uploadDate}
                onChange={this.handleDateInputField}
                placeholder="DD/MM/YYYY"
                render={<CustomInput placeholder="DD/MM/YYY"/>}
                inputClass="custom-input"
                arrow={false}
              />
            </div>
          </InputItem>
          {this.renderErrorMsg(this.state.errorMessageOn.dateOfBirth)}
          <InputItem className="pd5">
            <Typography variant="body1" className="inputLabel flex3">Game Type</Typography>
            <div className="flex4">
              <DropDownGroup testId="dropDownGameType" options={this.state.gameTypeOptions} isStep1={true} placeholder={"Select Game Type"} handleChangeFunction={this.handleChangeGameType} name="gameType" value={this.state.formValue.gameType} />
            </div>
          </InputItem>
          {this.renderErrorMsg(this.state.errorMessageOn.gameType)}
          {this.userType !== "eSports" && <div data-testid="switchButtons" style={{ marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "center", gap: 8 }}>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <SwitchButtonMaskBox>
                <Box
                  className="mask"
                  style={{
                    transform: `translateX(${this.state.gamePlace === "Home" ? 0 : "64px"})`
                  }}
                />
                <Button
                  data-testid="homeBtn"
                  disableRipple
                  variant="text"
                  style={{ color: this.state.gamePlace === "Home" ? "#1D1D1F" : "#CFD1D4" }}
                  onClick={this.onGamePlaceHome}
                >
                  Home
                </Button>
                <Button
                  disableRipple
                  variant="text"
                  style={{ color: this.state.gamePlace === "Away" ? "#1D1D1F" : "#CFD1D4" }}
                  onClick={this.onGamePlaceAway}
                >
                  Away
                </Button>
              </SwitchButtonMaskBox>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <SwitchButtonMaskBox>
                <Box
                  className="mask"
                  style={{
                    transform: `translateX(${this.state.gameStatus === "Win" ? 0 : "64px"})`
                  }}
                />
                <Button
                  data-test-id="winBtn" disableRipple
                  variant="text"
                  style={{ color: this.state.gameStatus === "Win" ? "#1D1D1F" : "#CFD1D4" }}
                  onClick={this.onGameStatusWin}
                >
                  Win
                </Button>
                <Button
                  disableRipple
                  variant="text"
                  style={{ color: this.state.gameStatus === "Lose" ? "#1D1D1F" : "#CFD1D4" }}
                  onClick={this.onGameStatusLose}
                >
                  Lose
                </Button>
              </SwitchButtonMaskBox>
            </Box>
          </div>}
        </Box>
      </>
    )
  }

  renderStepOneUploadRow = () => {
    if (this.props.uploadType === 'gameFolder') {
      return <Typography component='span' className="sub-text">
        Create Game Folder is different from normal Create Folder. This creates a folder which contains the POV's of all the players in that game.
      </Typography>
    }
    else if (this.props.editGameData?.editVideoId!=null) {
      return <></>
    }
    return (
      <>
        <span
          style={{
            color: '#676767',
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24.69px",
            letterSpacing: "-0.005em",

          }}>Available Formats (3G2 | 3GP | AVI | MKV | IMF | MP4 | MXF |<br /> MOV | WebM | WMV)
        </span>
        {this.state.filesWeb.length === 0 ?
          <>
            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'center' }}>
              <input
                type="file"
                style={{ display: 'none' }}
                data-testid="filePicker"
                accept=".3g2,.3gp,.avi,.mkv,.imf,.mp4,.mxf,.mov,.webm,.wmv"
                onChange={this.onChangeFile}
                id="upload-video-input"
                multiple={this.state.isMultiple}
              />
              <label htmlFor="upload-video-input">
                <Button
                  variant="outlined"
                  style={{
                    color: "#FF881A",
                    borderColor: "#FF881A",
                    padding: "10px 16px",
                    height: 44,
                    borderRadius: 8,
                    width: 174,
                    fontFamily: "Montserrat"
                  }}
                  component="span"
                  endIcon={< Add />}
                >
                  Choose File
                </Button>
              </label>
              <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                <BpCheckbox data-testid="multipleFiles" value={this.state.isMultiple} checked={this.state.isMultiple} onChange={this.handleMultipleFiles} />
                <span style={{
                  color: '#CFD1D4',
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontWeight: 300,
                  lineHeight: "19px",

                }}>This upload contains<br /> multiple files</span>
              </div>

            </div>
            <div style={{display:'flex'}}>{this.renderErrorMsg(this.state.errorMessageOn.hasFileMsg)}</div>
          </> :
          this.state.filesWeb.map((file, index) =>
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}>

              <Button
                key={file.name}
                onClick={() => this.removeFileWeb(index)}
                variant="outlined"
                data-testid={`selectedFile-${index + 1}`}
                style={{
                  color: "#FF881A",
                  borderColor: "#FF881A",
                  padding: "10px 16px",
                  height: 44,
                  width: "auto",
                  textOverflow: "ellipsis",
                  overflow: "hidden",

                  fontFamily: "Montserrat"
                }}
                component="span"
                endIcon={< Close />}>
                {file.name}
              </Button>
            </div>
          )
        }
        <span
          style={{
            color: '#676767',
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24.69px",
            letterSpacing: "-0.005em",
          }}>Limited Upload Size:
          <span style={{
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "24.69px",
            letterSpacing: "-0.005em",
            color: "#FF881A"
          }} >5GB</span>
        </span>
      </>
    )
  }

  renderSportStep1 = () => {
    return (
      <div>
        <div style={{ width: "640px", marginLeft: "auto", marginRight: "auto", marginTop: 16 }}>
         {this.renderStep1Header()}
          <div style={{...webStyle.uploadRow,...(this.props.uploadType === "gameFolder" ? {gap: '48px'} : {})}}>
            {!this.props.editGameData?.editVideoId&&<img src={this.props.uploadType === "gameFolder" ? createGameFolder : upload} height={128} width={128} />}
            <Box style={webStyle.uploadBtn}>
              {this.renderStepOneUploadRow()}
            </Box>
          </div>
        </div>
        <img src={seperator1} width={"100%"} />
        <div>
        { !this.props.editGameData?.editVideoId && <Box style={webStyle.selectFolderBlock}>
            <Typography variant="body1" className="inputLabel" >Select Folder Path:<span style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "21px",
              color: '#676767'
            }}>
              {this.props.uploadType === 'gameFolder' ? "(Select where you would want this folder to be opened)" : "(Select where you would want this game recording)"}</span>
              <span style={{ color: "red" }}>*</span>
            </Typography>
            
            <div data-testid="select-folder"onClick={this.onSelectFolder}>
              <InputField
              name="folderPath"
              type={"text"}
              value={this.props.selectedPath}
              disabled
              placeholder="Please select a folder path"
              className="MuiFilledInput-underline inputFieldSelectFolderPath"
              isHideMsgError={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"    
                    >
                      <img src={uploadIcon} width={32} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            </div>
            {this.renderErrorMsg(this.state.errorMessageOn.pathMsg)}
          </Box>}
          
          {this.renderStepOneForm()}
        </div>
      </div>)
  }

  renderStep1Header = () => {
    const { editGameData, uploadType } = this.props;
    const { userType } = this;
  
    let headerTitle = "Upload Game"; // Default title
  
    if (editGameData?.editVideoId) {
      headerTitle = "Edit Game Data";
    } else if (uploadType === "gameFolder" && userType === "eSports") {
      headerTitle = "Create Game Folder";
    }
  
    return (
      <Typography variant="body2" className="infoTitle" align="center">
        {headerTitle}
      </Typography>
    );
  }
  

  renderLeftStep3=(row: ProductItem & { expanded: boolean },assigned:boolean,index:number)=>{
    return(
      <div style={{ width: '54%', display: "flex", flexDirection: "row" }}>
        <div className="divHeader" style={{ display: "flex" }}>
          <div className="avatar">G{index + 1}</div>
          <div
            className="divSubHeader"
            style={{ width: "400px", borderRadius: "14px", marginLeft: "-22px" }}
          >
            <div
              className="typography"
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "space-between",
                cursor: 'pointer'
              }}
              data-test-id="step-3-group-container"
              onClick={() => this.handleExpandStep3Group(Number(row.id))}
            >
              <div
                style={{
                  display: "flex",
                  paddingLeft: 30,
                  height: "38px",
                  alignItems: "center",
                }}
              >
                <span>{row.attributes.name}</span>
                <span style={{ color: "white" }}>(</span>
                <span
                  style={{
                    color: assigned ? "green" : "red",
                  }}
                >
                  {assigned ? "Assigned" : "Unassigned"}
                </span>
                <span style={{ color: "white" }}>)</span>
              </div>
              <div
                style={{
                  display: "flex",
                  marginRight: "14px",
                  alignItems: "center",
                }}
              >
                <img
                  src={arrowStep3}
                  alt="arrow"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  renderRightStep3=(row:any,assigned?:boolean)=>{
    return (
      <div style={{ width: "45%" }}>
        <div
          style={{
            padding: "0 6px",
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 10,
            borderBottom: "1px solid #FF881A",
            flexDirection: "row",
            backgroundColor: "#1D1D1F",
            alignItems: "center",
          }}
        >
          <div style={{ flex: "1" }}>
            <Select
              displayEmpty
              data-test-id="keyboard-group"
              value={row.attributes.keyboardGroup || ""}
              onChange={(e) => {
                this.updateGroupKeyboard(row.id,e.target.value)
              }}
              disableUnderline
              inputProps={{
                style: {},
                "aria-label": "Without label",
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    fontFamily: "Poppins",
                    backgroundColor: "black",
                    border: "1px solid orange",
                    top: "147px !important",
                    color: "white",
                  },
                },
              }}
              IconComponent={() =>
                <img
                  src={arrow}
                  alt="Arrow"
                  style={{ width: 20, height: 20 }}
                />
              }
              style={{
                width: "100%",
                fontFamily: "Poppins",
                color: "#676767",
              }}
            >
              <MenuItem
                style={{
                  fontFamily: "Poppins",
                  color: "#676767",
                }}
                value=""
              >
                Select Keyboard Type
              </MenuItem>
              {this.filterKeyboard().map((item: Keyboard) => {
                let keyBoardV = row.attributes.keyboard && row.attributes.keyboard.id === item.id
                return(
                <MenuItem
                  key={item.id}
                  style={{
                    fontFamily: "Poppins",
                    color: keyBoardV ? "#676767" : "white",
                    backgroundColor: keyBoardV ? "#orange" : "intial",
                  }}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              )})}
            </Select>
          </div>
        </div>
      </div>
    )
  }

  contentLeft=(item:any)=>{
    return(
      <div style={{ width: '54%', display: "flex", flexDirection: "row", marginTop: '8px', paddingLeft: '40px' }}>
        <div className="divHeader" style={{ display: "flex" }}>
          <div className="avatar">
            <img src={sampleAvatar} width={40} style={{ borderRadius: '50%' }} />
          </div>
          <div
            className="divSubHeader"
            style={{ width: "274px", borderRadius: "14px", marginLeft: "-22px" }}
          >
            <div
              className="typography"
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  paddingLeft: 30,
                  height: "38px",
                  alignItems: "center",
                }}
              >
                <span>{item.first_name + " " + item.last_name}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  contentRight=(row:any,item:any)=>{
    return(
      <div style={{ width: "45%", marginTop: '8px' }}>
              <div
                style={{
                  padding: "0 6px",
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: 10,
                  borderBottom: "1px solid #FF881A",
                  flexDirection: "row",
                  backgroundColor: "#1D1D1F",
                  alignItems: "center",
                }}
              >
                <div style={{ flex: "1" }}>
                  <Select
                    data-test-id="accountSelectTest"
                    displayEmpty
                    value={item?.keyboards[0]?.assignable_id || ""}
                    disableUnderline
                    onChange={(e) => {
                      this.filterDataKeyboard(item.id,e.target.value);
                    }}
                    inputProps={{
                      style: {},
                      "aria-label": "Without label",
                    }}
                    style={{
                      width: "100%",
                      fontFamily: "Poppins",
                      color: "#676767",
                    }}
                    IconComponent={() =>
                      <img
                        src={arrow}
                        alt="Arrow"
                        style={{ width: 20, height: 20 }}
                      />
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          border: "1px solid orange",
                          fontFamily: "Poppins",
                          color: "white",
                          backgroundColor: "black",
                          top: "147px !important",
                        },
                      },
                    }}
                  >
                    <MenuItem
                      style={{
                        color: "#676767",
                        fontFamily: "Poppins",
                      }}
                      value=""
                    >
                      Select Keyboard Type
                    </MenuItem>
                    {this.filterKeyboard().map((keyboard: Keyboard) => { 
                      let keyboardV= item.keyboard && item.keyboard.id === keyboard.id;
                      return(        
                      <MenuItem
                        style={{
                          fontFamily: "Poppins",
                          color: keyboardV ? "#676767" : "white",
                          backgroundColor: keyboardV ? "#orange" : "intial",
                        }}
                        value={keyboard.id}
                      >
                        {keyboard.name}
                      </MenuItem>
                    )})}
                  </Select>
                </div>
              </div>
            </div>
    )
  }

  renderRowStep3 = (row: ProductItem & { expanded: boolean }, index: number) => {
    let assigned = row.attributes.assigned;
    return (
    <div
      key={row.id}
      className="tablerow"
      style={{
        padding: "3px 20px",
        margin: "10px 0",
        flex: 1,
        backgroundColor: "#252527",
        borderRadius: 10,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: 'wrap'
      }}
    >
      {this.renderLeftStep3(row,assigned,index)}
      {this.renderRightStep3(row,assigned)}
      
      {row.attributes.accounts && row.expanded ?
        row.attributes.accounts.map((item: Account) => (
          <React.Fragment key={String(item.id)}>
            {this.contentLeft(item)}
            {this.contentRight(row,item)}
          </React.Fragment>
        ))
        :
        null
      }
    </div>)
  }

  renderSportStep3 = () => {
    return (
      <div>
        <div
          style={{
            overflowX: "hidden",
            backgroundColor: "#252527",
            position: "relative",
            width: '932px'
          }}
        >
          <ShareStyle>
            <ThemeProvider theme={shareTheme}>
              <Container className="container">
                <Box className="header">
                  <Typography className="text_font" variant="h6">
                    {configJSON.assignKeyboardsText}
                  </Typography>
                  <Typography className="subTxt">
                    {configJSON.contentH1}
                  </Typography>
                </Box>
                <Box className="header-2">
                  <img src={keyboard} alt="share" className="img-style" />
                  <Typography className="shareTxt">
                    {configJSON.contentH2}
                  </Typography>
                </Box>
              </Container>
              <img src={seperator1} width={"100%"} />
            </ThemeProvider>
          </ShareStyle>
          <ShareStyle>
            <Typography style={{ marginTop: 15,width:"80%",margin:"auto",fontFamily:"Poppins",fontSize:"14px", display: "flex", justifyContent: "center", wordWrap: "break-word", flexDirection: "row", alignItems: "center" }} className="headingTxt">
              {configJSON.label3}<span className="subTxt">{configJSON.content3}</span>
            </Typography>
            <div style={{ overflowY: 'scroll',height:"400px", margin: '20px 30px',backgroundColor:"#1D1D1F" }}>
              <div className="tableContainer">
                <div aria-label="simple table" style={{backgroundColor:"#1D1D1F"}}>
                  <div>
                    <div style={{paddingTop:15, display: "flex", flex: 1, justifyContent: "space-around", alignItems: "center" }}>
                      <div className="headingTable" style={{ flex: 6 }}>Group</div>
                      <div className="headingTable" style={{ flex: 5 }}>Keyboards</div>
                      
                    </div>
                  </div>
                  {this.state.groups.map((row: ProductItem & { expanded: boolean }, index: number) => (
                      this.renderRowStep3(row,index)
                  ))}
                  
                </div>
              </div>
            </div>
          </ShareStyle>
        </div>
      </div>)
  }
  renderInOut = () => {
    return(
    <div>
      <div style={{ width: "640px", marginLeft: "auto", marginRight: "auto", marginTop: 16 }}>
        <Typography variant="body2" className="infoTitle" align="center">
          In & Outs:<span
            style={{
              color: '#676767',
              fontFamily: "Poppins",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "24.69px",
              letterSpacing: "-0.005em",
              textAlign: "center",
            }}>(Enter In & Outs for this game)</span>
        </Typography>
        <div style={webStyle.uploadRow}>
          <img src={inAndOut} height={128} width={128} />
          <Box style={webStyle.uploadBtn}>
            <span
              style={webStyle.importTxt}>You can also import a CSV / Excel File to automatically<br /> import data for all the players, this video is shared with.
            </span>
            <span
              style={{
                color: '#676767',
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "24.69px",
                letterSpacing: "-0.005em",

              }}>Available Formats (CSV, Excel)
            </span>
            {this.state.importedFile.length === 0 ? <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'center' }}>
              <input
                data-testid="importPicker"
                type="file"
                style={{ display: 'none' }}
                accept=".csv, .xlsx"
                onChange={this.onChangeImportedFile}
                id="upload-video-input"
                multiple={false}
              />
              <label htmlFor="upload-video-input">
                <Button
                  variant="outlined"
                  style={{
                    color: "#FF881A",
                    borderColor: "#FF881A",
                    padding: "10px 16px",
                    borderRadius: "8px",
                    height: 44,
                    width: 174,
                    fontSize: 18,
                    fontFamily: "Montserrat"
                  }}
                  component="span"
                  endIcon={< Add />}
                >
                  Choose File
                </Button>
              </label>

            </div> :
              <Button
                onClick={() => this.removeImportedFile(0)}
                variant="outlined"
                data-testid={`importedFile-1`}
                style={{
                  color: "#FF881A",
                  borderColor: "#FF881A",
                  padding: "10px 16px",
                  height: 44,
                  width: "auto",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  fontSize: 18,
                  fontFamily: "Montserrat"
                }}
                component="span"
                endIcon={< Close />}>
                {this.state.importedFile[0].name}
              </Button>
            }


          </Box>
        </div>
      </div>
      <img src={seperator1} width={800} />
      <div style={{padding:"18px 34px"}}><span style={{ color: "#CFD1D4", fontFamily: "Poppins", fontSize: 16, fontWeight: 400, lineHeight: "24px" }}>In & Outs Table</span></div>
      <div style={{ display: "flex",backgroundColor:"#181C18",margin:"auto", flexDirection: "column",borderRadius:"16px", marginTop:"5px",padding: "0 5px 10px 5px", gap: 8, height: 400,width:"740px",overflow:"scroll" }}>
        
        {this.state.importedData.length !== 0 && <TableContainer style={{paddingBottom:"200px"}} data-testid="importDataTable" >
          <StyledTableImport>
            <TableHead>
              <TableRow>
                {Object.keys(this.state.importedData[0]).map((key, index) => (
                  <TableCell className="tableCell" style={{}}  key={index}>{key}</TableCell>
                ))}
                <TableCell> <Button onClick={this.onAddNewColumn}> <img src={addIcon} className="icons" /></Button></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.importedData.map((rowData, rowIndex) => (
                <TableRow key={rowIndex} style={{backgroundColor:rowIndex % 2 !== 0 ? "#252527":"#1D1D1F"}}>
                  {Object.entries(rowData).map(([key, value], colIndex) => (
                    <TableCell key={colIndex} onClick={() => this.handleEditCell(key)}>
                      {this.state.editingCell === key ? (
                        <TextField
                          value={value}
                          placeholder="-"
                          onChange={(e) => this.handleCellChange(e, key, rowIndex)}
                          className="editingField"
                          variant="outlined"
                        />
                      ) : (
                        value
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </StyledTableImport>
        </TableContainer>}
      </div>
    </div>)
  }
  renderStepComponent = ({ steps, activeStep }:any) => {
    return (
      <Stepper data-testid="stepper" activeStep={activeStep}>
      {steps.map((label:any, index:any) => (
        <CustomStep key={index} isActive={activeStep === index}>
          <StepLabel label={label} isActive={activeStep === index} />
          <StepCount isActive={activeStep > index} stepNumber={index + 1} />
        </CustomStep>
      ))}
    </Stepper>
  
  )};
  
  renderStepContent = () => {
    const {gameTypeOptions,activeStep,showAssignModal,groups,steps} = this.state;
    
    switch (steps[activeStep]) {
      case "General Info":
        return this.renderSportStep1();
      case "Share Video":
        return <Share data-testId="shareModal" folderId={this.props.thirdLevelPathId} options={gameTypeOptions} showAssignModal={showAssignModal} handleModal={this.handleAssignModal} onGroupUpdate={this.onGroupUpdate} groups={groups} />;
      case "Assign Keyboards":
        return this.renderSportStep3();
      case "In & Out":
        return this.renderInOut();
      default:
        return null;
    }
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container >
          <ModalContent
            data-tesid="bulk-upload-modal"
            open={this.props.isOpen}
            step={this.state.activeStep}
            style={this.getStyleStep(this.state.showAssignModal,this.state.activeStep)}
          >
            <ModalInfoStyled>
              {this.renderStepComponent({ steps: this.state.steps, activeStep: this.state.activeStep })}
              <div>
                {this.state.activeStep !== this.state.steps.length &&
                  (
                    <div>
                      {this.renderStepContent()}
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 40, padding: 24 }}>
                        {this.state.activeStep === 0 && <Button
                          data-testid="cancelBulkUploadBtn"
                          onClick={this.onCancelBulkUpload}
                          variant="outlined"
                          style={{
                            color: "#FF881A",
                            borderColor: "#FF881A",
                            borderRadius: 8,
                            fontSize: 18,
                            padding: "10px 16px 10px 16px",
                            gap: 8,
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                            lineHeight: "normal",
                            width: "200px",
                            height: '55px'
                          }}
                        >
                          Cancel
                        </Button>}
                        {this.state.activeStep !== 0 && <Button
                          onClick={this.handleActiveStepBack}
                          variant="outlined"
                          style={{
                            color: "#FF881A",
                            borderColor: "#FF881A",
                            borderRadius: 8,
                            fontSize: 18,
                            padding: "10px 16px 10px 16px",
                            gap: 8,
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                            lineHeight: "normal",
                            width: "200px",
                            height: '55px'
                          }}
                        >
                          Back
                        </Button>}
                        {this.state.activeStep !== this.state.steps.length - 1 && <Button
                          data-testId="btnNextStep"
                          variant="contained"
                          onClick={this.handleActiveStepNext}
                          style={{
                            color: "#1D1D1F",
                            backgroundColor: "#FF881A",
                            borderRadius: 8,
                            fontSize: 18,
                            padding: "10px 16px 10px 16px",
                            gap: 8,
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                            lineHeight: "normal",
                            width: "200px",
                            height: '55px',
                            marginLeft: '40px',
                            ...(this.isNextBtnDisabled() && {
                              cursor: 'initial',
                              color: '#1D1D1F',
                              backgroundColor: "#66360B"
                            })
                          }}
                          disabled={this.isNextBtnDisabled()}
                        >
                          Next
                        </Button>}
                        {this.state.activeStep === this.state.steps.length - 1 && <Button
                          variant="contained"
                          data-testid="uploadBtn"
                          onClick={this.onBulkUpload}
                          style={{
                            color: "#1D1D1F",
                            backgroundColor: "#A1E146",
                            borderRadius: 8,
                            fontSize: 18,
                            padding: "10px 16px 10px 16px",
                            gap: 8,
                            fontFamily: "Montserrat",
                            fontWeight: 600,
                            lineHeight: "normal",
                            width: "200px",
                            height: '55px',
                            marginLeft: '40px',
                            ...(this.isNextBtnDisabled() && {
                              cursor: 'initial',
                              opacity:0.3,
                            })
                          }}
                          disabled={this.isNextBtnDisabled()}
                        >
                          Done
                        </Button>}
                      </div>
                    </div>
                  )}
              </div>
              {this.state.steps[this.state.activeStep] === "General Info"&&!this.props.editGameData?.editVideoId && (
                <div style={{ position: "absolute", width: "400px", height: "313px", top: "95px", right: "-400px", backgroundColor: "#1D1D1F", flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <div style={{ textAlign: "center", color: "#fff", marginBottom: "10px",fontFamily:"Poppins" }}>Please Upload Thumbnail</div>
                  <div
                    data-testid="imgFile-Btn"
                    onClick={() => { this.onChooseFile() }}
                    style={{ width: "352px", height: "200px", cursor: "pointer", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#252527" }}>
                    {!!this.state.thumbnailImg ? <img src={URL.createObjectURL(this.state.thumbnailImg)} style={{ width: "352px", height: "200px" }} /> :
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                        <img src={thumb} style={{ width: "92px", height: "92px", marginBottom: "-15px" }} />
                        <div style={{ display: "flex", marginTop: "30px", padding: "6px 20px", border: "1px solid #FF881A", borderRadius: "9px", justifyContent: "center", color: "#FF881A", fontSize: "18px", fontFamily: "Poppins", alignItems: "center", flexDirection: "row" }}>
                          <span style={{ marginRight: "4px" }}>Choose File</span><Add />
                        </div>
                      </div>}
                    <input
                      data-testid="imgFile-input"
                      type="file"
                      ref={this.fileInputRef}
                      onChange={this.handleOnChangeFile}
                      style={{ display: "none" }}
                      accept="image/*"
                    />
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span style={{ color: "#fff",fontFamily:"Poppins" }}>Limited Upload Size:</span><span style={{ color: "#FF881A",fontFamily:"Poppins" }}>5MB</span></div>
                </div>

              )}
            </ModalInfoStyled>

          </ModalContent>


        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start


const useStylesStepCount = makeStyles((theme: Theme) => ({
  stepCount: {
    textAlign: 'center',
    width: '100%',
    color: "#676767",
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "21.94px",
  },
  active: {
    color: '#8DE301', // Green color for active step count
  },
}));
const ShareStyle = styled(Box)({
  "& .header": {
    display: "block ruby"
  },
  "& .container": {
    paddingTop: '20px',
    width: "696px",
    backgroundColor: "#252527",
    height: "200px"
  },
  "& .header-2": {
    display: 'flex',
    paddingTop: '10px'
  },
  "& .shareTxt": {
    fontFamily: 'poppins',
    paddingTop: "18px",
    paddingLeft: "15px",
    color: "#676767",

  },
  "& .tablerow": {
    marginBottom: 6,
  },
  "& .MuiPaper-root": {
    backgroundColor: 'white'
  },
  "& .tableContainer": {
    minHeight: '300px',
    overflowX: 'hidden',
    backgroundColor: '#1D1D1F',
    position: 'relative',
    padding: '0 20px',

    "& *": {
      boxSizing: 'border-box'
    }
  },
  "& .table": {
    borderRadius: '4px',

  },
  "& .headingTable": {
    color: '#676767',
    fontFamily: "Poppins",
    fontSize: '16px',
    textAlign: 'center',
    border: 'none',
  },
  "& .divHeader": {
    display: 'flex',
    alignItems: 'center',
  },
  "& .headingTxt": {
    fontSize: 13,
    paddingTop: 15,
    position: 'relative',
    left: '4%',
    fontFamily: 'poppins',
    color: 'white'
  },
  "& .text_font": {
    color: 'white',
    fontFamily: 'poppins',
  },
  "& .subTxt": {
    fontFamily: 'poppins',
    color: "grey",
    fontSize: "16px",
    paddingLeft: "10px",
  },
  "& .divSubHeader": {
    backgroundColor: '#1d1d1f',
    borderRadius: '5px',
    marginLeft: '0.5rem'
  },
  "& .avatar": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgb(255, 136, 26)',
    width: "40px",
    height: "40px",
    borderRadius: "20px",
    color: '#151414fa',
    fontSize: "18px",
    fontFamily: "Poppins",
    zIndex: 800,
  },
  "& .next_back": {
    cursor: 'pointer',
    display: 'flex',
    alignItems: "center",
    justifyContent: "center"
  },
  "& .img-style_button": {
    margin: '5px',
    height: '32px'
  },
  "& .typography": {
    color: 'white',
    fontFamily: "Poppins",
    position: 'relative',
  },
  "& .tablecell": {
    position: 'relative',
    borderBottom: 'none'
  },
  "& .img-style": {
    cursor: 'pointer',
    margin: "2%",
  },
})
const shareTheme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
interface StepCountProps {
  isActive: boolean;
  stepNumber: number;
}

export const StepCount: React.FC<StepCountProps> = ({ isActive, stepNumber }) => {
  const classes = useStylesStepCount();

  return (
    <div className={`${classes.stepCount} ${isActive ? classes.active : ''}`}>
      Step {stepNumber}
    </div>
  );
};

const useStylesCustomStep = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    flexGrow: 1,
    padding: "16 32 8 32",
    '&.Mui-active, &.Mui-completed': {
      borderBottom: `4px solid ${theme.palette.primary.main}`,
    },
  },
  active: {
    borderBottom: `4px solid #FF881A`,
  },
}));

interface CustomStepProps {
  isActive?: boolean;
  children: React.ReactNode;
}

export const CustomStep: React.FC<CustomStepProps> = ({ isActive, children }) => {
  const classes = useStylesCustomStep();
  return (
    <Step className={`${classes.root} ${isActive ? classes.active : ''}`}>
      {children}
    </Step>
  );
};


const useStylesStepLabel = makeStyles((theme: Theme) => ({
  stepLabel: {
    textAlign: 'center',
    width: '100%',
    padding: '8px 0',
    color: '#676767',
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "21.94px"

  },
  active: {
    color: '#FF881A', // Label color for active step
  },
}));

interface StepLabelProps {
  isActive: boolean;
  label: string;
}

export const StepLabel: React.FC<StepLabelProps> = ({ isActive, label }) => {
  const classes = useStylesStepLabel();

  return (
    <div className={`${classes.stepLabel} ${isActive ? classes.active : ''}`}>
      {label}
    </div>
  );
};



export const CustomInput = ({ openCalendar, value, handleValueChange,placeholder }: any) => {
  const formatDate = (date: any) => {
    if (!date) return '';
    return date;
  };

  return (
    <div className="input-date" style={{ display: 'flex', alignItems: 'center' }}>
      <input
        readOnly
        data-testid="inputDate"
        value={formatDate(value)}
        onClick={openCalendar}
        placeholder={placeholder}
      />
      <IconButton onClick={openCalendar} >
        <img src={calendarIcon} />
      </IconButton>
    </div>
  );
};

export const BpCheckbox = (props: CheckboxProps) => {
  return (
    <Checkbox
      disableRipple
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
}
const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 5,
  width: 26,
  height: 26,
  border: "3px solid #676767",
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "transparent",
  backgroundImage: `url(${check})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  padding: 1,
  "&::before": {
    display: "block",
    width: 26,
    height: 26,
    color: "black",
    content: '""',
  },
});

const webStyle: Record<string, React.CSSProperties> = {
  importTxt:{
    color: '#676767',
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24.69px",
    letterSpacing: "-0.005em",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  uploadRow: {
    display: "flex",
    alignItems: "center",
    width: 640,
    justifyContent: "space-between",
    gap: 8,
    padding: "30px 0px"
  },
  uploadBtn: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
  },
  selectFolderBlock: {
    width: "720px",
    display: "flex",
    flexDirection: "column",
    padding: "16px 32px",
    gap: 8,
    marginTop: 16,
    margin:"auto"

  }

};
const SwitchButtonMaskBox = styled(Box)(({ theme }) => ({
  bordeRadius: 6,
  position: "relative",
  background: "#00000040",
  border: "1px solid #FF881A",

  "& .mask": {
    width: 64,
    height: 24,
    borderRadius: 4,
    background: "#FF881A",
    position: "absolute",
    boxShadow: "1px 0 2px #b473ff",
    transition: "all 0.5s ease",
  },
  "& .MuiButton-root": {

    width: 47,
    height: 24,
    fontWeight: 400,
    textTransform: "uppercase",
    transition: "all 0.2s 0.1s ease",
  },
  "& .MuiButton-root:hover": {
    opacity: 0.8,
  }

}))
const ModalInfoStyled = styled(Box)(({ theme }) => ({
  background: "#252527",
  borderRadius: "10px",
  boxShadow: "-5px 4px 12px 0px #00000040 inset",

  "& .sub-text": {
    fontFamily: "Poppins",
    fontWeight: '400',
    fontSize: '16px',
    color: "#676767"
  },

  "& .form-container": {
    display: 'flex',
    flexDirection: 'column',
    padding: '16 32px',
    gap: '8px'
  },
  "& .wrapStepOneForm": {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 66px',
    gap: 8,
  },

  "& .optional": {
    display: 'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  "& .optional-text": {
    fontFamily: "Poppins",
    fontWeight: '400',
    fontSize: '14px',
    color: "#676767"
  }
}))

const StyledTableImport = styled(Table)({

  fontFamily: 'Poppins',
  borderCollapse: 'separate',
  borderRadius: 10,

  '& .MuiTable-root': {
    minWidth: 600, // Set a minimum width to trigger horizontal scrolling
  },
  '& .MuiTableRow-root': {
    height: '30px',
  },

  '& .MuiTableRow-head': {
    height: '40px',
    position: "sticky",
    top: 0,
    backgroundColor: "#1d1d1f", /* Adjust as needed */
    zIndex: 1,
  },




  '& th, & td': {
    height: "40px",
    fontSize: '16px',
    color: '#676767',
    textAlign: 'center',
    fontWeight: 400,
    lineHeight: "24px",
    borderBottom: 'none',
    fontFamily: 'Poppins',
    margin: '0',
  },
  '& th': {
    color: '#676767',
    fontWeight: '400',
    lineHeight: '18px',
    padding: 0
  },
  '& td': {
    lineHeight: '24px',
    color: '#CFD1D4',
    fontWeight: 500,
    fontSize: 16,
    padding: "0 10px",
    width: 70,
    textAlign: "center",
    whiteSpace: "nowrap",
    "& .editingField": {
      "& .MuiOutlinedInput-input": {
        padding: 2,
        color: "white",
        width: 80,
        height: 24
      }
    }
  },
  '& tr': {
    height: '40px',
  },
  '& tbody tr:first-child td': {
    backgroundColor: "#1d1d1f",
  },
  '& tbody tr:nth-of-type(odd) td': {
    backgroundColor: "#1d1d1f",
  },


  '& th:last-child': {
    width: 30,
  },

  '& tr td:last-child': {
    width: "50px"
  },
});


const InputItem = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom:10,
  "& .pd": {
    padding: "5px 0"
  },
  "& .flex3": {
    flex: 3
  },
  "& .flex4": {
    flex: 5
  },
  "& .select-container": {
    width: "416px"
  },
  "& .gameScore1": {
    width: 184
  }
  ,
  "& .gameScore2": {
    width: 184
  },
  "& .inputField": {
    width: "416px"
  },
  "& .opponentInputField": {
    width: "285px !important"
  },
  "& .rmdp-top-class": {
    width: '300px'
  },
  "& .rmdp-calendar": {
    border: '1px solid #FF881A',
    backgroundColor: '#1D1D1F',
    boxShadow: 'none',
    width: '100%'
  },
  "& .rmdp-day-picker > div:first-child": {
    width: '100%',
  },
  "& .rmdp-day": {
    color: '#CFD1D4',
    fontSize: '14px',
    fontFamily: `"Poppins", sans-serif`
  },
  "& .rmdp-week-day": {
    color: '#CFD1D4',
    fontSize: '14px',
    fontFamily: `"Poppins", sans-serif`
  },
  "& .rmdp-header-values": {
    color: '#FF881A',
    fontWeight: 700
  },
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: '#FBB26E',
  },
  "& .rmdp-month-picker, .rmdp-year-picker": {
    color: '#FF881A',
    fontWeight: 700,
    backgroundColor: '#1D1D1F'
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight)": {
    backgroundColor: '#FF881A',
    boxShadow: 'none',
    fontWeight: 500
  },
  "& .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover": {
    backgroundColor: '#FF881A'
  },
  "& .rmdp-arrow": {
    border: 'solid #FF881A',
    borderWidth: '0 2px 2px 0'
  },
  "& .rmdp-arrow-container:hover": {
    backgroundColor: "transparent",
    boxShadow: 'none',
    border: 'none',
    borderWidth: 'none'
  },

  "& .rmdp-container ": {
    width: "417px",
    color: '#CFD1D4',
    "& .input-date": {
      background: "#1D1D1F",
      boxShadow: "4px 4px 4px 0px #00000040 inset",
      borderRadius: "10px",
      borderBottom: "1px solid #FF881A"
    },
    "& .input-date input": {
      width: "100%",
      height: "50px",
      background: "transparent",
      border: "unset",
      padding: "0 12px",
      color: "#CFD1D4",
      fontSize: "16px",
      fontFamily: "Poppins",
      fontWeight: 400
    },
    "& .input-date input:focus-visible": {
      outline: "unset"
    }
  },
  "& .icon-tabler-calendar-event ": {
    right: '16px !important'
  },
  "& .rmdp-input": {
    height: '56px',
    width: "100%",
    color: '#CFD1D4',
    background: '#1D1D1F',
    border: 'none',
    borderBottom: "1px solid #FF881A",
    boxShadow: "4px 4px 4px 0px #00000040 inset",
    borderRadius: "10px",
    margin: '1px 0',
    padding: '2px 5px',
    fontSize: '16px',
    fontWeight: 400
  },


}))
// Customizable Area End