import React from "react";
import ForgotPasswordWebController, { Props } from "./ForgotPasswordWebController";
import { Box, Button, TextField, Typography, styled } from "@material-ui/core";
import { backgroundImg, logo } from "./assets";

const LoginStyle = styled(Box)(({ theme }) => ({
    '& .image-wrapper': {
        display: "flex",
        flexDirection: "row",
        height: "100%",
        overflow: "hidden auto",
        [theme.breakpoints.down(1400)]: {
            height: "100%"
        }
    },
    '& .image-body': {
        flex: "0 0 calc(100%/12*8.5)",
        display: 'flex',
        width: "100%",
        height: "100vh",
    },
    '& .image-body-content': {
        width: "72%",
        height: "100%",
        backgroundImage: `url(${backgroundImg})`,
        position: "fixed",
        overflowX: 'hidden',
        backgroundRepeat: 'no-repeat',
        overflow: 'hidden',
        backgroundSize: "100% 100%",
    },
    '& .lableStyle': {
        color: "#676767",
        fontWeight: 400,
        fontSize: '20px',
        fomtFamily: 'Poppins'
    },
    '& .validationError': {
        width: "220px",
        textAlign: "left",
        color: '#FF1919',
        fontWeight: 400,
        fontSize: '16px',
        fontFamily: 'Poppins'
    },
    '& .MuiFilledInput-underline': {
        borderBottom: '1px solid #FF881A',
        boxShadow: '4px 4px 4px 0px #00000040 inset',
        borderRadius: '10px'
    },
    "& .inputField": {
        width: '100%',
        color: '#CFD1D4',
        borderBottom: '1px solid #FF881A',
        boxShadow: '4px 4px 4px 0px #00000040 inset',
        borderRadius: '10px',
        height: "53px",
        boxSizing: "border-box",
        "& .MuiInputBase-root": {
            height: "100%",
        }
    },
    '& .MuiInput-underline::before': {
        borderBottom: 'none',
        outline: 'none'
    },
    '& focus: .inputField': {
        outline: 'none',
        border: 'none'
    },
    '& .MuiInput-underline::after': {
        borderBottom: 'none'
    },
    '& .MuiInputBase-input': {
        color: '#CFD1D4',
        borderRadius: '10px',
        padding: "10px 8px 10px 8px",
        fontFamily: 'Poppins',
        fontSize: "16px",
        fontWeight: "400"
    },
    "& .logoImage": {
        display: 'flex',
        justifyContent: 'center',
    },
    '& .boxContainner': {
        display: 'flex',
        backgroundRepeat: 'no-repeat',
        // backgroundImage: `url(${backgroundImg})`,
        backgroundSize: 'cover',
        flex: "0 0 calc(100%/12*3.5)"
    },
    '& .buttonContainer': {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '30',
        position: 'relative'
    },
    '& .login-container': {
        width: '47.917vw'
    },
    '& .btn-below-line': {
        border: '1px solid #676767',
        width: '19vw',
        top: '50.417vw',
        margin: 'auto',
        marginTop: '4%',
        marginBottom: '4%'
    },
    '& .send-btn': {
        maxWidth: "120px",
        width: "100%",
        backgroundColor: '#FF881A',
        color: '#1D1D1F',
        textTransform: 'none',
        fontWeight: 400,
        fontFamily: 'Poppins',
        fontSize: '18px'
    },
    '& .back-btn': {
        maxWidth: "120px",
        width: "100%",
        color: '#FF881A',
        backgroundColor: '#252527',
        textTransform: 'none',
        fontWeight: 400,
        border:'1px solid #FF881A',
        fontFamily: 'Poppins',
        fontSize: '18px'
    },
    '& .games-company-container': {
        display: 'flex',
        justifyContent: 'center'
    },
    '& .bottom-link-text-link': {
        color: '#FF881A',
        fontSize: "16px",
        cursor:'pointer',
        fontWeight: 400,
        fontFamily: 'Poppins',
    },
    '& .bottom-container': {
        display: 'flex',
        fontFamily: 'Poppins',
        alignItems: 'baseline',
        justifyContent: 'center',
        marginTop: '2%'
    },
    '& .bottom-text': {
        color: '#CFD1D4',
        paddingRight: '1%'
    },
    '& .contact-info-box': {
        width: '90%', borderRadius: '10px',
    },
    '& .input-box': {
        display: 'flex', flexDirection: 'column', gap: '4px', width: "100%"
    },
    '& .reset-password-text': {
        color: "#CFD1D4",
        fontSize: "30px",
        fontWeight: "400",
        fontFamily: "Poppins"
    },
    '& .main-container': {
        display: "flex",
        justifyContent: "space-around",
        flexDirection: "column",
        position: 'relative',
        backgroundColor: '#252527',
        height: '100%',
        boxShadow: '-11px 0px 13px 0px #00000040 inset',
        backgroundSize: 'cover',
        '@media(max-height:1000px)': {
            height: '100%'
        }
    },
    '& .bottom-not-box': {
        color: '#676767',
        fontWeight: 400,
        textAlign: 'center',
        fontSize: '16px',
        fontFamily: 'Poppins'
    },
    '& .cardContent': {
        fontWeight: 400,
        fontSize: '14px',
        fontFamily: 'Montserrat',
        color: '#CFD1D4'
    }
})
)
export default class ForgotPassword extends ForgotPasswordWebController {
    constructor(props: Props) {
        super(props);
        //Customizable Area Start
        //Customizable Area End
    }

    render() {
        return (
            // Customizable Area Start
            <LoginStyle>
                <Box className="image-wrapper">
                    <Box className="boxContainner">

                        <Box className="main-container" >
                            <Box style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: 50 }}>
                                <img className="logoImage" width={220} src={logo} />
                            </Box>
                            <Box style={{ padding: "0 24px" }}>
                                <Box style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: 30 }} >
                                    <Typography className="reset-password-text">Reset Password</Typography>
                                </Box>
                                <Box style={{ display: "flex", width: "100%", justifyContent: "center", marginTop: 30 }}>
                                    <Typography className="bottom-not-box"
                                    >Please note, once resetting a password, the link sent to your email will expire in 48 Hours.</Typography>
                                </Box>
                                <Box style={{ display: "flex", width: "100%", marginTop: 30 }}>
                                    <Box className="input-box" >
                                        <Typography className="lableStyle">Email</Typography>
                                        <TextField className="inputField" InputProps={{
                                            disableUnderline: true,
                                            endAdornment: <span className="validationError" style={{ display: this.state.showMsg === "Incorrect Email" ? "block" : "none" }}>{this.state.showMsg}</span>
                                        }}
                                            value={this.state.email}
                                            onChange={this.changeHandler}
                                        />
                                        {this.state.showMsg === "Emaill sent." && <p style={{ margin: "0", fontFamily: "Poppins", fontWeight: 400, fontSize: "16px", color: "#7ED321", textAlign: "right" }}>
                                            Email sent
                                        </p>
                                        }
                                    </Box>

                                </Box>


                                <Box style={{ gap:'16px',display: "flex", width: "100%", justifyContent: "center", marginTop: 30 }} className="buttonContainer" >
                                <Button variant="contained"
                                        className="back-btn"
                                        data-test-id="forgot-password"
                                        onClick={this.goToLoginPage}
                                    >Back</Button>
                                    <Button variant="contained"
                                        className="send-btn"
                                        data-test-id="forgot-password"
                                        onClick={this.forgotPasswordHandler}
                                    >Send</Button>
                                </Box>
                               
                            </Box>
                            <Box style={{ display: "flex", marginTop: 30, marginBottom: 30, padding: "0 24px" }} className="contact-info-box" >
                                <Box style={{ display: 'flex', padding: '16px', width: "100%", backgroundColor: '#1D1D1F', borderRadius: "10px" }}>
                                    <Box>
                                        <Typography className="cardContent">
                                            Every access to this application is logged. Only authorized users may enter. ©2020-2024 All Copy Rights Reserved to
                                            Fantastic Athletes Corporation, USA This system is patent protected - US Patent #11113332 & US20210349942A1</Typography>
                                    </Box>
                                    <Box style={{ border: '1px solid #FF881A', margin: "0 4px" }} />
                                    <Box style={{ margin: 'auto' }}>
                                        <img style={{ width: '8vw' }} src={logo} />
                                    </Box>
                                </Box>
                            </Box>

                        </Box>       
                    </Box>
                    <Box className="image-body" >
                        <Box className="image-body-content"/>
                    </Box>
                </Box>
            </LoginStyle>
            // Customizable Area End
        );
    }
}