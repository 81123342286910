import React from "react";
import { Box, Typography, styled } from "@material-ui/core";
import { logoApp } from "../../blocks/dashboard/src/assets";
import { GameFolder } from "../../blocks/dashboard/src/DashboardController.web";
import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";
const defaultUploadImg = require("./assets/default_upload.png");


interface IPropBrowseRecentGame {
    data: GameFolder[];
    navigation: any;
}

interface IMappingDataRecentGame {
    people_with_uploads_count?: number;
    total_people_count?: number;
    isGameFolderType?: boolean
}

function mappingDataRecentGame(data: GameFolder[]): (GameFolder & IMappingDataRecentGame)[]{
    return data?.map(recentGame => {
        const { folder_type, people_with_uploads_count, total_people_count} = recentGame?.attachment?.parent_folder?.data?.attributes || {};
        if( folder_type?.toLowerCase() === "game folder") return {isGameFolderType: true, people_with_uploads_count, total_people_count, ...recentGame}
        return {isGameFolderType: false, ...recentGame}
    })
}

export default function BrowseRecentGame({ data,navigation }: IPropBrowseRecentGame) {

    const navigateToGame = (item: GameFolder) => {        
        navigation.navigate("VideoEvent", {videoId:item.attachment.id})
      };
    return <BrowseRecentGameStyled className="browseRecentGame_box">
        {!data?.length && (<Box style={{ display: 'flex', justifyContent: 'center' }}><Typography className="description_text">No browse recent games</Typography></Box>)}
        <div
            className="listRecentGame"
            data-test-id='recentGameId'
        >
            {mappingDataRecentGame(data)?.map((item, index) => (
                <div
                    key={index}
                    className="recentGameItem"
                    style={{
                        backgroundImage: `url(${item.attachment.thumbnail || defaultUploadImg})`,
                    }}
                    onClick={() => navigateToGame(item)}
                >
                    <div className="backgroundBlurGameItem" />
                    <div className="content_text">
                        <Box className="top_left_info">
                            <Box style={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                {
                                    item?.isGameFolderType ? <Box>
                                        <Typography style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "10px", color: "#FF881A", }}>
                                            {item?.people_with_uploads_count}/{item?.total_people_count}
                                        </Typography>
                                        <img src={require("./assets/file_icon.svg")} height={35} width={35} style={{
                                            position: "absolute",
                                            left: "50%",
                                            transform: "translate(-50%, -50%)",
                                            top: "50%"
                                        }} alt="File Icon" />
                                    </Box> :
                                <img src={require("./assets/videoPlay-Icon.svg")} height={35} width={35} alt="Play Icon"/>
                                }
                            </Box>
                        </Box>
                        <Box className="text_center">
                            <Typography className="description_text">{item.attachment?.team_name}</Typography>
                            <Typography className="description_text"> Vs</Typography>
                            <Typography className="description_text">{item.attachment?.oppo_team_name}</Typography>
                        </Box>
                    </div>
                    <div
                        style={{
                            marginTop: '24px',
                            marginBottom: '16px',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            zIndex: 2
                        }}
                    >
                        <img src={logoApp} width={'100px'} height={'50px'} alt="Logo" />
                    </div>
                </div>
            ))}
        </div>
    </BrowseRecentGameStyled>
}


const BrowseRecentGameStyled = styled(Box)(({ theme }) => ({
    paddingBottom: '24px',
    background: "#252527F2",
    position: "relative",
    borderRadius: "10px",
    "& .description_text": {
        color: "#CFD1D4",
        fontFamily: "Poppins",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400"
    },
    "& .content_text": {
        padding: '8px', 
        position: 'relative', 
        zIndex: 2, 
        display: "flex",
        justifyContent: "center",
        "& .top_left_info": {
            position: "absolute",
            left: 0,
            top: 0,
            backgroundColor: "#252527",
            boxShadow:" -3.55px 3.55px 4.73px 0px #00000040 inset",
            height:56,
            width:56,
            borderRadius:"10px 0px 28.4px 0px"
        },
        "& .text_center": {
            display: "flex",
            flexDirection: "column"
        },
        "& .description_text": {
            fontSize: "16px",
            fontWeight: "500",
            textAlign: "center"
        }, 
    }, 
    "& .listRecentGame": {
        display: 'flex',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        padding: '0px 24px',
        "& .recentGameItem": {
            minWidth: '285px',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% 100%',
            marginRight: '16px',
            position: 'relative',
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            transition: "filter 0.3s ease-in-out", // Add transition for smooth hover effect
            cursor: "pointer", // Change cursor on hover
            "&:hover": {
              filter: "brightness(1.2)", // Brighten thumbnail on hover
            },
            "& .backgroundBlurGameItem": {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 1,
            },
            height:"175px",
              "@media (min-width: 1920px)": {
                minWidth: '600px',
              }
        },
        "& .recentGameItem, .backgroundBlurGameItem": {
            borderRadius: "10px"
        }
    }
}))