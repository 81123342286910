//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { 
  toast
 } from "react-toastify/dist/react-toastify.esm.js";
import ImagePicker from "react-native-image-crop-picker";
import { 
  Alert
 } from "react-native";
import { 
  getStorageData,
  removeStorageData
} from "../../../framework/src/Utilities";
const CategoryData = [
  {
    idss: 0,
    title: "User 1",
    value: "user_1",
  },
  {
    idss: 1,
    title: "User 2",
    value: "user_2",
  },
];
const initialBread = [
  {
    id: null,
    breadCrumbName: "Fantastic Athletes Folders",
    type: "init",
  },
];

export interface PlayerFilter {
  label: string,
  id: number,
  type: string,
  selected: boolean;
}
export interface GetAssignedPermissionResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      folder_name: string;
      created_by: null;
      created_on: string;
      of_videos: number;
      videos_size_gb: number;
      assigned: boolean;
      viewing_permission: string;
      editing_permission: string;
      editing_permission_accounts: Array<{}>;
      viewing_permission_accounts: Array<{}>;
    };
  };
}

export interface UserAccount {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: null;
  phone_number: null;
  email: string;
  activated: boolean;
  device_id: null;
  unique_auth_id: string;
  password_digest: string;
  created_at: Date | string;
  updated_at: Date | string;
  user_name: string;
  platform: null;
  user_type: null;
  app_language_id: null;
  last_visit_at: null;
  is_blacklisted: boolean;
  suspend_until: null;
  status: string;
  role_id: number;
  full_name: null;
  gender: null;
  date_of_birth: Date | string;
  age: number;
  in_game_role: string;
  country: null | string;
  state: null;
  title: null;
  account_type: null;
  new_folder_id: null;
  role_name: null;
  stripe_customer_id: null | string;
  position: null;
  staff: null;
}

export interface GetTeamMemberResponse {
  teams: Array<Team>;
}

export interface PlayerInfo {
  id: string | number;
  type: string;
  attributes: {
    activated: boolean;
    country_code: null;
    email: string;
    first_name: string | null;
    full_phone_number: string;
    last_name: string | null;
    phone_number: null;
    type: string | null;
    created_at: Date | string;
    updated_at: Date | string;
    device_id: string|null;
    unique_auth_id: string | null;
    position: string | null;
    staff: string | null;
    photo?: string | null;
  };
  selected?:any;
  preSelected?: any;
}

export interface GetAllMemeberResponse {
  data: Array<PlayerInfo>;
}

export interface Team {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  country_code: null;
  phone_number: null;
  email: string;
  activated: boolean;
  device_id: null;
  unique_auth_id: string;
  password_digest: string;
  created_at: Date | string;
  updated_at: Date | string;
  user_name: string;
  platform: null;
  user_type: null;
  app_language_id: null;
  last_visit_at: null;
  is_blacklisted: boolean;
  suspend_until: null;
  status: string;
  role_id: number | null;
  full_name: null;
  gender: null | string;
  date_of_birth: Date | string;
  age: number | null;
  in_game_role: string;
  country: string;
  state: null | string;
  title: null | string;
  account_type: null | string;
  new_folder_id: null;
  role_name: null;
  stripe_customer_id: null;
  position: string | null;
  staff: null;
  photo?:string | null;
}

interface Imagetype {
  uris: string;
  width: number;
  height: number;
}
interface CategoryType {
  idss: number;
  title: string;
  value: string;
}

interface ProductItem {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    status: boolean;
    price: number;
    user_type: string;
    quantity: string;
    created_at: string;
    updated_at: string;
    images: [
      {
        id: number;
        url: string;
        type: string;
        filename: string;
      }
    ];
  };
}

interface CustomFolder {
  id: number;
  name: string;
  videos: number;
  fileSize: number;
  createdBy: string;
  createdOn: string;
  editing_permission_acccounts?: Array<{}>;
  viewing_permission_accounts?: Array<{}>;
  innerId?:any
}

interface Level {
  id: number;
  breadCrumbName: string;
  component: string;
}
export interface BreadArray {
  id: number;
  breadCrumbName: string;
  type: string;
  permission?:boolean;
}
interface PlayersData {
  user_name: string;
}
interface UserNames {
  specific_name: string;
}

interface DeleteFolder {
  id: number;
  name: string;
  size: string;
}

interface AssigneeItemOption {
  id: number;
  name: string;
  selected: boolean;
  avatar: string;
}
export type Player = Team & { selected: boolean };

export interface GameFolder {
  id: string;
  type: string;
  attributes: {
    id: number;
    folder_type:                 string;
    folder_name:                 string;
    created_by:                  string;
    created_on:                  string;
    of_videos:                   number;
    videos_size_gb:              number;
    assigned:                    boolean;
    viewing_permission:          null;
    editing_permission:          null;
    my_team_name:                string;
    opponent_team_name:          string;
    game_due_date:               Date | string;
    game_type:                   string;
    photo:                       string|null;
    thumbnail:                   string;
    accounts:                    UserAccount[];
    groups:                      Group[];
  }
}

export interface Group {
  id:            number;
  name:          string;
  settings:      string;
  created_at:    Date;
  updated_at:    Date;
  meeting_id:    null;
  assigned:      boolean;
  competition:   null;
  position:      null;
  account_id:    null;
  admin_created: boolean;
  players:       null;
  new_folder_id: number;
  game_type:     null;
}

type FileItem = {
  id: number;
  type: string;
};
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start

  navigation: object;
  idContent: string | number;
  permission: any;
  idGroup: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  deleteFolderModal: boolean;
  isLayoutGrid: boolean;
  selectMode: boolean;
  deleteFolder: DeleteFolder;
  selectedPlayers: { [name: string]: boolean };
  createFolderModal: boolean;
  showPermissions: boolean;
  createFolderName: string;
  createSelectedPath: string;
  selectFolderModal: boolean;
  activeComponent: string;
  userDataListPlayers: PlayersData[];
  dataPlayers: UserNames[];
  selectActiveComponent: string;
  selectedCustomFolder: number;
  Levels: Level[];
  levelBreads: BreadArray[];
  selectLevels: BreadArray[];
  customFolders: CustomFolder[];
  attachmentVideos: any;
  isSelectUploadModalOpen: boolean;
  isSelectUploadGameTypeModalOpen: boolean;
  isBulkUploaderOpen: boolean;
  isSelectFolderModalOpen: boolean;
  loading: boolean;
  catagorydata: CategoryType[];
  showCategory: boolean;
  category: CategoryType;
  showModel: boolean;
  images: Imagetype[];
  imagesWeb: File[];
  baseImages: string[];
  title: string;
  description: string;
  price: string;
  quantity: string;
  userDataList: ProductItem[];
  idStore: string;
  viewPermissionOpen: boolean;
  assigneePermissions: Array<{
    id: number;
    name: string;
    permission: "" | "edit" | "view";
    avatar: string;
    rolename:string
    
  }>;
  assignViewPermissionOpen: boolean;
  staffList: Array<AssigneeItemOption>;
  inGameRole:Array<any>;
  positionList: Array<AssigneeItemOption>;
  assigneeList: Array<AssigneeItemOption>;
  currentTeamName: string;
  createGameFolderOpen: boolean;
  token: string;
  getAssignedPermissionLoading: boolean;
  assignPermissionFolderLoading: boolean;
  deletingFolder: boolean;
  permissions: string;
  allPlayers: Array<Player>;
  initialAllPlayers: Array<Player>;
  editingFolderAccess: boolean;
  editingFolderId: number;
  dataRender: any;
  modalDataRender: any;
  selectedItem: any;
  selectedModalItem: any;
  gameFolders: Array<GameFolder>;
  staffFilters:Array<PlayerFilter>;
  inGameRole:Array<PlayerFilter>
  selectedFilter:Array<PlayerFilter>;
  initialLoading: boolean;
  selectedFolder:CustomFolder;
  currentPathItem:BreadArray | null;
  shareVideoOpen: boolean;
  shareSelectedVideoId: number|null;
  shareSelectedGameType: string|null;
  shareSelectedType: string|null;
  pagination: {
    page:number,
    perPage:number,
    totalRecords: number,
    totalPages:number
  },
  folderId:number |null,
  editSelectedVideoId: string|null, //for edit game
  editSelectedGameType: string|null,
  editSelectedType: string|null,
  isMoveFile: { // moving file
    isMove:boolean,
    files:{id:number,type:string}[]
  }, 
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idManagement: number | string;
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall: string | Message = "";
  saveDataUsers: string | Message = "";
  addDataCall: string | Message = "";
  apiGroupCallId: string | Message = "";
  autoGeneratedFoldersCall: string | Message = "";
  autoGeneratedSelectFoldersCall: string | Message = "";
  autoInnerGeneratedFoldersCall: string | Message = "";
  customFoldersCall: string | Message = "";
  autoInnerGeneratedSelectFoldersCall: string | Message = "";
  customSelectFoldersCall: string | Message = "";
  createCustomFoldersCall: string | Message = "";
  deleteCustomFoldersCall: string | Message = "";
  assignPermissionFolderCallId: string = "";
  getTeamMemberCallId: string = "";
  filterMemberCallId: string = "";
  getStaffCallId: string = "";
  getAllPlayerCallId: string = "";
  editFolderAccessCallId: string = "";
  getVideosCall: string | Message = '';
  moveFilesCallId: string | Message = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getSpecific = this.getSpecific.bind(this);
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeComponent: "FantasticAthletesFolders",
      userDataListPlayers: [],
      dataPlayers: [],
      showPermissions: false,
      selectedPlayers: {},
      createSelectedPath: "",
      Levels: [
        {
          id: null,
          breadCrumbName: "Fantastic Athletes Folders",
          component: "FantasticAthletesFolders",
        },
      ],
      selectLevels: initialBread,
      levelBreads: initialBread,
      deleteFolderModal: false,
      isLayoutGrid: false,
      selectMode: false,
      deleteFolder: { id: null, name: "", size: "" },
      createFolderModal: false,
      createFolderName: "",
      selectFolderModal: false,
      customFolders: [],
      attachmentVideos: [],
      selectActiveComponent: "FantasticAthletesFolders",
      selectedCustomFolder: null,
      isSelectUploadModalOpen: false,
      isSelectUploadGameTypeModalOpen: false,
      isBulkUploaderOpen: false,
      isSelectFolderModalOpen: false,
      loading: false,
      catagorydata: CategoryData,
      showCategory: false,
      category: {
        idss: 0,
        title: "User 1",
        value: "user_1",
      },
      showModel: false,
      images: [],
      imagesWeb: [],
      baseImages: [],
      title: "",
      description: "",
      price: "",
      quantity: "",
      userDataList: [],
      idStore: "",
      viewPermissionOpen: false,
      assigneePermissions: [],
      assignViewPermissionOpen: false,
      currentTeamName:"",
      staffList: [],
      positionList: [],
      assigneeList: [],
      createGameFolderOpen: false,
      token: "",
      getAssignedPermissionLoading: false,
      assignPermissionFolderLoading: false,
      deletingFolder: false,
      toastOpen: false,
      toastMessage: "",
      toastSuccess: false,
      permissions: "",
      allPlayers: [],
      initialAllPlayers: [],
      editingFolderAccess: false,
      editingFolderId: 0,
      dataRender: [],
      modalDataRender: [],
      selectedItem: {},
      selectedModalItem: {},
      gameFolders: [],
      staffFilters:[],
      inGameRole:[],
      selectedFilter:[],
      initialLoading: true,
      currentPathItem:{},
      shareVideoOpen: false,
      shareSelectedVideoId:null,// for share
      shareSelectedGameType: null,
      shareSelectedType: null,
      pagination:{
        page: 1,
        perPage:5,
        totalRecords: 0,
        totalPages: 0,
      },
      editSelectedVideoId:null,// for edit
      editSelectedGameType: null,
      editSelectedType: null,
      isMoveFile:{
        isMove:false,
        files:[],
      }, //for moving file
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

    this.handleGroupsData(message);
    this.handleSpecific(message);
    this.handleUserDataListCall(message);
    this.handleAddDataCall(message);
    this.handleAutoGeneratedFoldersCall(message);
    this.handleAutoGeneratedFolderSlCall(message)
    this.handleAutoInnerGeneratedFoldersCall(message);
    this.handleCustomFoldersCall(message);
    this.handleGetVideo(message);
    this.handleAutoInnerGeneratedSelectFoldersCall(message);
    this.handleCustomSelectFoldersCall(message);
    this.handleCreateCustomFoldersCall(message);
    this.handleDeleteCustomFoldersCall(message);
    this.handleGetAssignedPermissionRes(message);
    this.handleGetStaff(message);
    this.handleGetAllPlayer(message);
    this.handleEditFolderAccess(message);
    this.handleMoveFileResponse(message);
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = (await getStorageData("authToken")) || "";
    const permissions = (await getStorageData("permissions")) || "";
    const contentManagementBread = (await getStorageData("contentManagementBread")) || "";

    if (contentManagementBread && contentManagementBread !== "undefined") {
      const parsedBread = JSON.parse(contentManagementBread);
      if (parsedBread && parsedBread.length > 3) {
        this.getVideosApi(parsedBread[parsedBread.length - 1].id, token)
        this.setState({ levelBreads: parsedBread, folderId:parsedBread[parsedBread.length - 1].id  })
      }
    }

    await removeStorageData("contentManagementBread")
    
    this.setState({ token, permissions }, () => {
      this.getStaffAndPosition(token);
      this.getAllPlayer();
      this.getUserDataListApi();
      this.getAutoGeneratedFoldersApi();
    });
  }

  async componentWillUnmount() {
    if (window.api) {
      window.api.removeAllListeners('refresh-folder-game');
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.selectedItem !== prevState.selectedItem) {
      if (window.api) {
        window.api.receive('refresh-folder-game', () => {
          this.callBackCreateSuccess()
        });
      }
    }
  }
  callBackCreateSuccess = () => {
    this.getVideosApi(this.state.selectedItem.id)
    this.onClickItem(this.getLastObjArr(this.state.levelBreads), true);
    this.setState({
      selectLevels: initialBread,
      createSelectedPath: '',
      isBulkUploaderOpen: false,
      createGameFolderOpen: false
    },
      () => { this.getAutoGeneratedFolderSlApi() })
  }

  getStaffAndPosition = (token: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getStaffCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStaffAndPositionURL
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  generateURL(data:PlayerFilter[]) {
    const staffTypes = data.filter(obj => obj.type === 'Staff').map(obj => obj.label);
    const inGameRole = data.filter(obj => obj.type === 'In_Game_Role').map(obj => obj.label);
    const position = data.filter(obj => obj.type ==='Position').map(obj => obj.label);
    let url = '?';
    url += `staff=${staffTypes.join(',')}&sport_position=${position.join(',')}&in_game_role=${inGameRole.join(',')}`;
  
    return url;
  }
  replaceStaffValue = (queryString: string, newValue: string): string => {
    return queryString.replace(/(staff=)[^&]*/, `$1${newValue}`);
}
  getAllPlayer = (filter?:any) => {
  
    const {token} = this.state
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const profileDetail = this.getProfileDetail()
    let query = filter ? this.generateURL(filter) :"";
    if(profileDetail?.role == 'end user'){
      query = query==="" ? `?staff=end user` :this.replaceStaffValue(query,'end user') 
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPlayerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllPlayerURL + query
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getSpecific = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.specificData = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSpecificTeam}${this.state.idStore}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleMoveFileResponse = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (this.moveFilesCallId !== null && this.moveFilesCallId === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson: any) => {
          if(responseJson.message){
            toast.success(responseJson.message);
            this.hideCreateFolderModal();
            this.setState({selectMode:false})
          }else if(responseJson.error){
            toast.error(responseJson.error)
          }
          this.onClickItem(this.getLastObjArr(this.state.levelBreads), true);
        }
      );
    }
  };

  handleEditFolderAccess = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (
      this.editFolderAccessCallId !== null &&
      this.editFolderAccessCallId === dataMessage
    ) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson: GetAssignedPermissionResponse) => {
          if (responseJson.data) {
            toast.success("Folder permissions edited successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });
            this.setState((prev) => ({
              ...prev,
              customFolders: prev.customFolders.map((item) => {
                if (item.id === Number(responseJson.data.id)) {
                  return {
                    ...item,
                    attributes: {
                      ...item.attributes,
                      editing_permission_accounts:
                        responseJson.data.attributes
                          .editing_permission_accounts,
                      viewing_permission_accounts:
                        responseJson.data.attributes
                          .viewing_permission_accounts,
                    },
                  };
                }

                return item;
              }),
            }));

            this.closeViewPermissionModal();
          } else {
            toast.error("Edit access error!", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        }
      );
    }
  };

  handleSpecific = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (this.specificData !== null && this.specificData === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson: any) => {
          this.setState({
            dataPlayers: responseJson.data.attributes.viewing_permission,
            loading: false,
          });
        }
      );
    }
  };
  handleGroupsData = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (this.apiGroupCallId !== null && this.apiGroupCallId === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson: any) => {
          this.setState({
            userDataListPlayers: responseJson.teams,
            loading: false,
          });
        }
      );
    }
  };

  onFilterChange = (obj: PlayerFilter) => {
    this.setState(prevState => {
      let newState = { ...prevState };
      switch (obj.type) {
        case 'Staff':
          newState.staffList = prevState.staffList.map(item =>
            item.label === obj.label ? { ...item, selected: !item.selected } : item
          );
          break;
        case 'In_Game_Role':
          newState.inGameRole = prevState.inGameRole.map(item =>
            item.label === obj.label ? { ...item, selected: !item.selected } : item
          );
          break;
          case 'Position':
            newState.positionList = prevState.positionList.map(item =>
              item.label === obj.label ? { ...item, selected: !item.selected } : item
            );
            break;
        default:
          break;
      }
  
      const existingIndex = prevState.selectedFilter.findIndex(item => item.label === obj.label);
      if (existingIndex !== -1) {
        newState.selectedFilter = prevState.selectedFilter.filter((_, index) => index !== existingIndex);
      } else {
        newState.selectedFilter = [...prevState.selectedFilter, obj];
      }
  
      return newState;
    }, () => {
      this.getAllPlayer(this.state.selectedFilter);
    });
  };

  mappingFilterList = (data: any) => {
    const esports = data?.esports || [];
    const sports = data?.sports || [];
    const team_name=data.team_name||"";
    let staff;
    let inGameRole;
    let positions;
  
    if (esports.length > 0) {
      staff = esports
        .filter((item: PlayerFilter) => item.type === 'Staff')
        .map(({ label, id, type }: PlayerFilter) => ({ label, id, type, selected: false }));
  
      inGameRole = esports
        .filter((item: PlayerFilter) => item.type === 'In_Game_Role')
        .map(({ label, id, type }: PlayerFilter) => ({ label, id, type, selected: false }));
    } else if (sports.length > 0) {
      staff = sports
        .filter((item: PlayerFilter) => item.type === 'Staff')
        .map(({ label, id, type }: PlayerFilter) => ({ label, id, type, selected: false }));
  
      positions = sports
        .filter((item: PlayerFilter) => item.type === 'Position')
        .map(({ label, id, type }: PlayerFilter) => ({ label, id, type, selected: false }));
    }
    return { staff, inGameRole, positions,team_name};
  }
  getProfileDetail = () =>{
    try {
      const profileDetail = JSON.parse(sessionStorage.getItem("profile"))
      return profileDetail
    }catch(e){
      console.log(e)
    }
  }
  getStaffsData = (staffsData) => {
    if(this.getProfileDetail()?.role == 'end user'){
      return staffsData.filter((item) => item.label === 'end user')
    }
    return staffsData
  }
  handleGetStaff = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (this.getStaffCallId !== null && this.getStaffCallId === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson) => {        
          const result = this.mappingFilterList(responseJson)
          this.setState({ staffList: this.getStaffsData(result.staff), inGameRole: result.inGameRole,positionList:result.positions,currentTeamName:result.team_name })
        }
      );
    }
  };

  handleGetAllPlayer = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (
      this.getAllPlayerCallId !== null &&
      this.getAllPlayerCallId === dataMessage
    ) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson: GetAllMemeberResponse) => {
          if (responseJson.data) {
            const players = responseJson.data.map((item) => ({
              ...item,
              selected: false,
            }));
            this.setState({ allPlayers: players,initialAllPlayers:players });
          }else{
            this.setState({allPlayers:[],initialAllPlayers:[]})
          }
        }
      );
    }
  };

  getAutoGeneratedFolderSlApi = async () => {
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "ngrok-skip-browser-warning": "true",
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.autoGeneratedSelectFoldersCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.autoGeneratedFoldersUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getAutoInnerGeneratedSelectFoldersApi = async (item: any) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.autoInnerGeneratedSelectFoldersCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.autoInnerGeneratedFoldersUrl}+${item.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  getCustomSelectFoldersApi = async (item: any) => {
    this.setState({currentPathItem:item})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
      "ngrok-skip-browser-warning": "true",
    };
    const { type, innerId } = item;
    const query = type !== "auto_inner_generate_folder" ? `?auto_inner_generate_folder_id=${innerId}&parent_folder_id=${item.id}` : `?auto_inner_generate_folder_id=${item.id}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.customSelectFoldersCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCustomFoldersUrl + query
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  createCustomFoldersApi = async () => {
    const {selectedFolder} = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const body = {
      folder_name: this.state.createFolderName,
      auto_inner_generate_folder_id: selectedFolder?.innerId,
    };
    const nestedFolder = selectedFolder?.id?.toString() === selectedFolder?.innerId?.toString();

    if (!nestedFolder) {
      body.parent_folder_id = selectedFolder?.id;
      body.parent_id = selectedFolder?.id;
    }

    const views = this.state.assigneePermissions.filter(
      (item) => item.permission === "view"
    );
    const edits = this.state.assigneePermissions.filter(
      (item) => item.permission === "edit"
    );

    if (views.length) {
      body.viewing_permission = views.map((item) => `${Number(item.id)}`);
    }

    if (edits.length) {
      body.editing_permission = edits.map((item) => `${Number(item.id)}`);
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createCustomFoldersCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createCustomFolderUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteCustomFoldersApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCustomFoldersCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteCustomFolderUrl + this.state.deleteFolder.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getUserDataListApi = async () => {
    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userDataListCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userListUrl + this.state.category.value
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  postAddDataApi = async () => {
    const header = {};
    const formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("description", this.state.description);
    formData.append("price", this.state.price);
    formData.append("quantity", this.state.quantity);
    formData.append("user_type", this.state.category.value);

    if (this.isPlatformWeb()) {
      this.state.imagesWeb.forEach((item: File) => { });
    } else {
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addDataCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addDataUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  showCategory = () =>
    this.setState({
      showCategory: !this.state.showCategory,
    });

  onCloseSelectUploadModal = () => {
    this.setState({
      isSelectUploadModalOpen: false,
    });
  };

  // upload game step click
  onSelectUploadGameStep = () => {
    const userType = localStorage.getItem("typeSport");  
    if (userType === "eSports") {
      this.setState({
        isSelectUploadModalOpen: false,
        isSelectUploadGameTypeModalOpen: true,
      });
    } else {
      this.setState({
        isSelectUploadModalOpen: false,
        isBulkUploaderOpen: true,
      },()=>{this.handleAdvFolder()});
    }
  };

  onCloseSelectUploadGameTypeModal = () =>
    this.setState({
      isSelectUploadGameTypeModalOpen: false,
    });

  onCloseSelectFolderModal = () =>
    this.setState({
      isSelectFolderModalOpen: false,
    });

  onCreateUpload = () =>{
    this.setState({
      isSelectUploadModalOpen: true,
    });
  }
  checkPermissiondBtn = () => {
    const {permissions} = this.state;
    const texts = ["Upload", "Create Custom Folder", "Create Game Folder"];
    return !texts.some((text) => permissions.includes(text));
  };
  onInGameRecorder = () =>{
    this.setState({
      isSelectUploadGameTypeModalOpen: false,
      isSelectFolderModalOpen: true,
    });
  }
  transformGameFolderToCs = (data) => {
    return data?.map((item) => {
      const innerId = item?.auto_inner_generate_folder_id || '';
      return {
        ...item,
        type:"Game Folder",
        id: parseInt(item.id),
        innerId: innerId,
        name: this.handleLongString(item.folder_name),
        videos: item.of_videos || 0,
        fileSize: `${parseFloat(item.videos_size_gb).toFixed(1)} GB`,
        createdBy: item.created_by || 'Fantastic We',
        createdOn: this.formatDate(item.created_on),
        currentUser: item.current_user_folder || false,
        editAccess: item.edit_button || false,
        folder_type: item.type || '',
      };
    });
  };
  handleAdvFolder = () => {
    const { dataRender, levelBreads, attachmentVideos, createGameFolderOpen, isBulkUploaderOpen } = this.state;
    const isOpenBulkUpload = createGameFolderOpen || isBulkUploaderOpen;
    const selectedInit = levelBreads[levelBreads.length - 1];
    if(isOpenBulkUpload){
      if(attachmentVideos?.length > 0){
        const gameFolder = attachmentVideos?.length > 0&&  attachmentVideos.filter((obj) => obj?.type === "game_folders") || [];
      const game_folders = this.transformGameFolderToCs(gameFolder);
        this.setState({modalDataRender:game_folders,selectLevels:levelBreads,selectedModalItem:selectedInit,selectedFolder:selectedInit},()=>{this.getPathModalCreate()})
        return
      }else{
        this.setState({modalDataRender:dataRender,selectedFolder:selectedInit,selectLevels:levelBreads},()=> this.getPathModalCreate())
      }
    }  
  }

  handleBackBtn =()=>{
    const {selectLevels} = this.state;
    const lastItem = selectLevels[selectLevels.length-2];
    const newSelectLevels  =selectLevels.slice(0,selectLevels.length-1)
    this.setState({selectLevels:newSelectLevels,selectedModalItem:lastItem},()=>{this.getInsideFolder()})
  }

  onBulkGameUpload = () =>{
     this.setState({ 
      isSelectUploadGameTypeModalOpen: false,
      isBulkUploaderOpen: true},()=>{this.handleAdvFolder()}) 
  }

  onCloseBulkUploadModal = () => {
    this.setState({
      isBulkUploaderOpen: false,
      createGameFolderOpen: false,
      shareSelectedVideoId: null,
      shareSelectedGameType: null,
      shareSelectedType:null,
      editSelectedVideoId:null,
      editSelectedGameType:null,
      editSelectedType:null
    });
  };
  
  onSelectCategory = (item: CategoryType) => {
    this.setState({ category: item, showCategory: false }, () => {
      this.getUserDataListApi();
    });
  };

  addNewProduct = () => this.setState({ showModel: true });

  hideModal = () => this.setState({ showModel: false });

  openImagePicker = () => {
    ImagePicker?.openCamera({
      width: 300,
      height: 400,
      cropping: false,
      includeBase64: true,
    }).then((ress) => {
      this.setState({
        images: [
          ...this.state.images,
          {
            uris: ress.path,
            width: 300,
            height: 400,
          },
        ],
      });
    });
  };

  onChangeTitle = (text: string) => {
    this.setState({ title: text });
  };

  onChangeDescription = (text: string) => {
    this.setState({ description: text });
  };

  onChangePrice = (text: string) => {
    this.setState({ price: text });
  };

  onChangeQuantity = (text: string) => {
    this.setState({ quantity: text });
  };

  handleChangeLayout = () => {
    this.setState({ isLayoutGrid: !this.state.isLayoutGrid });
  };

  handleSelectMode = () => {
    this.setState({ selectMode: !this.state.selectMode });
  };

  hideDeleteFolderModal = () =>
    this.setState({
      deleteFolderModal: false,
      deleteFolder: { id: null, name: "", size: "" },
    });

  setDeleteFolder = (id: number, name: string, size: string) => {
    const deleteFolder: DeleteFolder = { id: id, name: name, size: size };
    this.setState({ deleteFolder: deleteFolder, deletingFolder: true });
  };

  deleteFolder = () => {
    this.deleteCustomFoldersApi();
  };

  hideCreateFolderModal = () => {
    this.setState({
      createFolderModal: false,
      createFolderName: "",
      createSelectedPath: "",
      assigneePermissions: [],
      selectedModalItem: {},
      selectLevels: initialBread,
      isMoveFile:{isMove:false,files:[]},
    })
  };

  showCreateFolderModal = () => {
    this.setState((prev) => ({
      createFolderModal: true,
      allPlayers: prev.initialAllPlayers,
    }),()=>{this.getAutoGeneratedFolderSlApi()});

  };

  createFolder = () => {
    if(this.state.isMoveFile.isMove){
      //call move file function
      this.moveFiles();
    }else{
      this.createCustomFoldersApi();
    }
  };

  hideSelectFolderModal = () => {
    this.setState({
      selectFolderModal: false,
      selectedFolder:{}
    });
    this.resetModalSelectFolder()
  };

  showSelectFolderModal = () => this.setState({ selectFolderModal: true });

  handleCreateFolderNameChange = (event) => {
    this.setState({ createFolderName: event.target.value });
  };

  formatDate = (dateStr: string | null) => {
    if (dateStr === null) {
      return "";
    } else {
      // Remove the UTC part and parse the date string
      const parsedDateStr = dateStr.replace(" UTC", "");
      const dateObj = new Date(parsedDateStr);
      
      // Check if the date is valid
      if (isNaN(dateObj.getTime())) {
        return ""; // Invalid date
      }
  
      // Get month, day, and year
      const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
      const day = String(dateObj.getUTCDate()).padStart(2, '0');
      const year = String(dateObj.getUTCFullYear()).slice(-2); // Get the last two digits of the year
      
      // Format the date to "MM DD YY"
      const formattedDate = `${month}/${day}/${year}`;
      return formattedDate;
    }
  }

  //helper functions
  transformAutoGeneratedFolderData = (data: any) => {
    return data.map((item) => ({
      id: parseInt(item.id),
      name: item.attributes.folder_name,
      type: item.type,
      of_competitive_videos: parseInt(item.attributes.of_competitive_videos),
      of_practice_videos: parseInt(item.attributes.of_practice_videos),
      createdBy: item.attributes.created_by || "Fantastic We",
      createdOn: this.formatDate(item.attributes.created_on),
    }));
  };

  transformAutoInnerGeneratedFolderData = (data) => {
    return data.map((item) => ({
      id: parseInt(item.id),
      name: item.attributes.folder_name,
      type: item.type,
      videos: parseInt(item.attributes.of_videos),
      fileSize: `${parseFloat(item.attributes.videos_size_gb).toFixed(1)} GB`,
      createdBy: item.attributes.created_by || "Fantastic We",
      innerId: (item.id),
      createdOn: this.formatDate(item.attributes.created_on),
      ...item
    }));
  };

  handleLongString = (text:string)=>{
      if (text?.length > 100) {
        return text.slice(0, 100) + '...';
      } else {
        return text;
      }
  }
  
  transformCustomFolderData = (data) => {

    return data.map((item) => {
      const innerId = item?.attributes?.auto_inner_generate_folder_id ? item?.attributes?.auto_inner_generate_folder_id : "";
      return ({
        ...item,
        id: parseInt(item.id),
        innerId: innerId,
        name: this.handleLongString(item.attributes.folder_name),
        videos: item.attributes.of_videos || 0,
        fileSize: `${parseFloat(item.attributes.videos_size_gb).toFixed(1)} GB`,
        createdBy: item.attributes.created_by || "Fantastic We",
        createdOn: this.formatDate(item.attributes.created_on),
        currentUser: item.attributes.current_user_folder || false,
        editAccess: item.attributes.edit_button || false,
        folder_type: item.type || ""
      })
    });
    
  };

  //recieve functions

  handleResponse = (responseJson, errorResponse, successCallback) => {
    if (responseJson) {
      successCallback(responseJson);
    } else {
      this.setState({ loading: false });
      this.parseApiErrorResponse(errorResponse);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };

  handleUserDataListCall = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (
      this.userDataListCall !== null &&
      this.userDataListCall === dataMessage
    ) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson) => {
          this.setState({
            userDataList: responseJson.data,
            loading: false,
          });
        }
      );
    }
  };

  handleAddDataCall = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (this.addDataCall !== null && this.addDataCall === dataMessage) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson) => {
          Alert.alert(configJSON.success);
          this.setState({
            loading: false,
            showModel: false,
          });
          this.getUserDataListApi();
        }
      );
    }
  };

  handleAutoGeneratedFoldersCall = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (
      this.autoGeneratedFoldersCall !== null &&
      this.autoGeneratedFoldersCall === dataMessage
    ) {
      this.setState({initialLoading:false})
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson) => {

          const folders = this.transformAutoGeneratedFolderData(
            responseJson.data
          );
          this.setState({
            dataRender: folders,
            
          });
        }
      );
    }
  };
  handleAutoGeneratedFolderSlCall = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (
      this.autoGeneratedSelectFoldersCall !== null &&
      this.autoGeneratedSelectFoldersCall === dataMessage
    ) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson) => {
          if (!!responseJson?.data && responseJson.data.length > 0) {
            const folders = this.transformAutoGeneratedFolderData(
              responseJson.data
            );
            this.setState({
              modalDataRender: folders,
            });
          }

        }
      );
    }
  };

  handleAutoInnerGeneratedFoldersCall = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (
      this.autoInnerGeneratedFoldersCall !== null &&
      this.autoInnerGeneratedFoldersCall === dataMessage
    ) {
      this.setState({initialLoading:false})
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson) => {
          const folders = ("error" in responseJson) ? [] : this.transformAutoInnerGeneratedFolderData(responseJson.data);
          this.setState({
            dataRender: folders,
          });
        }
      );
    }
  };

  handleCustomFoldersCall = (message: Message) => {
    const {currentPathItem } = this.state;
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (
      this.customFoldersCall !== null &&
      this.customFoldersCall === dataMessage
    ) {
      this.setState({ initialLoading: false })
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson) => {
          if (responseJson.status === 500) {
            toast.error("Internal server error!", { position: 'top-center' })
            this.setState(prev => ({ levelBreads: prev.levelBreads.slice(0, -1) }))
          }

          if (responseJson?.data?.length > 0) {
            const normalFolders = responseJson.data.filter(item => item.attributes.folder_type !== "Game Folder")
            const folders = this.transformCustomFolderData(normalFolders);
            this.setState({
              customFolders: folders,
              dataRender: folders,
            });
          } else {
            this.setState({
              customFolders: [],
              dataRender: [],
            });
              this.getVideosApi(currentPathItem.id)
          }
        }
      );
    }
  };

  mappingDataVideos(obj:any) {
    const result = obj.data.map((item:any) => ({
      ...item.attachment,
      type: "attachment",
    }));
    const gameFolderData = Array.isArray(obj.game_folders) ? obj.game_folders : obj.game_folders.data
    const gameFolders = gameFolderData.map((folder) => ({
      ...folder.attributes,
      type: "game_folders",
      name:folder?.attributes?.folder_name || '',
    }));
  
    return [...result, ...gameFolders];
  }

  transformDataGamefolder = (data) => {
    const result = data?.map((item) => {
      const {
        id,
        level,
        folder_type,
        folder_name,
        parent_folder_name,
        created_by,
        created_on,
        of_videos,
        videos_size_gb,
        assigned,
        viewing_permission,
        editing_permission,
        my_team_name,
        opponent_team_name,
        game_due_date,
        game_type,
        auto_inner_generate_folder_id,
        edit_button,
        current_user_folder,
        photo,
        thumbnail,
        editing_permission_accounts,
        viewing_permission_accounts,
        attributes,
        type,
        ...rest
      } = item;
      return {
        id,
        type: attributes.folder_type,
        innerId: auto_inner_generate_folder_id,
        name: attributes.folder_name,
        createdBy: attributes.created_by,
        createdOn: attributes.game_due_date,
        videos:attributes.of_videos,
        fileSize:`${parseFloat(attributes.videos_size_gb).toFixed(1)} GB`,
        folder_type,
        currentUser: attributes?.current_user_folder || false,
        editAccess: attributes?.edit_button || false,
        ...rest,
      };
    });
    return result;
  }
  handleDataVideo = (responseJson)=>{
    const { selectFolderModal,modalDataRender,isBulkUploaderOpen,createGameFolderOpen } = this.state;
    const game_folders = responseJson?.game_folders?.data || [];
    if(game_folders.length>0){
      if(isBulkUploaderOpen || createGameFolderOpen && selectFolderModal){
        this.setState({ modalDataRender: this.transformDataGamefolder(game_folders) })
      }else if(modalDataRender?.length === 0 && game_folders?.length === 0){
        this.setState({selectFolderModal:false})
      }
    }
  }
 processVideoData = (response) => {
  const gameFolders = []
  const attachments = []
  response.data.forEach((item) =>{
    if(item.attachment) {
      attachments.push(item)
    } else {
      gameFolders.push({...item.data})
    }
  })
  return {
    gameFolders,attachments
  }
 }
  handleGetVideo = (message: Message) => {
    const { selectFolderModal } = this.state;
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (
      this.getVideosCall !== null &&
      this.getVideosCall === dataMessage
    ) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson) => {
          if (responseJson?.data) {
            this.setState({
              ...this.state,
              pagination:{
                ...this.state.pagination,
                totalRecords: responseJson.meta.total_records,
                totalPages: responseJson.meta.total_pages
              }
            })
            const {gameFolders,attachments} = this.processVideoData(responseJson)
            responseJson = {
              data: attachments,
              game_folders:{data: gameFolders}
            }
            this.handleDataVideo(responseJson)
            if(!selectFolderModal){
              this.setState({
                attachmentVideos: this.mappingDataVideos(responseJson),
              });
            }
            
          }
          this.setState({initialLoading:false})
        }
      );
    }
  };

  handleAutoInnerGeneratedSelectFoldersCall = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (
      this.autoInnerGeneratedSelectFoldersCall !== null &&
      this.autoInnerGeneratedSelectFoldersCall === dataMessage
    ) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson) => {
          if ("error" in responseJson) {
          } else {
            const folders = this.transformAutoInnerGeneratedFolderData(
              responseJson.data
            )
            this.setState({
              modalDataRender: folders,
              selectedModalItem: {}
            });
          }
        }
      );
    }
  };


  callBackAfterGetFoldersResponse = (responseJson) => {
    const {currentPathItem,createFolderModal } = this.state;
    if(responseJson?.data?.length>0){
      const folders = this.transformCustomFolderData(responseJson.data);
      this.setState({modalDataRender:folders})
      this.getPathModalCreate();
    }else{
      this.setState({ modalDataRender:[] });
      if(createFolderModal){
        this.setState({selectFolderModal:false})
        this.getPathModalCreate();
      }else{
        this.getVideosApi(currentPathItem.id);
      this.getPathModalCreate();
      }

      return
    }
    
  }
  handleCustomSelectFoldersCall = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const customSelectFoldersCall = this.customSelectFoldersCall;

    if (customSelectFoldersCall !== null && customSelectFoldersCall === dataMessage) {
      const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

      this.handleResponse(successMessage, errorMessage, (responseJson) => {
        this.callBackAfterGetFoldersResponse(responseJson)
      });
    }
  };
  getLastObjArr = (arr) => {
    if (arr.length === 0) {
      return null;
    }
    return arr[arr.length - 1];
  }

  handleCreateCustomFoldersCall = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (
      this.createCustomFoldersCall !== null &&
      this.createCustomFoldersCall === dataMessage
    ) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson: any) => {
          if (responseJson.data) {
            this.setState({
              idStore: responseJson.data.id,
              loading: false,
              createFolderModal: false,
              selectLevels:initialBread,
              isMoveFile:{isMove:false,files:[]},
            });
            this.resetModalSelectFolder();
            toast.success(
              "Folder created successfully!You can now add players by clicking on Viewing Permission",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
          } else if (responseJson.error) {
            toast.error(`${responseJson.error}`, {
              position: toast.POSITION.TOP_CENTER,
            });
          }

          this.onClickItem(this.getLastObjArr(this.state.levelBreads), true);
        }
      );
    }
  };

  handleDeleteCustomFoldersCall = (message: Message) => {
    const dataMessage = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    if (
      this.deleteCustomFoldersCall !== null &&
      this.deleteCustomFoldersCall === dataMessage
    ) {
      this.handleResponse(
        message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
        message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
        (responseJson) => {
          if (
            responseJson.message &&
            responseJson.message === "Folder deleted successfully"
          ) {
            this.setState({ deleteFolderModal: false, deletingFolder: false });

            toast.success("Folder deleted successfully!", {
              position: toast.POSITION.TOP_CENTER,
            });

            this.onClickItem(this.getLastObjArr(this.state.levelBreads), true);
          }
        }
      );
    }
  };

  viewPermission = () => {
    this.setState((prev) => ({
      viewPermissionOpen: true,
      allPlayers: prev.initialAllPlayers,
    }));
  };

  closeViewPermissionModal = () => {
    this.setState((prev) => ({
      viewPermissionOpen: false,
      assigneePermissions: [],
      allPlayers: prev.initialAllPlayers,
    }));
  };

  handleChangeAssigneePermission = (
    selectedOption: { label: string; value: string },
    { option }: { option: { id: number } }
  ) => {
    this.setState((prev) => {
      const { assigneePermissions } = prev;
      let newAssigneePermissions;

      if (selectedOption.value === "") {
        newAssigneePermissions = assigneePermissions.filter(
          (item) => item.id !== option.id
        );
      } else {
        newAssigneePermissions = assigneePermissions.map((item) => {
          if (item.id === option.id) {
            return { ...item, permission: selectedOption.value };
          }

          return item;
        });
      }
      return {
        ...prev,
        assigneePermissions: newAssigneePermissions,
      };
    });
  };
  getSelectedPlayers = (assigneePermissions) =>{
    const assigneePermissionsIdSet = new Set()
    assigneePermissions.forEach((item) => assigneePermissionsIdSet.add(item.id.toString()))
    const intialPlayers = this.state.initialAllPlayers
    const players = intialPlayers.map(item =>{
      if(assigneePermissionsIdSet.has(item.id)){
        return {
          ...item,
          selected: true,
        };
      }
      return {
        ...item,
        selected: false,
      };
    })
    return players;
  }
  handleClickAssignUser = () => {
    this.setState((prev) => {
      return {
        assignViewPermissionOpen: true,
        allPlayers:this.getSelectedPlayers(prev.assigneePermissions)
      };
    });
  };

  closeAssignViewPermissionModal = () => {
    this.setState((prev) => ({
      assignViewPermissionOpen: false,
    }));
  };

  formatNameAsAvatar = (name: string) => {
    const char = name.split(" ");

    if (char[1]) {
      return char[0].split("")[0] + char[1].split("")[0];
    }

    return char[0].split("")[0];
  };

  handleSelectAssignee = (id: number) => {
    this.setState(prev => ({
      ...prev,
      allPlayers: prev.allPlayers.map(item => {
        if (item.id === id) {
          item.selected = !item.selected
        }

        return item;
      })
    }))
  }

  handleOpenCreateGameFolder = () => {
    this.setState({ createGameFolderOpen: true, isSelectUploadModalOpen: false },()=>{this.handleAdvFolder()})
  }

  handleGetAssignedPermissionRes = (message: Message) => {
    const dataMsg = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (
      [
        this.assignPermissionFolderCallId,
      ].includes(dataMsg)
    ) {
      const successData = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorData = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      this.handleResponse(
        successData,
        errorData,
        (responseJson: GetAssignedPermissionResponse) => {
          const { data } = responseJson;
          const attributes = data?.attributes;
          if (attributes) {
            const { editing_permission_accounts, viewing_permission_accounts } =
              attributes;

            const editingAccounts =
              editing_permission_accounts?.map((item) =>
                this.mapUserAccount(item, "edit")
              ) || [];
            const viewingAccounts =
              viewing_permission_accounts?.map((item) =>
                this.mapUserAccount(item, "view")
              ) || [];

            this.setState({
              assigneePermissions: [...editingAccounts, ...viewingAccounts],
              ...(this.assignPermissionFolderCallId === dataMsg && {
                viewPermissionOpen: false,
              }),
            });
          }
        }
      );
    }
  }

  mapUserAccount = (userAccount: UserAccount, permission: "edit" | "view") => ({
    id: userAccount.id,
    name: `${userAccount.first_name} ${userAccount.last_name}`,
    permission,
    avatar: userAccount.photo || "",
    rolename: userAccount.rolename
  });

  getAssignedPermissionMap = (assignedPermissions) =>{
    const assignedPermissionMapData = new Map()
    assignedPermissions.forEach((item) =>{
      assignedPermissionMapData.set(item.id.toString(),item)
    })
    return assignedPermissionMapData;
  }
  //check assignpermission is present in staff player
  getAssignedPermissions = () =>{
    const allPlayerMap = this.getAssignedPermissionMap(this.state.allPlayers)
    const assignedPermission = this.state.assigneePermissions.filter(item => {
      if(allPlayerMap.get(item.id.toString())){
        return false
      }
      return true
    })
    return assignedPermission
  }
  assignPermissionFolder = () => {
    const assignedPermissionMapData = this.getAssignedPermissionMap(this.state.assigneePermissions)
    const assigneedPermissions = this.state.allPlayers
    .filter((item) => item.selected)
    .map((item) => {
      const assignedPermission = assignedPermissionMapData.get(item.id)

      return {
        ...item,
        name: item.attributes.first_name + " " + item.attributes.last_name,
        permission:  assignedPermission ? assignedPermission.permission : "view",
        avatar: item.attributes.photo || "",
        rolename: item.attributes.role?.name
      }
    })
    this.setState((prev) => ({
      ...prev,
      assigneePermissions: [...this.getAssignedPermissions(),...assigneedPermissions],
      assignViewPermissionOpen: false,
      allPlayers: this.getSelectedPlayers(assigneedPermissions),
    }));
  };
  getPermissions = () => {
    const profileDetail = this.getProfileDetail()
    const body = {};
    const views = this.state.assigneePermissions.filter(
      (item) =>
        item.permission === "view" &&
        (profileDetail.role !== "end user" || item.rolename === "end user")
    );
    const edits = this.state.assigneePermissions.filter(
      (item) =>
        item.permission === "edit" &&
        (profileDetail.role !== "end user" || item.rolename === "end user")
    );
    if (views.length) {
      body.viewing_permission = views.map((item) => Number(item.id))
    }
    if (edits.length) {
      body.editing_permission = edits.map((item) => Number(item.id))
    }
    return body;
  }
  saveEditAccess = () => {
    if (this.state.editingFolderAccess) {
      const editFolderAccessApi = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.editFolderAccessCallId = editFolderAccessApi.messageId;

      editFolderAccessApi.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.assignPermissionFolderUrl}${this.state.editingFolderId}`
      );

      editFolderAccessApi.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: this.state.token,
        })
      );

      editFolderAccessApi.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchMethod
      );
      const body = this.getPermissions()
      editFolderAccessApi.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

      runEngine.sendMessage(editFolderAccessApi.id, editFolderAccessApi);

      return;
    }

    this.setState({ viewPermissionOpen: false });
  };


  closeDeleteFolder = () => {
    this.setState({ deletingFolder: false });
  };

  hideToast = () => {
    this.setState({ toastOpen: false, toastMessage: false });
  };

  onEditAccessClick = (folderId: number) => {
    this.setState((prev) => {
      const selectedFolder = this.state.customFolders.find(
        (item) => item.id === folderId
      );

      let assigneePermissions = [];

      if (selectedFolder) {
        const { editing_permission_accounts, viewing_permission_accounts } =
          selectedFolder.attributes;

        if (editing_permission_accounts && editing_permission_accounts.length) {
          assigneePermissions.push(
            ...editing_permission_accounts?.map((item) =>
              this.mapUserAccount(item, "edit")
            )
          );
        }

        if (viewing_permission_accounts && viewing_permission_accounts.length) {
          assigneePermissions.push(
            ...viewing_permission_accounts?.map((item) =>
              this.mapUserAccount(item, "view")
            )
          );
        }
      }

      return {
        ...prev,
        editingFolderAccess: true,
        viewPermissionOpen: true,
        assigneePermissions,
        editingFolderId: folderId,
      };
    });
  };
  
  handleBreadCrumbs = (item: number) => {
    const {levelBreads} = this.state;
    const indexItem = levelBreads.findIndex(
      (folder) =>
        folder.id === item.id && folder.breadCrumbName === item.breadCrumbName
    );
    
  
    const newBread = levelBreads.slice(0, indexItem + 1);
  
    this.setState((prevState) => ({
      attachmentVideos: prevState.attachmentVideos.length > 0 ? [] : prevState.attachmentVideos,
      gameFolders: prevState.gameFolders.length > 0 ? [] : prevState.gameFolders,
      levelBreads: newBread,
      currentPathItem:item,
    }), () => {
      switch (item.type) {
        case "auto_generate_folder":
          this.getAutoInnerGeneratedFoldersApi2(item, true);
          break;
        case "auto_inner_generate_folder":
        case "new_folder":
          this.getCustomFoldersApi2(item, true);
          break;
          case "game_folders":
          this.getVideosApi(item.id);
          break;
        case "init":
          this.getAutoGeneratedFoldersApi();
          break;
        default:
          break;
      }
    });
  };

  handleSelectBreadCrumbs = (item: number) => {
    const { selectLevels } = this.state;
    const indexItem = selectLevels.findIndex(
      (folder) =>
        folder.id === item.id && folder.type === item.type
    );
    const newBread = selectLevels.slice(0, indexItem + 1);
    this.handleCallFolderItem(item)
    this.setState({ selectLevels: newBread })
  };

  getAutoGeneratedFoldersApi = async () => {
    this.setState({initialLoading:true})
    const { token } = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "ngrok-skip-browser-warning": "true",
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.autoGeneratedFoldersCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.autoGeneratedFoldersUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  setNewBreadCrumb = (item: any, breadcrumb?: boolean) => {
    const innerId = item?.attributes?.auto_inner_generate_folder_id ? item.attributes.auto_inner_generate_folder_id : "";
    const valid = (item?.attributes?.edit_button || item?.edit_button || item.type === "auto_inner_generate_folder") ?  true :false;
    const newLevel = {
      id: item.id,
      breadCrumbName: breadcrumb
        ? item.breadCrumbName
        : item.name,
      type: item.type,
      innerId,
      permission:valid,
      editAccess:valid,
    };
    if (!breadcrumb) {
      this.setState((prevState) => ({
        levelBreads: [...prevState.levelBreads, newLevel]
      }));
    }
  }
  getAutoInnerGeneratedFoldersApi2 = async (
    item: any,
    breadcrumb?: boolean
  ) => {
    this.setState({initialLoading:true})
    this.setNewBreadCrumb(item, breadcrumb)
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
      "ngrok-skip-browser-warning": "true",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.autoInnerGeneratedFoldersCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.autoInnerGeneratedFoldersUrl + item.id
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCustomFoldersApi2 = async (item: number, breadcrumb?: boolean) => {
    this.setState({initialLoading:true,currentPathItem:item})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
      "ngrok-skip-browser-warning": "true",
    };
    const { type, innerId } = item;

    this.setNewBreadCrumb(item, breadcrumb)
    const query = type !== "auto_inner_generate_folder" ? `?auto_inner_generate_folder_id=${innerId}&parent_folder_id=${item.id}` : `?auto_inner_generate_folder_id=${item.id}`;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.customFoldersCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCustomFoldersUrl + query
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getVideosApi = async (id: any, token: string = this.state.token) => {
    this.setState({initialLoading:true})
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
      'ngrok-skip-browser-warning': 'true'
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getVideosCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.videosInFolderURL}/${id}/get_folder_attachments?page=${this.state.pagination.page}&per_page=${this.state.pagination.perPage}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header));

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onClickItem = (item: any, breadcrumb?: boolean) => {
    this.setState({ selectedItem: item })

    if (item.type === "auto_generate_folder") {
      const isCheckExistLevelBread = this.state.levelBreads.some(itemBread => itemBread.id === item.id && item.type === itemBread.type)
      if (!isCheckExistLevelBread || breadcrumb)
        this.getAutoInnerGeneratedFoldersApi2(item);

    } else if (
      item.type === "auto_inner_generate_folder" ||
      item.type === "new_folder"
    ) {
      const isCheckExistLevelBread = this.state.levelBreads.some(itemBread => itemBread.id === item.id && item.type === itemBread.type)
      if (!isCheckExistLevelBread || breadcrumb) {
        this.getCustomFoldersApi2(item, breadcrumb);
      }
    }
  };

  onRowModalClick = (item: any) => {
    this.setState({ selectedModalItem: item })
  }

  isEmpty = (obj: any) => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  setSelectPath = (item: any) => {
    const {selectLevels} = this.state;
    const innerId = item?.attributes?.auto_inner_generate_folder_id ? item.attributes.auto_inner_generate_folder_id : ""
    const newLevel = {
      id: item.id,
      breadCrumbName: item.name,
      type: item.type,
      innerId
    };
    const isExistFolderPath = selectLevels.some(level => level.id === newLevel.id && level.type === newLevel.type);
    if (!isExistFolderPath) {
      this.setState((prevState) => ({
        selectLevels: [...prevState.selectLevels, newLevel],
        selectedFolder:item,
      }),()=>this.getPathModalCreate());
      
    }
  }
  handleCallFolderItem = (item)=>{
    switch (item.type) {
      case "auto_generate_folder":
        this.getAutoInnerGeneratedSelectFoldersApi(item);
        break;
      case "auto_inner_generate_folder":
      case "new_folder":
        this.getCustomSelectFoldersApi(item);
        break;
      case "init":
        this.getAutoGeneratedFolderSlApi();
        break;
      default:
        break;
    }
  }

  getInsideFolder = () => {
    const { selectedModalItem,createGameFolderOpen,isBulkUploaderOpen} = this.state;
    const openBulkUpload = isBulkUploaderOpen || createGameFolderOpen;
    if ((openBulkUpload) && (selectedModalItem?.type === "Game Folder" || selectedModalItem?.type === "game_folders" )) {
      return;
    }
    if (!this.isEmpty(selectedModalItem)) {
      this.handleCallFolderItem(selectedModalItem);
      this.setSelectPath(selectedModalItem)
    }
  };

  onClickSelectBtn = () => {
    const { selectedModalItem, selectLevels, createFolderModal, modalDataRender } = this.state;

    if (this.shouldPreventSelection(createFolderModal, modalDataRender, selectLevels, selectedModalItem)) {
      this.setState({ selectedModalItem: {} });
      this.showSelectionError();
      return;
    }

    if (!this.isEmpty(selectedModalItem)) {
      this.selectFolder(selectedModalItem, selectLevels);
    }
  };

  shouldPreventSelection = (createFolderModal, modalDataRender, selectLevels, selectedModalItem) => {
    if (createFolderModal || !modalDataRender) return false;

    const newFolderExists = modalDataRender.some(folder => folder.type === "new_folder");
    const isExistSelected = selectLevels.some(level => level.id === selectedModalItem.id);

    return newFolderExists && isExistSelected;
  };

  showSelectionError = () => {
    toast.error("Cannot select this folder!", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  selectFolder = (selectedModalItem, selectLevels) => {
    this.setState({ selectedFolder: selectedModalItem, selectFolderModal: false }, () => {
      const newLevel = this.createNewLevel(selectedModalItem);
      const updatedLevels = this.computeNewLevels(selectLevels, newLevel);

      if (!updatedLevels) return;

      const Path = this.createPathString(updatedLevels);
      this.setState({ createSelectedPath: Path, selectLevels: updatedLevels });
    });
  };

  createNewLevel = (selectedModalItem) => {
    const innerId = selectedModalItem?.attributes?.auto_inner_generate_folder_id || "";
    return {
      id: selectedModalItem.id,
      breadCrumbName: selectedModalItem.name,
      type: selectedModalItem.type,
      innerId
    };
  };

  computeNewLevels = (selectLevels, newLevel) => {
    const lastItemLevel = selectLevels[selectLevels.length - 1];
    const isSameFolder = this.state.modalDataRender.some(folder => folder?.id === lastItemLevel?.id && folder?.innerId === lastItemLevel?.innerId);
    const isExistLevel = selectLevels.some(level => (level.id === newLevel.id && level.innerId === newLevel.innerId));

    if (isExistLevel) return null;

    let newLevels = [...selectLevels];
    if (isSameFolder) {
      newLevels[newLevels.length - 1] = newLevel;
    } else {
      newLevels.push(newLevel);
    }

    return newLevels;
  };

  createPathString = (levels) => {
    return levels.map(obj => obj.breadCrumbName).filter((str) => str !== null).join(" > ");
  };
  
  onDoubleClickItem = (item: any) => {
    this.setState({ selectedModalItem: item }, () => this.getInsideFolder())
  }
  getPathModalCreate = () => {
    const PathFolders = this.state.selectLevels.map(obj => obj.breadCrumbName).filter((str) => str !== null);
    const Path = PathFolders.join(" > ");
    this.setState({ createSelectedPath: Path })
  }

  getLastNewFolderValue = (data) => {
    let lastNewFolderValue = null;
    for (let i = data.length - 1; i >= 0; i--) {
      if (data[i].type === "new_folder" || data[i].type === "Game Folder" || data[i].type === "game_folders") {
        lastNewFolderValue = data[i].id;
        break;
      }
    }

    return lastNewFolderValue;
  }

  getSelectedLevelPath = () => {
    const data = this.state.selectLevels;
    let result = {};

    for (let obj of data) {
      if (obj.type === "auto_generate_folder") {
        result.first = obj.id || '';
      } else if (obj.type === "auto_inner_generate_folder") {
        result.second = obj.id || '';
      }
    }
    result.third = this.getLastNewFolderValue(data) || '';
    return result;
  }
 
  resetModalSelectFolder = () => {
    this.setState({assigneePermissions: [] }, () => {
      this.handleAdvFolder()
    })

  }

    handleShareVideo = (videoId:number|string,gameType:string,type: string) => {
    this.setState({
      shareSelectedVideoId:videoId,
      shareSelectedGameType: gameType,
      isBulkUploaderOpen:true, // open bulk upload,
      shareSelectedType: type,
    })
  }
  setPagination = ({perPage,page}) =>{
    const {pagination} = this.state
    this.setState({
      ...this.state,
      pagination:{
        ...pagination,
        perPage: perPage ? perPage : pagination.perPage,
        page: page ? page: pagination.page

      }
    },() =>{
      this.getVideosApi(this.state.selectedItem.id)
    })
  } 

  handleEditGameData = (videoId:number|string,gameType:string,type: string) => {
    if(type==="attachment"){
      this.setState({
        editSelectedVideoId:videoId,
        editSelectedGameType: gameType,
        editSelectedType: type,
        isBulkUploaderOpen:true, // open bulk upload,
      })
    }
  }
  handleMoveFile=(file:FileItem)=>{
    this.setState((prev) => ({
      isMoveFile: {
        isMove: true, // Set isMove to true
        files: [
          ...prev.isMoveFile.files.map(file => ({
            ...file,
            type: file.type === 'game_folders' ? 'gameFolder' : file.type
          })),
          ...file.map(file => ({
            ...file,
            type: file.type === 'game_folders' ? 'gameFolder' : file.type
          }))
        ]
      },
      createFolderModal: true,
      allPlayers: prev.initialAllPlayers,
    }), () => {
      this.getAutoGeneratedFolderSlApi();
    });
  }

  moveFiles=()=>{
    const {selectedFolder} = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const body = {
      files:this.state.isMoveFile.files,
      path:this.state.createSelectedPath.toString()
    };
    const nestedFolder = selectedFolder?.id?.toString() === selectedFolder?.innerId?.toString();

    if (!nestedFolder) {
      body.new_parent_id = selectedFolder?.id;
      body.parent_id = selectedFolder?.id;
    }    
    const edits = this.state.assigneePermissions.filter(
      (item) => item.permission === "edit"
    );
    const views = this.state.assigneePermissions.filter(
      (item) => item.permission === "view"
    );
    
    if (edits.length) {
      body.editing_permission = edits.map((item) => `${Number(item.id)}`);
    }
    if (views.length) {
      body.viewing_permission = views.map((item) => `${Number(item.id)}`);
    }


    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.moveFilesCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.moveFilesEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  // Customizable Area End
}
