// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";

const ConnectionView = ({
  connection,
  connectionParticipants,
  sendMessage
}) => {
  // REVIEW: all labels in all files will need to be extracted to config.js. E.g. Close connection

  return (
    <div style={webStyles.mainContainer}>
      <button
        onClick={connection.close}
        data-testid="closeConnection"
        className={"button"}
      >
        Close Connection
      </button>

      <button
        onClick={sendMessage}
        className={"button"}
        data-testid="sendMessage"
      >
        Send Meessage
      </button>

      <button
        onClick={() => {
          connection.meeting.end();
        }}
        className={"button"}
        data-testid="endMeeting"
      >
        End Meeting
      </button>
      <p>
        {connection.id} : {connection.payload}
      </p>
      {connectionParticipants.map(participant => {
        return (
          <div style={webStyles.participantContainer}>
            <Typography variant="h6" data-testid="participantName">
              {participant.displayName}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

const webStyles = {
  mainContainer: {
    width: 400,
    backgroundColor: "#3E84F6",
    borderRadius: 8,
    overflow: "hidden",
    margin: 8,
    padding: 8,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    position: "relative"
  },
  participantContainer: {
    padding: 4,
    border: "1px solid blue"
  }
};

export default ConnectionView;
// Customizable Area End
