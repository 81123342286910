import React, { useState } from "react";
import { Box, Button, Dialog, Divider, IconButton, Typography, styled } from "@material-ui/core";
import { moveIcon, trashBinIcon } from "../assets";
import { Close } from "@material-ui/icons";


const Overlay = styled(Box)(({ theme }) => ({
    background: "#1D1D1F",
    borderRadius: "10px",
    backgroundColor:"rgba(37, 37, 39, 1)",
    padding: "4px 24px",
    position: "fixed",
    width:509,
    display:"flex",
    justifyContent:"center",
    bottom:126,
    right:33,
    gap:28,
    alignItems:"center",
    zIndex:999

   
  }))

  const StyledFolderModal = styled(Box)(({ theme }) => ({
    background: "#1D1D1F",
    borderRadius: "10px",
    boxShadow: "-5px 4px 12px 0px #00000040 inset",
    padding: "40px",
    position: "relative",
  
    "& .MuiPaper-root": {
      maxWidth: "800px",
      // width: "700px",
    },
  
    "& .MuiIconButton-root": {
      height: "24px",
      width: "24px",
      padding: "4px",
      position: "absolute",
      top: "16px",
      right: "16px",
    },
  
    "& .MuiTypography-h6": {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "28px",
      textAlign: "center",
      color: "#CFD1D4",
      marginBottom: 32,
    },
  
    "& .MuiTypography-body1": {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "26px",
      textAlign: "center",
      color: "#676767",
    },

    "& .MuiTypography-body2": {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "26px",
      textAlign: "center",
      color: "#CFD1D4",
    },
  
  }))
  

  type SelectedOverlayProps={
    selectedItems:{
      id: number,
      gameType: string,
      videos_size_gb: string,
    }[],
    onDelete:()=> void,
    onMove:()=> void,
  }
  
export default function SelectedFilesOverlay(props:SelectedOverlayProps) {

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  
  const totalSize = props.selectedItems.reduce((sum, item) => {
    return sum + parseFloat(item.videos_size_gb);
  }, 0);
  
  const totalSizeRounded = Math.round(totalSize * 100) / 100;

  const openDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleDelete = () => {
    props.onDelete(); // Trigger delete action
    closeDeleteModal(); // Close modal after delete
  };

  return (
    <>
    <Overlay>
        <Box>
          <p style={{
          fontFamily: "Poppins",
          fontSize: "20px",
          fontWeight: 400,
          lineHeight: "28px",
          textAlign: "left",
          color:"rgba(207, 209, 212, 1)"
          }}>{props.selectedItems.length} Videos</p>
          <p style={{
          fontFamily: "Poppins",
          fontSize: "24px",
          fontWeight: 400,
          lineHeight: "32px",
          textAlign: "left",
          letterSpacing: "-0.005em",
          color:"rgba(207, 209, 212, 1)"}}>Total: <span style={{color:"rgba(255, 136, 26, 1)"}}>{totalSizeRounded} GB</span></p>
        </Box>
        <Box>
        <Button variant="outlined" onClick={openDeleteModal} style={{ color: '#FF1919', borderColor: '#FF1919', borderRadius: '8px', width: '130px', height: '44px', padding: '10px 16px', gap: '10px' }} endIcon={<img src={trashBinIcon} 
        alt="Your Image" style={{ width: '24px', height: '24px' }} />}>Delete</Button>
        </Box>
        <Box>
        <Button variant="outlined" onClick={props.onMove} style={{ color: 'rgba(26, 172, 255, 1)', borderColor: 'rgba(26, 172, 255, 1)', borderRadius: '8px', width: '130px', height: '44px', padding: '10px 16px', gap: '10px' }} endIcon={<img src={moveIcon} 
        alt="Your Image" style={{ width: '24px', height: '24px' }} />}>Move</Button>
        </Box>
    </Overlay>

    <Dialog
          open={deleteModalOpen}
          PaperProps={{
            style: {
              border: "unset",
              margin: 'auto',
              background: "transparent",
              maxWidth: "unset"
            },
          }}
          BackdropProps={{
            style: {
              backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
            }
          }}
        >
          <StyledFolderModal style={{width: '780px'}}>
            <IconButton
              data-test-id="on-close-delete-modal"
              style={{ cursor: "pointer" }}
              onClick={closeDeleteModal}
              aria-label="close">
              <Close style={{ color: "#676767" }} />
            </IconButton>

            <Typography variant="h6">Delete Folder/Video!</Typography>
            
            <Typography variant="body1" style={{marginTop: '24px'}}>
            Deleting will delete all of the following options from selected video/s: 
            </Typography>

            <Typography variant="body2" style={{marginTop: '24px'}}>
            Mark, Clips, Report, Highlights
            </Typography>

            <Typography variant="body1" style={{marginTop: '24px'}}>
            The following action will remove <span style={{font:"Poppins",fontSize:18,fontWeight:500,color:"#FF881A",}}>{totalSizeRounded} GB</span> from your cloud storage.
            </Typography>

            <Divider style={{ width: '496px', margin: "24px auto", backgroundColor: '#FF881A',height:2 }} />
            
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button
                data-test-id="close-delete-modal"
                variant="outlined"
                style={{
                  borderRadius: 8,
                  color: "#FF881A",
                  borderColor: "#FF881A",
                  fontFamily: "Montserrat",
                  fontSize: 18,
                  width: "200px",
                  padding: "10px 16px 10px 16px",
                  gap: 8,
                  fontWeight: 600,
                  textTransform: 'none',
                  lineHeight: "22px",
                  cursor: "pointer",
                }}
                onClick={handleDelete}
              >
                Delete without
                Reports
              </Button>
              <Button
                data-testid="delete-account"
                variant="contained"
                style={{
                  backgroundColor: "#FF1919",
                  color: "#1D1D1F",
                  borderRadius: 8,
                  fontSize: 18,
                  padding: "10px",
                  gap: 8,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  lineHeight: "22px",
                  width: "200px",
                  marginLeft: '24px',
                  cursor: "pointer",
                  textTransform: 'none'
                }}
                onClick={handleDelete}
              >
                Delete with
                Reports
              </Button>
            </div>
          </StyledFolderModal>
        </Dialog>
    </>
  )
}
