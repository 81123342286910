import { Box, Button, IconButton, Modal, Typography, styled } from "@material-ui/core";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import React from "react";
const closeIcon = require('../assets/close_icon.svg')

interface Props{
    statusMarking: {
        status: string;
        missing_value?: number | undefined;
        type_missing_value?: string | undefined;
    };
    isOpen: boolean;
    handleCloseModal: () => void;
}

interface S {
}

interface SS {
    
}

export default class ModalStatusMarking extends BlockComponent<Props, S, SS> {
  constructor(props: Props) {
    super(props);
    this.state = {
    }
  }

  render() {

    return (
        <Modal
            open={this.props.isOpen}
        >
            <ModalStatusMarkingStyled>
                <Box className="modalContent">
                    <IconButton className="closeIcon" onClick={() => this.props.handleCloseModal()}>
                        <img alt="i" src={closeIcon}/>
                    </IconButton>
                    <Box className="content">
                        <Typography className="heading_content">
                            {
                                this.props.statusMarking?.status === "SUCCESS" ? "Success!" : "Warning!" 
                            }
                        </Typography>
                        <Typography className="description">
                            { this.props.statusMarking?.status === "SUCCESS" && `You've finished marking your game!`}
                            {this.props.statusMarking?.status === "ERROR" && (
                                <Typography style={{ textAlign: "center" }}>
                                    In order for you to finish marking, you need to find at least <span style={{ color: "#FF881A" }}>{this.props.statusMarking.missing_value}</span> more <span style={{ color: "#FF881A" }}>
                                        {this.props.statusMarking?.type_missing_value === "Missed-OP" && `Missed-Opportunity's`}</span>.
                                </Typography>
                            )}
                        </Typography>
                        <hr style={{width:"70%", color: "#FF881A", backgroundColor: "#FF881A", borderColor: "#FF881A", margin: 24}} />
                        <Box className="action_buttons">
                            {this.props.statusMarking?.status === "SUCCESS" && (
                                <React.Fragment>
                                    <Button className="btn btn-done" onClick={() => this.props.handleCloseModal()}>
                                        Go To Clips
                                    </Button>
                                    <Button className="btn btn-report">
                                        Go to Report
                                    </Button>
                                </React.Fragment>
                            )}
                            {this.props.statusMarking?.status === "ERROR" && (
                                <Button className="btn btn-back" onClick={() => this.props.handleCloseModal()}>
                                    Back
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </ModalStatusMarkingStyled>
        </Modal>
    );

  }
}


const ModalStatusMarkingStyled = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    outline: "none",
    backgroundColor: "#1D1D1F",
    boxShadow: "-5px 4px 12px 0px #00000040 inset",
    "& .modalContent": {
        position: "relative",
        "& .closeIcon": {
            position: "absolute",
            right: "10px"
        },
        "& .content": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: 40,
            paddingBottom: 30,
            "& .heading_content": {
                fontFamily: "Poppins",
                fontSize: "18x",
                fontWeight: 700,
                color: "#CFD1D4"
            },
            "& .description": {
                marginTop: 32,
                fontFamily: "Poppins",
                fontSize: "16px",
                color: "#676767"
            },
            "& .action_buttons": {
                display: "flex",
                gap: 24,
                justifyContent: "center",
                alignItems: "center",
                "& .btn": {
                    textTransform: "none",
                    width: "160px",
                    borderRadius: "8px",
                    padding: "10px 16px",
                    background: "red",
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: 16,
                    border: "1px solid"
                },
                "& .btn.btn-done": {
                    borderColor: "#FF881A",
                    background: "transparent",
                    color: "#FF881A"
                },
                "& .btn.btn-report": {
                    background: "#8DE301",
                    color: "#1D1D1F"
                },
                "& .btn.btn-back": {
                    background: "#FF1919",
                    color: "#1D1D1F"
                }
            }
        }
    }
}))

