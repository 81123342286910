// Customizable Area Start
import React, { useState, useRef } from "react";
import { useMeeting } from "@videosdk.live/react-sdk";
import ExternalVideoView from "./ExternalVideoView.web";

const ExternalVideo = () => {
  const [{ link }, setVideoInfo] = useState({
    link: null,
    playing: false,
  });

  const onVideoStateChanged = (data) => {
    const { currentTime, link, status } = data;

    switch (status) {
      case "stopped":
        externalPlayer.current.src = null;
        setVideoInfo({ link: null, playing: false });
        break;
      case "resumed":
        if (typeof currentTime === "number") {
          externalPlayer.current.currentTime = currentTime;
        }
        externalPlayer.current.play();
        setVideoInfo((s) => ({ ...s, playing: true }));
        break;
      case "paused":
        externalPlayer.current.pause();
        setVideoInfo((s) => ({ ...s, playing: false }));
        break;
      case "started":
        setVideoInfo({ link, playing: true });
        break;
      default:
        break;
    }
  };

  const onVideoSeeked = (data) => {
    const { currentTime } = data;
    if (typeof currentTime === "number") {
      externalPlayer.current.currentTime = currentTime;
    }
  };

  const externalPlayer = useRef();

  useMeeting({ onVideoStateChanged, onVideoSeeked });

  const viewProps = {
    link,
    externalPlayer,
    onVideoStateChanged,
    onVideoSeeked
  };

  return <ExternalVideoView testID="ExternalVideoView" {...viewProps} />;
};

export default ExternalVideo;
// Customizable Area End
