import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Avatar, Typography } from "@material-ui/core";
import React from "react";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

interface Row {
  id: number;
  group: string;
  competition: string;
}
import { Message } from "../../../framework/src/Message";
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { GetAllMemeberResponse, PlayerInfo } from "../../../../packages/blocks/contentmanagement/src/ContentManagementController";
import { toast } from "react-toastify";
import { defaultAvatar } from "./assets";

export interface Position {
  name: string;
  selected: boolean;
}

export interface PlayerFilter {
  label: string,
  id: number,
  type: string,
  selected: boolean;
}

export type Player = PlayerInfo & { selected: boolean }

export interface Group {
  id: string;
  type: string;
  attributes: {
    name: string;
    assigned: boolean;
    competition: null;
    settings: null;
    keyboard?: {
      id: number;
      name: string;
    }
    accounts: Array<Account>;
    position: null;
    players: null;
    game_type: null;
  }
}
interface KeyboardInfo{
  id: number,
  account_id: number,
  assignable_type: string,
  assignable_id: number,
  name: string,
}

export interface Account {
  id: number,
  first_name: string,
  last_name: string,
  full_phone_number: string | null;
  country_code: null,
  phone_number: null,
  email: string,
  activated: boolean,
  device_id: null,
  unique_auth_id: string | null,
  password_digest:string,
  created_at: string,
  updated_at: string,
  user_name: string,
  platform: string|null,
  user_type: null,
  app_language_id: null,
  last_visit_at: null,
  is_blacklisted: boolean,
  suspend_until: null,
  status: string,
  role_id: number|null,
  full_name: null,
  gender: string,
  date_of_birth: string|null,
  age: null,
  in_game_role: null,
  country: string,
  state: string,
  title?: string | null,
  account_type: string|null,
  new_folder_id: null,
  role_name:string|null,
  stripe_customer_id: string|null,
  position: string | null,
  staff: string|null,
  keyboards?:KeyboardInfo[];
}

export interface ProductItem {
  id: string|number;
  type: string;
  attributes: {
    name: string;
    assigned: boolean;
    competition: null | string;
    settings: null;
    accounts?: Array<Account>; 
    position: null;
    players: null | Array<string>;
    game_type: null;
    keyboard?: {
      id: number;
      name: string;
    }
  }
  expanded?: boolean;
}
// Customizable Area End



export const configJSON = require("./config");

export interface Props {
  // navigation: any;
  // id: string;
  navigation?: any;
  idt?: any;
  // Customizable Area Start
  showAssignModal?: boolean;
  closeModal?: any;
  handleModal?: any;
  options?: any;
  onGroupUpdate?: (groups: Array<ProductItem & {expanded: boolean}>) => void;
  onNewsPaperGroupUpdate?: (group: Group) => void;
  groups?: Array<ProductItem & {expanded: boolean}> ;
  selectedGroupId?: number
  onNewspaperGroupDelete?: (groupId: number) => void;
  folderId?:any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  showNewPaper: boolean;
  isFirstRender: boolean,
  id: number;
  loading: boolean;
  isSaveClicked: boolean,
  idGroup: number,
  rows: Row[],
  selectedPlayers: { [name: string]: boolean },
  selectedPoss: [],
  token: string;
  group: string;
  competition: string;
  positions: Array<PlayerFilter>;
  staffFilters:Array<PlayerFilter>
  inGameRole:Array<PlayerFilter>
  allPlayers: Array<Player>;
  initialAllPlayers: Array<Player>;
  deletingGroupId: number;
  selectedFilter:Array<PlayerFilter>;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ShareController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  createGroupId: string = "";
  editGroupData: string = "";
  playersPositions: string = "";
  positionandplayers: string = ""
  apiGroupCallId: string = ''
  lastClickTime: number = 0;
  getFilterList: string = '';
  getAllPlayerCallId: string = '';
  addPlayerToGroupCallId: string = '';
  deleteGroupCallId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      showNewPaper: false,
      isFirstRender: true,
      isSaveClicked: false,
      idGroup: 0,
      loading: false,
      token: "",
      id: 0,
      selectedPlayers: {},
      selectedPoss: [],
      rows: [],
      group: "",
      competition: "",
      staffFilters:[],
      inGameRole:[],
      positions: [],
      allPlayers: [],
      initialAllPlayers: [],
      deletingGroupId: 0,
      selectedFilter:[]
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;
    this.handleGroupsData(message);
  }
  async componentDidMount() {
    const token = await getStorageData("authToken") || ""
    this.setState({ token })
    this.getFilterPlayerList(token)
    this.getAllPlayer()
  }


  handleResponse = (responseJson: any, errorResponse: any, successCallback: { (responseJson: any): void; (arg0: any): void; }) => {
    if (responseJson) {
      successCallback(responseJson);
    } else {
      this.setState({ loading: false });
      this.parseApiErrorResponse(errorResponse);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  updatePositionsandPlayers = async () => {
    const {folderId} = this.props;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    }
    const data = {
      account_ids: this.state.allPlayers.filter(item => item.selected).map(player => player.id),
      folder_id:folderId,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addPlayerToGroupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_account_groups/groups/${this.props.idt}/add_accounts`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  updateRowData = async (id: number, competititons: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    }
    const data = {
      group: {
        competition: competititons,
      }
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.editGroupData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.editGroup}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  addRowData = async (row: string) => {
    await this.addRowDatas(row);
  };

  addRowDatas = async (row: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    }
    const data = {
      group: {
        name: row
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createGroupId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addNewGroup
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  deleteGroup = async (accountId: number) => {
    this.setState({ deletingGroupId: accountId })
    this.deleteGroups(accountId);
  };

  deleteGroups = async (accountId: number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteGroupCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteGroup}${accountId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleCreateGroupApi = (message: Message) => {
    this.handleResponse(
      message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
      message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
      (responseJson: { data: ProductItem }) => {
        if (responseJson.data && this.props.onGroupUpdate) {
          this.props.onGroupUpdate([...this.props.groups!.map(item => ({ ...item, expanded: false })), { ...responseJson.data, expanded: false }])
        }
      }
    )
  }

  handleEditGroupApi = (message: Message) => {
    this.handleResponse(
      message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
      message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
      (responseJson: { data: ProductItem }) => {
        if (responseJson.data && this.props.onGroupUpdate) {
          this.props.onGroupUpdate(this.props.groups!.map(item => {
            if (item.id === responseJson.data.id) {
              return { ...responseJson.data, attributes: { ...responseJson.data.attributes, assigned: item.attributes.assigned }, expanded: false }
            }

            return { ...item, expanded: false }
          }))
        }
      }
    )
  }
  
  mappingFilter = (data: any) => {
    const esports = data?.esports || [];
    const sports = data?.sports || [];
    let staff;
    let inGameRole;
    let positions;
  
    if (esports.length > 0) {
      staff = esports
        .filter((item: PlayerFilter) => item.type === 'Staff')
        .map(({ label, id, type }: PlayerFilter) => ({ label, id, type, selected: false }));
  
      inGameRole = esports
        .filter((filter: PlayerFilter) => filter.type === 'In_Game_Role')
        .map(({ label, id, type }: PlayerFilter) => ({ label, id, type, selected: false }));
    } else if (sports.length > 0) {
      staff = sports
        .filter((item: PlayerFilter) => item.type === 'Staff')
        .map(({ label, id, type }: PlayerFilter) => ({ label, id, type, selected: false }));
  
      positions = sports
        .filter((filter: PlayerFilter) => filter.type === 'Position')
        .map(({ label, id, type }: PlayerFilter) => ({ label, id, type, selected: false }));
    }
    return { staff, inGameRole, positions };
  }

  handleGetFiterPlayer = (message: Message) => {
    
     
    this.handleResponse(
      message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
      message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
      (responseJson: Array<{ Staff: Array<string>, Positions: Array<string> }>) => {
          const result = this.mappingFilter(responseJson)
          this.setState({staffFilters:result.staff,inGameRole:result.inGameRole,positions:result.positions})
    
      }
    )
  }

  formatPlayer = (player: PlayerInfo) => {
    let selected = false;
    const selectedGroup = this.props.groups!.find(group => group.id === String(this.props.idt));

    if (selectedGroup && selectedGroup.attributes.accounts?.length) {
      selected = Boolean(selectedGroup.attributes.accounts.map(account => account.id).includes(Number(player.id)))
    }
    return {...player, selected, preSelected: selected}
  }

  handleGetAllPlayerApi = (message: Message) => {
    this.handleResponse(
      message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
      message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
      (responseJson: GetAllMemeberResponse) => {
        if (responseJson.data) {
          const players = responseJson.data.map(this.formatPlayer)
          this.setState({ allPlayers: players, initialAllPlayers: players.map(item => ({...item,selected:false}))})
        }else{
          this.setState({allPlayers:[]})
        }
      }
    )
  }

  handleAddPlayerToGroupApi = (message: Message) => {
    this.handleResponse(
      message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
      message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
      (responseJson: { data: Group }) => {
        if (responseJson.data) {
          this.setState(prev => ({
            positions: prev.positions?.map(item => ({ ...item, selected: false })),
            allPlayers: prev.initialAllPlayers
          }))
          toast.success('Add user successfully!')
          if (this.props.onNewsPaperGroupUpdate) {
            this.props.onNewsPaperGroupUpdate(responseJson.data)
          }
          this.props.closeModal(false);
        }
      }
    )
  }

  handleDeleteGroupApi = (message: Message) => {
    this.handleResponse(
      message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)),
      message.getData(getName(MessageEnum.RestAPIResponceErrorMessage)),
      (responseJson: { message: string }) => {
        if (responseJson.message && responseJson.message === 'Group deleted successfully!' && this.props.onGroupUpdate) {
          this.props.onGroupUpdate(this.props.groups!.filter(item => {
            return item.id !== String(this.state.deletingGroupId)
          }).map(item => ({ ...item, expanded: false })))
          toast.success('Group deleted successfully!')
        }
      }
    )
  }

  handleGroupsData = (message: Message) => {
    const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    const apiHandler = {
      [this.createGroupId]: this.handleCreateGroupApi,
      [this.editGroupData]: this.handleEditGroupApi,
      [this.getFilterList]: this.handleGetFiterPlayer,
      [this.getAllPlayerCallId]: this.handleGetAllPlayerApi,
      [this.addPlayerToGroupCallId]: this.handleAddPlayerToGroupApi,
      [this.deleteGroupCallId]: this.handleDeleteGroupApi
    }

    if (apiHandler[dataMessage]) {
      apiHandler[dataMessage](message)
    }
  };

  onNewsPaperGroupUpdate = (group: Group) => {
    if (this.props.onGroupUpdate) {
      this.setState({ showNewPaper: false })
      this.props.onGroupUpdate(this.props.groups!.map(item => {
        if (item.id === group.id) {
          return { ...group, expanded: true, attributes: { ...group.attributes, assigned: true } }
        }

        return { ...item, expanded: false }
      }))
    }
  }

  handleClick = (id: number) => {
    this.setState({ idGroup: id, showNewPaper: true })
  };

  handleSaveButtonClick = () => {
    this.setState({ isSaveClicked: true });
    this.updatePositionsandPlayers()
  };
  handleSaveButtonBlur = () => {
    this.setState({ isSaveClicked: false });
  };
  stringAvatar(name: string) {
    if(name?.length>0){
      const words = name.split(' ');
    let children;
    if (words.length > 1) {
      children = `${words[0][0]}${words[1][0]}`;
    } else {
      children = `${words[0][0]}`;
    }
    return { children }
    }
  }
 
  renderPosition = (positions: Array<PlayerFilter>) => {
    return positions?.map((position, index) => {
      const textColor = position.selected ? "rgb(255, 136, 26)" : "white";

      return (
        <div
          data-test-id="position"
          className="item-container"
          
          key={index}
          onClick={() => {
            this.onChangeFilter(position)
          }}
          style={{ cursor: "pointer",width:"48%" }}
        >
          <Avatar className="avatar" {...this.stringAvatar(position.label)} />
          <div
            className="info-container"
            style={{
              border: `1px solid ${position.selected || textColor === 'rgb(255, 136, 26)' ? "rgb(255, 136, 26)" : "transparent"}`,
              borderRadius: "5px",
              padding: "0.5rem",
              marginLeft: "0.5rem"
            }}
          >
            <Typography component="span" className="info" style={{ color: textColor }}>
              {position.label}
            </Typography>
          </div>
        </div>
      );
    });
  }

  


  handleSelectPlayer = (isDisabled: boolean, id: string) => {
   
    if (!isDisabled) {
      this.setState(prev => ({
        ...prev,
        allPlayers: prev.allPlayers.map(item => {
          if (item.id === id) {
            return {
              ...item,
              selected: !item.selected
            }
          }

          return item
        })
      }))
    }
  }

  renderPlayerInfo = (players: Array<PlayerInfo & { selected: boolean }>) => {
    const selectedGroup = this.props.groups!.find(item => item.id === this.props.idt);

    return players.map((player) => {
      let assignedToText = null;
      let isAssignedToThisGr = false;
      let isAssignedToAnotherGr = false;
      let assignedGroupIndex = -1;
      const formatName = player.attributes.first_name + " " + player.attributes.last_name
      const profileImg = player.attributes.photo
      
      if (player.preSelected && player.selected) {
        const grIndex = this.props.groups!.map(item => item.id).indexOf(selectedGroup!.id) + 1

        isAssignedToThisGr = true;
        assignedToText = <Typography component="span" className="prev-group-assigned">Assigned to G{grIndex}</Typography>;
      } else {
        this.props.groups!.some((item, gIndex) => {
          if (item.id === selectedGroup!.id) {
            return false;
          }
          
          const {accounts} = item.attributes;
          
          if (accounts?.length) {
            const isAssigned = accounts.map(acc => acc.id).includes(Number(player.id));

            isAssignedToAnotherGr = isAssigned

            if (isAssigned) {
              assignedGroupIndex = gIndex;
              
              assignedToText = <Typography component="span" className="prev-group-assigned">Assigned to G{assignedGroupIndex + 1}</Typography>;
              return true;
            }
          }

          return false;
        })
      }
      
      return (
        <div
          data-test-id="player"
          className="item-container"
          key={String(player.id)}
          onClick={() => this.handleSelectPlayer(isAssignedToAnotherGr, String(player.id))}
          style={{ width:"48%" }}
        >
          {profileImg ?
            <Avatar className="avatar" src={profileImg}/> :<Avatar className="avatar" src={defaultAvatar}/>
          }
         
          <div
            className="info-container"
            style={{
              border: `1px solid ${player.selected || isAssignedToThisGr || isAssignedToAnotherGr ? "rgb(255, 136, 26)" : "transparent"}`,
              borderRadius: "5px",
              padding: "0.5rem",
              marginLeft: "0.5rem"
            }}
          >
            <Typography component="span" className="info" style={{ color: player.selected || isAssignedToAnotherGr ? "#FF881A" : "white" }}>
              {formatName}
            </Typography>

            {assignedToText}
          </div>
        </div>
      );
    })
  }
  handleChange = async (id: number, value: string) => {
    this.updateRowData(id, value)
  }

  handleClose = () => {
    this.setState({ showNewPaper: false });
  };

  getFilterPlayerList = (token: string) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFilterList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFilterPlayerList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  generateURL(data: PlayerFilter[]) {
    const urlParams = data.reduce((params: string[], obj) => {
      if (obj.type === 'Staff') {
        params.push(`staff=${obj.label}`);
      } else if (obj.type === 'In_Game_Role') {
        params.push(`in_game_role=${obj.label}`);
      } else if (obj.type === 'Position') {
        params.push(`sport_position=${obj.label}`);
      }
      return params;
    }, []);
  
    const url = urlParams.length > 0 ? '?' + urlParams.join('&') : '';
  
    return url;
  }

  onChangeFilter = (obj: PlayerFilter) => {
    this.setState(prevState => {
      let newStates = { ...prevState };

      switch (obj.type) {
        case 'Staff':
          newStates.staffFilters = prevState.staffFilters.map(filter =>
            filter.label === obj.label ? { ...filter, selected: !filter.selected } : filter
          );
          break;
        case 'In_Game_Role':
          newStates.inGameRole = prevState.inGameRole.map(item =>
            item.label === obj.label ? { ...item, selected: !item.selected } : item
          );
          break;
        case 'Position':
          newStates.positions = prevState.positions.map(filter =>
            filter.label === obj.label ? { ...filter, selected: !filter.selected } : filter
          );
          break;
        default:
          break;
      }

      const existedIndex = prevState.selectedFilter.findIndex(filter => filter.label === obj.label);
      if (existedIndex !== -1) {
        newStates.selectedFilter = prevState.selectedFilter.filter((_, index) => index !== existedIndex);
      } else {
        newStates.selectedFilter = [...prevState.selectedFilter, obj];
      }

      return newStates;
    }, () => {
      this.getAllPlayer(this.state.selectedFilter);
    });
  };

  getAllPlayer = (filter?:any) => {
    const {token} = this.state;
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    }
    const query = filter ? this.generateURL(filter) :"";

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllPlayerCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllPlayerURL + query
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}