import { Box, Typography, styled } from "@material-ui/core";
const drop_down = require("./drop_down.svg");
import React, { Component } from "react";
import Select, { components  } from "react-select";


type Option = { value: string; label: string };

type MyProps = {
    label?: string,
    option?: Option[],
    placeholder?: string,
    name: string,
    handleChangeFunction: any,
    value: string,
    validationMsg?: string,
    isDisabled?: boolean
    hideIndicator?: boolean
    className?: string
    showLabelAsValue?: boolean;
    hideCustomLabel?: boolean;
};
const DropdownIndicator = (
    props: any
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={drop_down} width={32}/>
      </components.DropdownIndicator>
    );
  };
export default class DropDown extends Component<MyProps> {

    constructor(props: any) {
        super(props);
        this.state = {
        };
    }

    render(): React.ReactNode {

        return (
            <TextFieldStyle className={`${this.props.className || ""}`}>
                <Typography style={{ color: "#676767", fontWeight: 400, fontSize: '1.3rem', display: this.props.hideCustomLabel ? "none" : "block" }}>{this.props.label}</Typography>
                <Select
                components={{ DropdownIndicator: this.props.hideIndicator ? null : DropdownIndicator, 
                    IndicatorSeparator: () => null 
                }}
                    className="select-container"
                    placeholder={this.props.placeholder}
                    options={this.props.option}
                    name={this.props.name}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#FF881A',
                            primary: '#FF881A',
                        },
                    })}
                    
                    onChange={this.props.handleChangeFunction}
                    value={{ value: this.props.value, label: this.props.showLabelAsValue && this.props.label ? this.props.label : this.props.value }}
                    isDisabled={this.props.isDisabled}
                />

                <Box style={{visibility: this.props.validationMsg ? "visible" : "hidden",
                    display: this.props?.validationMsg?.length ? "block" : "none"
                    }}>
                    {<span className="validationMsg">{this.props.validationMsg ? this.props.validationMsg : "error"}</span>}
                </Box>
            </TextFieldStyle>
        )
    }

}


const TextFieldStyle = styled(Box)({
    '& .MuiFilledInput-underline': {
        borderBottom: '1px solid #FF881A',
        boxShadow: '4px 4px 4px 0px #00000040 inset',
        borderRadius: '10px'
    },
    "& .inputField": {
        width: '21.667vw'
    },
    "& :focus-within .MuiFilledInput-underline": {
        outline: 'none'
    },
    '& .MuiFilledInput-underline::before': {
        borderBottom: 'none'
    },
    '& .MuiFilledInput-underline::after': {
        borderBottom: 'none'
    },
    '& .inputFieldContainer': {
        display: 'flex',
        flexDirection: 'column'
    },
    '& .validationMsg': {
        fontFamily: 'Poppins',
        color: '#FF1919',
        float: 'left',
        fontWeight: 400,
        fonstSize: '14px',
        margin: '6px 0'
    },
    '& .select-container': {
        borderBottom: '1px solid #FF881A',
        boxShadow: '4px 4px 4px 0px #00000040 inset',
        borderRadius: '10px',
        width: '100%',
    },
    '& .select-container .css-yk16xz-control ': {
        backgroundColor: '#1D1D1F',
        boxShadow: '4px 4px 4px 0px #00000040 inset',
        borderRadius: '10px',
        width: '100%',
        border: 'none',
        color: '#CFD1D4',
        height: '56px'
    },
    '& .select-container .css-yk16xz-control:focus ': {
        backgroundColor: '#1D1D1F',
        borderBottom: '1px solid #FF881A',
        boxShadow: '4px 4px 4px 0px #00000040 inset',
        borderRadius: '10px',
        width: '21.667vw',
        border: 'none',
        color: '#CFD1D4',
        height: '56px'
    },
    "& .select-container .css-tlfecz-indicatorContainer": {
        padding: "12px"
    },
    "& .css-1l87ks9-control": {
        backgroundColor: "#1D1D1F",
        border: 'none',
        height: '54px',
        borderRadius: "10px",
      },
    '& .select-container .css-1pahdxg-control:hover': {
        border: 'none'
    },
    '& .select-container .css-1pahdxg-control': {
        backgroundColor: '#1D1D1F',
        color: '#CFD1D4',
        height: '56px'
    },
    '& .select-container .css-26l3qy-menu': {
        color: '#CFD1D4',
        backgroundColor: '#1D1D1F',
    },
    '& .css-1uccc91-singleValue': {
        fontFamily: "Poppins",
        fontWeight: 400,
        fontSize: "16px",
        color: "#CFD1D4"
    },
    '& .css-107lb6w-singleValue':{
        fontFamily: "Poppins",
    },
    '& .css-1fhf3k1-control': {
        border: 'none',
        borderRadius: '10px',
        backgroundColor: '#1D1D1F',
        height: '54px',
    }
})