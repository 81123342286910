import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { Linking } from "react-native";
import { UserProfileData } from "../../../../packages/blocks/settings2/src/UserProfileController";
import { getStorageData, removeStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";

export interface Profile {
  id: any;
  country: any;
  address: any;
  city: any;
  postal_code: any;
  account_id: any;
  gender: any;
  team_name: any;
  email: any;
  photo: any;
  full_name: any;
  role: any;
  date_of_birth: any;
  team_profile: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  currentTabProp?: string;
  onGetUserProfileResponse?: (data: UserProfileData) => void;
  userData?: {userName:string,teamName:string,avatar:string};
  profileUpdate?: boolean|  null;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  webDrawer: boolean;
  token: any;
  drawerItems: any;
  anchorEl: any,
  anchorEl2: any,
  currentTab:string
  profile: Profile | null
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  apiGetProfileCallId: string = "";
  apiLogOutCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      webDrawer: false,
      token: "",
      drawerItems: [],
      anchorEl: null,
      anchorEl2:null,
      currentTab: this.props.currentTabProp || "dashboard",
      profile: null
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      runEngine.debugLog("TOKEN", token);
      this.setState({ token: token }, () => {
        this.getMenuItems();
      });
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetDataCallId !== "" &&
      this.apiGetDataCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ drawerItems: responseJson });
      } else {
        this.parseApiErrorResponse(responseJson);
      }
    } else if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiGetProfileCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        sessionStorage.setItem("profile",JSON.stringify(responseJson?.data?.attributes))
        this.setState({ profile: responseJson?.data?.attributes });

        if (responseJson?.data?.attributes.permissions) {
          setStorageData("permissions", responseJson.data.attributes.permissions)
        }
        
        if (this.props.onGetUserProfileResponse) {
          this.props.onGetUserProfileResponse(responseJson)
        }
      } else {
        this.parseApiErrorResponse(responseJson);
        setStorageData("permissions", '')
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleClick = (event:any,value:string) => {
    this.setState({ anchorEl: event?.currentTarget });
    this.setState({currentTab:value})
  };
  handleClick2 = (event:any,value:string) => {
    this.setState({ anchorEl2: event?.currentTarget });
    this.setState({currentTab:value})
  };
  handleCurrentTab = (name:any)=>{
  this.setState({currentTab:name})

  }
  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleClose2 = () => {
    this.setState({ anchorEl2: null });
  };
 
  goToHelpCentre = () => {
    this.props.navigation.navigate("HelpCentre");
  };

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    const userData = sessionStorage.getItem("profile")    
    if (userData) {
        this.setState({ profile: JSON.parse(userData) })
    }
    else{      
      this.getProfile();
    }
    if(!window.localStorage.getItem('authToken'))
      return this.props.navigation.navigate('LoginBlock')
    this.getToken();
    if(this.state.currentTab === "dashboard"){
      this.props.navigation.navigate('Dashboard')
    }
   
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevProps.profileUpdate!==this.props.profileUpdate){
      this.getProfile();
    }
  }

  async handleLogout(){
    this.handleClose2();
    await this.handleLogOutApi();
    window.localStorage.removeItem('authToken')
    sessionStorage.clear()
    this.props.navigation.navigate("LoginBlock")
    removeStorageData("permissions")
  }
  
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getMenuItems = async () => {
    let token = this.state.token;

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getMenuItemsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getProfile = async () => {
    const token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
      'ngrok-skip-browser-warning': 'true' 
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  goToSetting() {
    this.handleCurrentTab("settings");
    this.props.navigation.navigate("Settings2")
  }
  navigateToTeam(){
    this.handleCurrentTab('team')
    const profile = JSON.parse (sessionStorage.getItem('profile') || "")
    const route = profile.role === 'end user' ? "AccountGroups": "AccountTeamGroup"
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
      msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
      msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(msg);
  }

  handleLogOutApi = async () => {
    let token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiLogOutCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.logoutEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  goToNotifications=()=> {
    this.props.navigation.navigate("Notifications")
  }

  // Customizable Area End
}
