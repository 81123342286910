import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { bellIcon, defaultIcon, deleteIcon, more, search } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import MenuButton from "../../../components/src/MenuButton";
import MuteNotificationDialog from "../../../components/src/Notification/MuteNotificationDialog.web"
import { Pagination } from "@material-ui/lab";

// Customizable Area End

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationsController";
import { KeyboardArrowLeft } from "@material-ui/icons";

export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  notifications = [
    {
      text: "Your account information has been successfully updated.",
      time: "2 seconds ago",
      isRead:true,
    },
    {
      text: "Jen(Player) has uploaded a new video for Cricket match. ",
      link: "Click here to view the video",
      time: "30 minutes ago",
    },
    {
      text: "New report for Harry Watson is now available for Soccer Game. ",
      link: "Click here to view the player's report",
      time: "12 July 2024 at 11:30 am",
    },
    {
      text: "You have received a new direct message from Jack Adison.",
      time: "12 July 2024 at 11:30 am",
    },
    {
      text: "Your account has been locked due to multiple failed login attempts. Please reset your password or contact support for assistance.",
      time: "12 July 2024 at 11:30 am",
      isRead:true,
    },
    {
      text: "A new scouting report for Football match has been created. ",
      link: "Click here to view the report",
      time: "12 July 2024 at 11:30 am",
    },
  ];
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <StyledContainer>
        <NavigationMenu
          navigation={this.props.navigation}
          id="NavigationMenu"
          currentTabProp="subscriptions"
          />
          <div style={{padding:"24px 48px"}}> 
          <MuteNotificationDialog
          data-testid="mute-dialog"
            open={this.state.muteNotificationModal}
            onClose={this.handleCloseMuteModal}
            selectedValue={this.state.muteNotificationValue}
            onRadioChange={this.handleRadioChange}
          />
           <Box className={"container"}>
            <Box className={"header"}>
              <Box style={{display:"flex",gap:16,alignItems:"center"}}>
            <Button className="procedure-back-btn" data-test-id="back-btn">
                <KeyboardArrowLeft className="arrow-back-icon" />
            </Button>
              <Typography variant="h6">Notifications</Typography>
              </Box>
              <Box className={"headerIcons"}>
              <Box className="inputbase">
                  <InputBase
                    className="gridItem2"
                    placeholder="Search.."
                    inputProps={{ 'aria-label': 'Search...' }}

                  />
                  <Box>
                    <IconButton type="button" style={{ padding: '10px' }} aria-label="search">
                      <img src={search} height={32} width={32} />
                    </IconButton>
                  </Box>
                </Box>

                <button className="actionBtn" data-testid="bell-btn" onClick={this.handleOpenMuteModal}>
                 <img src={bellIcon} height={32} width={32}/>
                </button>
                <button className="actionBtn">
                 <MenuButton icon={more} data-testid="more-options" options={[
                          {label: "Mute Notification", onClick: () => {this.handleOpenMuteModal()}},
                          {label: 'Mark all as read' , onClick: () =>{} },
                          {label: "Clear Notifications", onClick: () => {}},
                        ]}/>
                </button>
              </Box>
            </Box>
            <List>
              {this.notifications.map((notification, index) => (
                <ListItem key={index} className={`listItem`}>
                  <ListItemIcon >
                  <div className="iconWrapper">
                  <img src={ defaultIcon} alt="notification-icon" height={30} width={30} />

                  {!notification.isRead && <span className="unreadDot"></span>}
                  </div>
                  </ListItemIcon>
                  <ListItemText
                    className={"listText"}
                    primary={
                      <>
                      
                        {notification.text}
                        {notification.link && (
                         
                            <span className="link">
                              {notification.link}
                            </span>
                          
                        )}
                      </>
                    }
                    secondary={
                      <Typography variant="body2" className={"notificationTime"}>
                        {notification.time}
                      </Typography>
                    }
                  />
                  <IconButton className={"iconButton"}>
                    <img src={deleteIcon} alt="delete button" height={24} width={24}/>
                  </IconButton>
                </ListItem>
              ))}
            </List>
        </Box>
        </div>
        <StyledPagination count={100} page={1} onChange={()=>{}} />
        </StyledContainer>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});


const StyledContainer = styled(Box)(() => ({
  minHeight: "100vh",
  paddingBottom: "20px",
  boxSizing: "border-box",
  overflowX: "hidden",
  position:"relative",
  "& .container": {
    backgroundColor: '#252527',
    color: '#fff',
    padding: "24px 16px 16px 0px",
    borderRadius: '10px',   
    margin: 'auto',
  },
  "& .header": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  "& .headerIcons": {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  "& .procedure-back-btn": {
    width: 52,
    height: 48,
    borderStyle: "solid",
    borderWidth: 2,
    borderColor: "#FF881A",
    borderRadius:"0px 8px 8px 0px"
  },
  "& .arrow-back-icon": {
    color: "#FF881A",
    height:24,
    width:24
  },
  '& .inputbase': {
    width: "90%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 10,
    fontSize: 20,
    color: "#CFD1D4",
    backgroundColor: "#1D1D1F",
    textTransform: "none",
    [theme.breakpoints.down('lg')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  "& .listItem": {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #333',
    transition: "background-color 0.3s",
    padding:16,
  },
  "& .listText": {
    color: '#CFD1D4',
    display: 'flex',
    flexDirection: 'column',
    fontFamily:"Poppins",
    fontWeight:400,
    fontSize:24,
    lineHeight:"32px",
  },
  "& .link": {
    color: '#FF881A',
    cursor: 'pointer',
    fontSize: '14px',
    textDecoration:"underline",
  },
  "& .notificationTime": {
    color: '#666', 
    fontSize: '12px',
    marginTop: '4px',
  },
  "& .actionBtn": {
    border:"1px solid #FF881A",
    borderRadius:8,
    backgroundColor:"#252527",
    padding:8,
    cursor:"pointer",
    transition: "background-color 0.3s ease, transform 0.3s ease", 

    "&:hover": {
      backgroundColor: "rgba(255, 136, 26, 0.1)",  
      transform: "scale(1.05)", 
    }
  },
  "& .pagination": {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    '& .MuiPaginationItem-root': {
      color: '#FF881A',
    },
  },

  '& .gridItem2': {
    borderRadius: 10,
    fontSize: 18,
    padding: "10px 24px",
    color: "#CFD1D4",
    textTransform: "none",
    [theme.breakpoints.down('lg')]: {
      fontSize: 16,
      padding: 14,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      padding: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      padding: 10,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      padding: 8,
    },
  },
  "& .iconWrapper":{
    position:"relative",
  },
  "& .unreadDot": {
    position: "absolute",
    top: "-5px",  
    right: "-5px",
    width: "10px",  
    height: "10px",
    backgroundColor: "orange",  
    borderRadius: "50%",
    border: "2px solid #222", 
  },

}))

const StyledPagination = styled(Pagination)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  backgroundColor: 'transparent',
  fontSize: 20,
  '& .MuiPaginationItem-root': {
    fontSize: 20,
    color: "#CFD1D4",
  },
  '& .Mui-selected': {
    color: "#E87C18",
  },
  '& .MuiPaginationItem-page.Mui-selected:hover': {
    backgroundColor: 'transparent',
  }
}));
// Customizable Area End
