// Customizable Area Start
import React from "react";
import { Typography } from "@material-ui/core";

import Connection from "./Connection";

const configJSON = require("./config");

const ConnectionsView = ({ connections, meetingId }) => {
  const splitConnectionsIntoRows = numberPerRow => {
    const participantList = [...connections.keys()];

    const newArr = [];
    while (participantList.length) {
      newArr.push(participantList.splice(0, numberPerRow + 1));
    }
    return newArr;
  };

  return (
    <div style={webStyles.container}>
      <Typography id="titleText" variant="h2" style={webStyles.connectionTitle}>
        {configJSON.connectionsLabel}
      </Typography>
      {splitConnectionsIntoRows(2).map((row, index) => (
        <div style={webStyles.connectionItem} key={row}>
          {row.map(connection => (
            <Connection
              id={"test-" + index}
              key={`${meetingId}_${connection}`}
              connectionId={connection}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

const webStyles = {
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    padding: 8
  },
  connectionTitle: {
    color: "#3E84F6"
  },
  connectionItem: {
    display: "flex"
  }
};

export default ConnectionsView;
// Customizable Area End
