import { Box, IconButton, LinearProgress, Snackbar, SnackbarContent, Theme, styled } from '@material-ui/core';
import { getStorageData } from '../../../framework/src/Utilities';
import * as React from 'react';
import { IFile } from './OptimizationStatus.web';

const defaultUploadImg = require("../assets/default_upload.png");
const playIcon = require("../assets/button_play.svg");
const pauseIcon = require("../assets/button_pause.svg");
const errorIcon = require("../assets/button_error.svg");
const closeIcon = require("../assets/button_close.svg");
const chevronRightIcon = require("../assets/chevronRight.svg");
const chevronLeftIcon = require("../assets/chevronLeft.svg");

export enum EStatusUiProgress{
    UPLOAD = "Upload",
    OPTIMIZATION = "Optimization"
}

interface IPropsUiProgress {
    isOpenSnackbar: boolean;
    listFile: IFile[];
    statusUiProgress?: EStatusUiProgress;
    isError?: boolean;
    handleRetryUpload?: () => void;
}

export default function CustomUiProgress({
    isOpenSnackbar,
    listFile,
    statusUiProgress = EStatusUiProgress.OPTIMIZATION,
    isError,
    handleRetryUpload
}: IPropsUiProgress) {

    const [thumbnailImgProcess, setThumbnailImgProcess] = React.useState("")
    const [isMaximize, setIsMaximize] = React.useState(true);

    React.useEffect(() => {
        const getThumbnail = async () => {
            const formData = await getStorageData("formData");
            const parseFormData = JSON.parse(formData)
            if (parseFormData?.thumbnail) setThumbnailImgProcess(parseFormData?.thumbnail?.fileContent)
            else setThumbnailImgProcess(defaultUploadImg)
        }
      
        getThumbnail();
    }, [listFile])
    const handleConditionRenderFile = (progress: number, isOptimization: boolean) => {
        if(statusUiProgress === EStatusUiProgress.OPTIMIZATION){
            return (progress <= 100 && !isOptimization )
        }
        return isOptimization
    }

    const handleSetMaximize = () => {
        setIsMaximize(!isMaximize)
    }

    return (
        <Box>
            <Snackbar
                anchorOrigin={{
                    vertical: `${statusUiProgress === EStatusUiProgress.UPLOAD ? `bottom` : `top`}` as 'bottom' | 'top',
                    horizontal: 'right',
                }}
                open={isOpenSnackbar}
                key={`${statusUiProgress === EStatusUiProgress.UPLOAD ? `bottom right` : `top right`}`}
            >
                <SnackbarContentContainer isMaximize={isMaximize}>
                    <SnackbarContent
                        message={
                            <div style={{ width: '100%' }}>
                                <div className="action_progress">
                                    {
                                        isMaximize ?
                                            <IconButton className="btn_action" onClick={() => handleSetMaximize()}><img src={chevronRightIcon} width={24} height={24} alt=">" /></IconButton>
                                            :
                                            <IconButton className="btn_action" onClick={() => handleSetMaximize()}><img src={chevronLeftIcon} width={24} height={24} alt=">" /></IconButton>
                                    }
                                </div>
                                <div className="header_text">
                                    <p style={{ textAlign: "center", margin: 0, fontFamily: "Poppins", fontWeight: 700, fontSize: "20px", color: "#CFD1D4" }}>
                                        {`${statusUiProgress === EStatusUiProgress.OPTIMIZATION ? "Processing" : "Upload"}`} Status!
                                    </p>
                                </div>
                                <Box className="listFileProcessing">
                                    {listFile.map(({ fileName, progress, isOptimization }, index) => handleConditionRenderFile(progress, isOptimization) && (
                                        <Box className="fileProcessing" key={index + fileName}>
                                            <Box className="thumbnailImg">
                                                <img
                                                    src={thumbnailImgProcess}
                                                    width={62}
                                                    height={50}
                                                    alt="Thumbnail Video"
                                                    loading="lazy"
                                                />
                                            </Box>
                                            <Box className="processingProgress">
                                                <Box className="processStatus">
                                                    <p className="processStatus__videoName">{fileName}</p>
                                                    <span style={{ color: "#FF881A", fontFamily: "Poppins", fontWeight: 500, fontSize: "20px", margin: "0 16px" }}>
                                                        -
                                                    </span>
                                                    <div style={{ fontFamily: "Poppins", fontWeight: 500, fontSize: "20px", color: "#676767" }}>
                                                        {progress > 100 ? 100 : progress}%
                                                    </div>
                                                </Box>
                                                <CustomLinearProgressBar variant="determinate" value={progress > 100 ? 100 : progress} />
                                            </Box>
                                            <Box className="actionProgress" style={{display: `${statusUiProgress === EStatusUiProgress.OPTIMIZATION ? "none" : "block"}`}}>
                                                <CustomIconButton isErrorUpload={isError} onRetryUpload={handleRetryUpload}/>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            </div>
                        }
                    />
                </SnackbarContentContainer>
            </Snackbar>
        </Box>
    );
}

const CustomIconButton = ({isErrorUpload, onRetryUpload}: any) => {
    return (
        <div style={{
            display: "flex",
            columnGap: 4
        }}>
            <IconButton
                style={{ padding: 2 }}
                // onClick={() => onRetryUpload()}
                aria-label="play"
                color="secondary"
            >
                <img src={isErrorUpload ? errorIcon : playIcon} />
            </IconButton>

            <IconButton
                style={{ padding: 2 }}
                // onClick={handleEdit}
                aria-label="cancel"
                color="primary"
            >
                <img src={closeIcon} />

            </IconButton>
        </div>
    );
};

const SnackbarContentContainer = styled(Box)(({ theme, isMaximize }: { theme: Theme; isMaximize: boolean;}) => ({
    "& .MuiPaper-root.MuiSnackbarContent-root": {
        padding: isMaximize ? '24px' : 0,
        minWidth: "500px",
        position: "relative",
        background: "#00000099",
        borderRadius: 16,
        transition: 'transform 0.5s, width 0.5s, padding 0.5s',
        transform: isMaximize ? 'translateX(0)' : 'translateX(calc(100% - 16px))',
    },
    "& .MuiSnackbarContent-message": {
        width: "100%",
        padding: 0,
        height: isMaximize ? "auto" : "40px"
    },
    "& .header_text, .MuiSnackbarContent-message .listFileProcessing": {
        display: isMaximize ? "block" : "none"
    },
    "& .action_progress": {
        position: "absolute",
        right: 8,
        top: isMaximize ? 8 : 0,
        left: isMaximize ? "auto" : 0,
        "& .btn_action": {
            padding: 8
        }
    },
    "& .MuiSvgIcon-root": { fontSize: 24 },
    "& .listFileProcessing": {
        marginTop: "24px",
        display: "flex",
        flexDirection: "column",
        rowGap: 8,
        "& .fileProcessing": {
            display: "flex",
            flexDirection: "row",
            columnGap: 16,
            alignItems: "center",
            "& .thumbnailImg": {
                flex: "0 0 calc(100%/12*1.5)",
                maxWidtH: "calc(100%/12*1.5)"
            },
            "& .processingProgress": {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                rowGap: "4px",
                "& .processStatus": {
                    display: "flex",
                    "& .processStatus__videoName": {
                        margin: 0,
                        fontFamily: "Poppins",
                        fontSize: 20,
                        fontWeight: 500,
                        color: "#CFD1D4",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        maxWidth: "200px"
                    }
                },
            },
            "& .actionProgress": {
                flex: "0 0 calc(100%/12*1.5)",
                maxWidtH: "calc(100%/12*1.5)"
            }
        }
    }
}))

// Define custom styles for the LinearProgress component
const CustomLinearProgressBar = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 10,
    [`& .MuiLinearProgress-barColorPrimary`]: {
        backgroundColor: "#FF881A",
    },
    [`& .MuiLinearProgress-colorPrimary`]: {
        borderRadius: 10,
        backgroundColor: "#CFD1D4",
    },
}));