// Customizable Area Start
import React from "react";
import { useMeeting } from "@videosdk.live/react-sdk";

import ConnectionsView from "./ConnectionsView";

const Connections = () => {
  const { connections, meetingId } = useMeeting();

  const viewProps = {
    connections,
    meetingId,
  };

  return <ConnectionsView testID="ConnectionsView" {...viewProps} />;
};

export default Connections;
// Customizable Area End
