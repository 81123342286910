// Customizable Area Start
import React from "react";
import { useConnection } from "@videosdk.live/react-sdk";

import ConnectionView from "./ConnectionView";

const Connection = ({ connectionId }) => {
  const { connection } = useConnection(connectionId, {
    onMeeting: {
      onChatMessage: ({ message, participantId }) => {
        alert(
          `A person ${participantId} from ${connectionId} wants to say : ${message}`
        );
      }
    }
  });

  const connectionParticipants = [...connection.meeting.participants.values()];

  const sendMessage = () => {
    const message = prompt("Enter You Message");

    if (message) {
      return connection.meeting.sendChatMessage(message);
    }

    alert("Empty Message");
  };

  const viewProps = {
    connection,
    connectionParticipants,
    sendMessage
  };

  return <ConnectionView testID="ConnectionView" {...viewProps} />;
};

export default Connection;
// Customizable Area End
