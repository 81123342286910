import React from 'react';
import {
  Dialog, DialogContent, DialogActions, Button, Radio, RadioGroup, FormControlLabel, Typography, IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';

// Styled component for the modal
const StyledMuteNotificationModal = styled('div')(({ theme }) => ({
  background: "#1D1D1F",
  borderRadius: "10px",
  boxShadow: "-5px 4px 12px 0px #00000040 inset",
  padding: "40px",
  position: "relative",

  "& .close.MuiIconButton-root": {
    height: "24px",
    width: "24px",
    padding: "4px",
    position: "absolute",
    top: "16px",
    right: "16px",
  },

  "& .MuiTypography-h6": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "28px",
    textAlign: "center",
    color: "#CFD1D4",
    marginBottom: 32,
  },

  "& .MuiTypography-body1": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#676767",
  },
}));

interface MuteNotificationDialogProps {
  open: boolean;
  onClose: () => void;
  selectedValue: string;
  onRadioChange: (value: string) => void;
}

const MuteNotificationDialog: React.FC<MuteNotificationDialogProps> = ({
  open,
  onClose,
  selectedValue,
  onRadioChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    onRadioChange(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          border: "unset",
          margin: 'auto',
          background: "transparent",
          maxWidth: "unset",
        },
      }}
      BackdropProps={{
        style: {
          backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
        }
      }}
    >
      <StyledMuteNotificationModal style={{ width: '780px' }}>
        <IconButton
          data-test-id="on-close-delete-modal"
          style={{ cursor: "pointer" }}
          onClick={onClose}
          className='close'
        >
          <Close style={{ color: "#676767" }} />
        </IconButton>

        <Typography variant="h6">Mute Notifications</Typography>

        <Typography variant="body1" style={{ marginTop: '24px' }}>
          You will be notified if you are mentioned
        </Typography>

        <DialogContent style={{ borderRadius:10, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginLeft: 'auto',backgroundColor:"#252527",width:332,marginRight: 'auto',marginBottom:16 }}>
        <RadioGroup
            value={selectedValue}
            onChange={handleChange}
          >
            <FormControlLabel
              value="8 hours"
              control={<Radio style={{ color: '#FF881A' }} />}
              label="8 hours"
            />
            <FormControlLabel
              value="1 week"
              control={<Radio style={{ color: '#FF881A' }} />}
              label="1 week"
            />
            <FormControlLabel
              value="Always"
              control={<Radio style={{ color: '#FF881A' }} />}
              label="Always"
            />
          </RadioGroup>
        </DialogContent>

        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            data-test-id="close-mute-modal"
            variant="outlined"
            style={{
              borderRadius: 8,
              color: "#FF881A",
              borderColor: "#FF881A",
              fontFamily: "Montserrat",
              fontSize: 18,
              width: "200px",
              padding: "10px 16px",
              fontWeight: 600,
              textTransform: 'none',
              height: '55px',
              cursor: "pointer",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="confirm-mute"
            variant="contained"
            style={{
              backgroundColor: "#FF881A",
              color: "#1D1D1F",
              borderRadius: 8,
              fontSize: 18,
              padding: "10px",
              fontFamily: "Montserrat",
              fontWeight: 600,
              width: "200px",
              height: '55px',
              marginLeft: '24px',
              cursor: "pointer",
              textTransform: 'none'
            }}
            onClick={onClose}
          >
            Ok
          </Button>
        </DialogActions>
      </StyledMuteNotificationModal>
    </Dialog>
  );
};

export default MuteNotificationDialog;
