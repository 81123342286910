// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../../packages/framework/src/Utilities";

export interface KeyboardInfoResponse {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      compitition_compatibilities: Array<Item>;
      teams: { data: Array<Team> };
      roles_and_positions: {
        staffs: Array<Staff>;
        positions: Array<Position>;
      }
      individual_players: {
        staffs: Array<PlayerStaff & { id: number }>;
        players: Array<Player & { id: number }>
      };
      default_actions: { data: ItemResponse[] };
      sub_actions: { data: ItemResponse[] };
      values: { data: ItemResponse[] };
      reasons: { data: ItemResponse[] };
      sub_reasons: { data: ItemResponse[] };
      comment: string;
      createt_by: {
        data: {
          attributes: {
            first_name: string;
            last_name: string;
          }
        }
      };
      createt_at: string;
    }
  }
}

export interface ItemResponse {
  attributes: {
    id: number;
    name: string;
    default_action_id?: number;
  }
}

export interface Staff {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  selected: boolean;
}

export interface Position {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  selected: boolean;
}
import { Team } from "../../../../packages/components/src/CustomKeyboards/AssignTeamModal";
import { toast } from "react-toastify";
import { Player, Staff as PlayerStaff } from "../../../../packages/components/src/CustomKeyboards/AssignPlayerModal";

export interface Item {
  id: number;
  name: string;
  parent_id?: number;
  // parent_id use for link between part2 items
  // example: sub-action depend on action
  // so, action has a parent_id is action id that sub-action belong to
  // similar, sub-action, value, reason depend on action. and sub-reason depend on reason
  group_id?: number;
  // group id used for bank item
  // example, actions belong to a action group
}

export interface ItemGroup {
  id: number;
  name: string;
}


// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  openSnackBar: boolean;
  snackBarMessage: string;

  gameTitle: string;

  part1StepIndex: number;
  part2StepIndex: number;
  currentpart: number;

  // action
  showEditActionModal: boolean;
  showActionBankModal: boolean;
  defaultActions: Array<Item>;
  actionsBank: Array<Item>;
  actionsBankGroup: Array<Item>;
  selectedActions: Array<Item>;

  // sub-action
  showEditSubActionModal: boolean;
  showSubActionBankModal: boolean;
  subActions: Array<Item>;
  subActionsBank: Array<Item>;
  subActionsBankGroup: Array<Item>;
  selectedSubActions: Array<Item>;

  // value
  showEditValuesModal: boolean;
  showValuesBankModal: boolean;
  values: Array<Item>;
  valuesBank: Array<Item>;
  valuesBankGroup: Array<Item>;
  selectedValues: Array<Item>;

  // reason
  showEditReasonsModal: boolean;
  showReasonsBankModal: boolean;
  reasons: Array<Item>;
  reasonsBank: Array<Item>;
  reasonsBankGroup: Array<Item>;
  selectedReasons: Array<Item>;

  // sub-reason
  showEditSubReasonsModal: boolean;
  showSubReasonsBankModal: boolean;
  subReasons: Array<Item>;
  subReasonsBank: Array<Item>;
  subReasonsBankGroup: Array<Item>;
  selectedSubReasons: Array<Item>;

  // comment
  comment: string;

  competitionCompatibilities: Array<Item>;
  selectedCompetitionCompatibilities: Array<Item>;
  competitionSelecting: Item | undefined;

  showAssignRolePositionModal: boolean;
  staffList: Array<Staff>;
  positionList: Array<Position>;
  tmpStaffList: Array<Staff>;
  tmpPositionList: Array<Position>;
  showEmptyRolePositionModal: boolean;

  showAssignTeamModal: boolean;
  teams: Array<Team>;
  tmpTeams: Array<Team>;

  keyboardInfo: KeyboardInfoResponse | undefined;

  userRole: string;

  showAssignPlayerModal: boolean;
  showEmptyPlayerModal: boolean;
  playerList: Array<Player>;
  playerStaffList: Array<PlayerStaff>;
  tmpPlayerList: Array<Player>;
  tmpPlayerStaffList: Array<PlayerStaff>;

  informModal: {
    show: boolean;
    header: string;
    title: string;
  };

  isUpdate: boolean; // true: keyboard update, false: keyboard create

  showUpdateKeyboardModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CreateKeyboardsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  customKeyboardId: string = this.props.navigation.getParam("id")

  part1CreationRef: any;
  part2CreationRef: any;
  getCompetitionCompatibilitiesCallId: any;

  getDefaultActionsCallId: any;
  getActionsBankCallId: any;
  saveActionsCallId: any;

  getSubActionsCallId: any;
  getSubActionsBankCallId: any;
  saveSubActionsCallId: any;

  getValuesCallId: any;
  getValuesBankCallId: any;
  saveValuesCallId: any;

  getReasonsCallId: any;
  getReasonsBankCallId: any;
  saveReasonsCallId: any;

  getSubReasonsCallId: any;
  getSubReasonsBankCallId: any;
  saveSubReasonsCallId: any;

  savePart2DataCallId: any;

  saveCommentCallId: any;

  getRolesAndPositionsCallId: string = "";
  getTeamCallId: string = "";
  saveTeamCallId: string = "";
  saveRolePositionCallId: string = "";
  getKeyboardInfoCallId: string = "";
  saveCompetitionCompatibilityCallId: string = "";
  getPlayerCallId: string = "";
  savePlayerCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.part1CreationRef = React.createRef();
    this.part2CreationRef = React.createRef();
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      openSnackBar: false,
      snackBarMessage: "",

      gameTitle: "",

      part1StepIndex: 0,
      part2StepIndex: 0,
      currentpart: 1,

      defaultActions: [],
      actionsBank: [],

      subActions: [],
      subActionsBank: [],
      values: [],
      valuesBank: [],
      reasons: [],
      reasonsBank: [],
      subReasons: [],
      subReasonsBank: [],

      actionsBankGroup: [],
      subActionsBankGroup: [],
      valuesBankGroup: [],
      reasonsBankGroup: [],
      subReasonsBankGroup: [],

      selectedActions: [],
      selectedSubActions: [],
      selectedValues: [],
      selectedReasons: [],
      selectedSubReasons: [],
      comment: "",

      showEditActionModal: false,
      showActionBankModal: false,
      showEditSubActionModal: false,
      showSubActionBankModal: false,
      showEditValuesModal: false,
      showValuesBankModal: false,
      showEditReasonsModal: false,
      showReasonsBankModal: false,
      showEditSubReasonsModal: false,
      showSubReasonsBankModal: false,

      competitionCompatibilities: [],
      selectedCompetitionCompatibilities: [],
      competitionSelecting: undefined,

      showAssignRolePositionModal: false,
      staffList: [],
      positionList: [],
      tmpStaffList: [],
      tmpPositionList: [],
      showEmptyRolePositionModal: false,

      showAssignTeamModal: false,
      teams: [],
      tmpTeams: [],

      keyboardInfo: undefined,
      userRole: '',

      showAssignPlayerModal: false,
      showEmptyPlayerModal: false,
      playerStaffList: [],
      playerList: [],
      tmpPlayerList: [],
      tmpPlayerStaffList: [],

      informModal: {
        show: false,
        header: "",
        title: "",
      },

      isUpdate: false,
      showUpdateKeyboardModal: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.getCompetitionCompatibilities();
    this.getDefaultActions();
    this.getActionsBank();
    this.getSubActionsBank();
    this.getValuesBank();
    this.getReasonsBank();
    this.getSubReasonsBank();
    this.getRolesAndPositions();
    this.getTeam();
    this.getPlayer();
    this.getKeyboardInfo();
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.setState({ loading: false });
    this.handleGetCompetitionResponse(message);
    this.handleGetDefaultActionsResponse(message);
    this.handleGetActionsBankResponse(message);
    this.handleGetSubActionsResponse(message);
    this.handleGetValuesResponse(message);
    this.handleGetReasonsResponse(message);
    this.handleGetSubReasonsResponse(message);

    this.handleGetRolesAndPositionsResponse(message);
    this.handleGetTeamResponse(message);
    this.handleSaveTeamResponse(message);
    this.handleSaveRolePositionResponse(message);
    this.handleGetPlayerResponse(message);
    this.handleGetKeyboardInfoResponse(message);
    this.handleSaveCompetitionCompatibilityResponse(message);
    this.handleSavePlayerResponse(message);
    this.handleSaveCommentResponse(message);

    this.handleSavePart2Response(message);
    // Customizable Area End
  }


  // Customizable Area Start
  componentDidMount = async () => {
    const userData = sessionStorage.getItem('profile')

    if (userData) {
      const parsedUserData = JSON.parse(userData);
      const { role } = parsedUserData;

      if (role !== 'coach' && role !== 'team admin') {
        this.props.navigation.navigate("Cfcustomkeyboards");
      }

      this.setState({ userRole: role })
    }
  }

  componentDidUpdate = (prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) => {
    const { keyboardInfo: prevKeyboardInfo, teams: prevTeams, staffList: prevStaffList, positionList: prevPositionList, playerList: prevPlayerList, playerStaffList: prevPlayerStaffList } = prevState;
    const { keyboardInfo, teams, staffList, positionList, playerList, playerStaffList } = this.state;

    if (
      (
        String(prevKeyboardInfo) !== String(keyboardInfo) ||
        String(prevPositionList) !== String(positionList) ||
        String(prevStaffList) !== String(staffList) ||
        String(prevTeams) !== String(teams) ||
        String(prevPlayerList) !== String(playerList) ||
        String(prevPlayerStaffList) !== String(playerStaffList)
      ) &&
      keyboardInfo !== undefined
    ) {
      const { teams: keyboardTeams, compitition_compatibilities, roles_and_positions, individual_players } = keyboardInfo.data.attributes;

      this.updateTeam(keyboardTeams.data);
      this.updateStaffList(roles_and_positions.staffs);
      this.updatePositionList(roles_and_positions.positions);
      this.updateCompetitionCompatibility(compitition_compatibilities);
      this.updatePlayerStaff(individual_players.staffs);
      this.updatePlayer(individual_players.players);
    }
  }

  updatePlayerStaff = (staffs: Array<PlayerStaff & { id: number }>) => {
    if (staffs.length > 0 && this.state.playerStaffList.length > 0) {
      const selectedPlayerStaffs = staffs.map(item => String(item.id));

      this.setState(prev => ({
        playerStaffList: prev.playerStaffList.map(item => {
          if (selectedPlayerStaffs.includes(item.id)) {
            item.selected = true;
          }

          return item;
        })
      }))
    }
  }

  updatePlayer = (players: Array<Player & { id: number }>) => {
    if (players.length > 0 && this.state.playerList.length > 0) {
      const selectedPlayers = players.map(item => String(item.id));

      this.setState(prev => ({
        playerList: prev.playerList.map(item => {
          if (selectedPlayers.includes(item.id)) {
            item.selected = true;
          }

          return item;
        })
      }))
    }
  }

  updateCompetitionCompatibility = (competitions: Array<Item>) => {
    if (competitions.length > 0 && this.state.competitionCompatibilities.length > 0) {
      const selectedCompetition = competitions.map(item => item.id);

      this.setState(prev => ({
        selectedCompetitionCompatibilities: prev.competitionCompatibilities.filter(item => selectedCompetition.includes(item.id))
      }))
    }
  }

  updateTeam = (teams: Array<Team>) => {
    if (teams.length > 0 && this.state.teams.length > 0) {
      const selectedTeams = teams.map(item => String(item.id));

      this.setState(prev => ({
        teams: prev.teams.map(item => {
          if (selectedTeams.includes(item.id)) {
            item.selected = true;
          }

          return item;
        })
      }))
    }
  }

  updateStaffList = (staffs: Array<Staff>) => {
    if (staffs.length > 0 && this.state.staffList.length > 0) {
      const selectedStaffId = staffs.map((item: Staff) => item.id);

      this.setState(prev => ({
        staffList: prev.staffList.map(item => {
          if (selectedStaffId.includes(item.id)) {
            item.selected = true;
          }

          return item;
        })
      }))
    }
  }

  updatePositionList = (positions: Array<Position>) => {
    if (positions.length > 0 && this.state.positionList.length > 0) {
      const selectedPositionId = positions.map((item: Position) => item.id);

      this.setState(prev => ({
        positionList: prev.positionList.map(item => {
          if (selectedPositionId.includes(item.id)) {
            item.selected = true;
          }

          return item;
        })
      }))
    }
  }

  handleSaveCompetitionCompatibilityResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveCompetitionCompatibilityCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson.data) {
        this.getKeyboardInfo();
        this.setState({ competitionSelecting: undefined })
      } else {
        toast.error(configJSON.saveCompetitionFail, { position: "top-center" })
        this.setState(prev => {
          const { selectedCompetitionCompatibilities, competitionSelecting } = prev;
          let selected = selectedCompetitionCompatibilities;

          if (selectedCompetitionCompatibilities.map(item => item.id).includes(competitionSelecting!.id)) {
            selected = selected.filter(item => item.id !== competitionSelecting!.id)
          } else {
            selected = [...selected, competitionSelecting!]
          }

          return {
            ...prev,
            selectedCompetitionCompatibilities: selected,
            competitionSelecting: undefined
          }
        })
      }
    }
  }

  handleGetKeyboardInfoResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getKeyboardInfoCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson: KeyboardInfoResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data?.attributes?.default_actions?.data?.length > 0) this.setState({ isUpdate: true, currentpart: 2 });

      if (responseJson.data && responseJson.data.id) {
        const keyboardAttributes = responseJson.data.attributes;
        this.setState({
          selectedActions: keyboardAttributes.default_actions.data.map((element: any) => ({
            id: element.attributes.id,
            name: element.attributes.name,
          })),
          selectedSubActions: keyboardAttributes.sub_actions.data.map((element: any) => ({
            id: element.attributes.id,
            name: element.attributes.name,
            parent_id: element.attributes.default_action_id
          })),
          selectedValues: keyboardAttributes.values.data.map((element: any) => ({
            id: element.attributes.id,
            name: element.attributes.name,
            parent_id: element.attributes.default_action_id
          })),
          selectedReasons: keyboardAttributes.reasons.data.map((element: any) => ({
            id: element.attributes.id,
            name: element.attributes.name,
            parent_id: element.attributes.default_action_id
          })),
          selectedSubReasons: keyboardAttributes.sub_reasons.data.map((element: any) => ({
            id: element.attributes.id,
            name: element.attributes.name,
            parent_id: element.attributes.default_action_id
          })),
          comment: keyboardAttributes?.comment || "",
        }, () => {
          this.getSubActions();
          this.getValues();
          this.getReasons();
          this.getSubReasons();
        });
        this.setState({ keyboardInfo: responseJson });
      }
    }
  }

  handleSavePlayerResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.savePlayerCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.staffs || responseJson.players) {
        this.getKeyboardInfo();
        toast.success(configJSON.successMessage, { position: 'top-center' })
        this.setState(prev => ({ playerList: prev.tmpPlayerList, playerStaffList: prev.tmpPlayerStaffList, showAssignPlayerModal: false, tmpPlayerList: [], tmpPlayerStaffList: [], showEmptyPlayerModal: false }))
      } else {
        toast.error(configJSON.failMessage, { position: 'top-center' })
      }
    }
  }

  handleSaveCommentResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveCommentCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      console.log("save comment success");
    }
  }

  handleSavePart2Response = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.savePart2DataCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ openSnackBar: true, snackBarMessage: "Update keyboard successfully!" });
      const timer = setTimeout(() => {
        this.setState({ openSnackBar: false });
        clearTimeout(timer);
      }, 10000);
      this.setState({
        informModal: {
          show: true,
          header: "Congratulation!",
          title: this.state.isUpdate ? "Update keyboard successfully." : "Create keyboard successfully"
        }, showUpdateKeyboardModal: false,
      })
    }
  }

  handleSaveRolePositionResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveRolePositionCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.staffs || responseJson.positions) {
        this.getKeyboardInfo();
        toast.success(configJSON.successMessage, { position: 'top-center' })
        this.setState(prev => ({ staffList: prev.tmpStaffList, positionList: prev.tmpPositionList, showAssignRolePositionModal: false, showEmptyRolePositionModal: false, tmpPositionList: [], tmpStaffList: [] }))
      } else {
        toast.error(configJSON.failMessage, { position: 'top-center' })
      }
    }
  }

  handleSaveTeamResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.saveTeamCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data && responseJson.data.length > 0) {
        this.getKeyboardInfo();
        toast.success(configJSON.assignTeamSuccess, { position: 'top-center' })
        this.setState(prev => ({ teams: prev.tmpTeams, showAssignTeamModal: false, tmpTeams: [], }))
      } else {
        toast.error(configJSON.assignTeamFail, { position: 'top-center' })
      }
    }
  }

  handleGetPlayerResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPlayerCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.staffs) {
        this.setState({ playerStaffList: responseJson.staffs.data.map((item: PlayerStaff) => ({ ...item, selected: false })) })
      }

      if (responseJson.players) {
        this.setState({ playerList: responseJson.players.data.map((item: Player) => ({ ...item, selected: false })) })
      }
    }
  }

  handleGetTeamResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTeamCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.data) {
        this.setState({ teams: responseJson.data.map((item: Team) => ({ ...item, selected: false })) })
      }
    }
  }

  handleGetRolesAndPositionsResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRolesAndPositionsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.staffs) {
        this.setState({ staffList: responseJson.staffs.map((item: Staff) => ({ ...item, selected: false })) })
      }

      if (responseJson.positions) {
        this.setState({ positionList: responseJson.positions.map((item: Position) => ({ ...item, selected: false })) })
      }
    }
  }

  handleGetCompetitionResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCompetitionCompatibilitiesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (Array.isArray(responseJson)) this.setState({ competitionCompatibilities: responseJson });
    }
  }

  handleGetDefaultActionsResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDefaultActionsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        defaultActions: responseJson.data.map((element: any) => ({
          id: element.attributes.id,
          name: element.attributes.name,
        }))
      });
      this.setState({ gameTitle: responseJson.data[0].attributes?.sport_title_name || responseJson.data[0].attributes?.esport_name || "" });
    }
  }

  handleGetActionsBankResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getActionsBankCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const groupRes = responseJson.data.map((element: any) => ({
        id: element.attributes.id,
        name: element.attributes.name,
      }))
      const bankRes: Item[] = [];
      responseJson.data.map((groupElm: any) => {
        groupElm.attributes.default_actions.data.map((subactionElm: any) => {
          bankRes.push({
            id: subactionElm.attributes.id,
            name: subactionElm.attributes.name,
            parent_id: groupElm.attributes.id,
          })
        })
      });
      this.setState({
        actionsBankGroup: groupRes,
        actionsBank: bankRes,
      });
    }
  }

  handleGetSubActionsResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSubActionsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson?.data?.length > 0) {
        this.setState({
          subActions: responseJson.data.map((element: any) => ({
            id: element.attributes.id,
            name: element.attributes.name,
            parent_id: element.attributes.default_action_id
          }))
        });
      }
    }
  }

  handleGetValuesResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getValuesCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        values: responseJson.values.map((element: any) => ({
          id: element.id,
          name: element.name,
          parent_id: element.action_id,
        }))
      });
    }
  }

  handleGetReasonsResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getReasonsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        reasons: responseJson.reasons.map((element: any) => ({
          id: element.id,
          name: element.name,
          parent_id: element.action_id
        }))
      });
    }
  }

  handleGetSubReasonsResponse = (message: Message) => {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSubReasonsCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({
        subReasons: responseJson.sub_reasons.map((element: any) => ({
          id: element.id,
          name: element.name,
          parent_id: element.action_id
        }))
      });
    }
  }

  getPlayer = async () => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getPlayerCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPlayersURL
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    )

    runEngine.sendMessage(requestMessage.messageId, requestMessage);
  }

  getKeyboardInfo = async () => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )

    this.getKeyboardInfoCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getKeyboardInfoURL.replace("keyboardId", this.customKeyboardId)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    )

    runEngine.sendMessage(requestMessage.messageId, requestMessage);
  }

  getTeam = async () => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTeamCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTeamURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveTeamApi = async (teamIds: Array<string>) => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveTeamCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveTeamURL.replace("keyboardId", this.customKeyboardId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "team_ids": teamIds.map(item => Number(item))
      })
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRolesAndPositions = async () => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRolesAndPositionsCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRolesAndPositionsURL
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  savePlayerApi = async (staff_ids: Array<number>, player_ids: Array<number>) => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.savePlayerCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.savePlayerURL.replace("keyboardId", this.customKeyboardId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        staff_ids,
        player_ids
      })
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  saveRolesPositionApi = async (staff_ids: Array<number>, position_ids: Array<number>) => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveRolePositionCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveRolePositionURL.replace("keyboardId", this.customKeyboardId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        staff_ids,
        position_ids
      })
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCompetitionCompatibilities = () => {
    const token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCompetitionCompatibilitiesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCompetitionEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  createApiMessage = (method: string) => {
    const token = window.localStorage.getItem("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    return requestMessage;
  }

  getDefaultActions = () => {
    const requestMessage = this.createApiMessage("GET");
    this.getDefaultActionsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDefaultActionsEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getActionsBank = () => {
    const requestMessage = this.createApiMessage("GET");
    this.getActionsBankCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getActionsBankEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSubActions = () => {
    const actionIds = this.state.selectedActions.map(actionElm => actionElm.id).join(",");
    const requestMessage = this.createApiMessage("GET");
    this.getSubActionsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubActionsEndPoint}?default_action_ids=${actionIds}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSubActionsBank = () => {
    const requestMessage = this.createApiMessage("GET");
    this.getSubActionsBankCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubActionsBankEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValues = () => {
    const actionIds = this.state.selectedActions.map(actionElm => actionElm.id).join(",");
    const requestMessage = this.createApiMessage("GET");
    this.getValuesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getValuesEndPoint}?actions_id=${actionIds}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValuesBank = () => {
    const requestMessage = this.createApiMessage("GET");
    this.getValuesBankCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getValuesBankEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getReasons = () => {
    const actionIds = this.state.selectedActions.map(actionElm => actionElm.id).join(",");
    const requestMessage = this.createApiMessage("GET");
    this.getReasonsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getReasonsEndPoint}?actions_id=${actionIds}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getReasonsBank = () => {
    const requestMessage = this.createApiMessage("GET");
    this.getReasonsBankCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getReasonsBankEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSubReasons = () => {
    const actionIds = this.state.selectedActions.map(actionElm => actionElm.id).join(",");
    const requestMessage = this.createApiMessage("GET");
    this.getSubReasonsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSubReasonsEndPoint}?actions_id=${actionIds}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getSubReasonsBank = () => {
    const requestMessage = this.createApiMessage("GET");
    this.getSubReasonsBankCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubReasonsBankEndPoint
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  saveAction = (items: Item[]) => {
    this.setState({
      selectedActions: [...items],
      selectedSubActions: [],
      selectedValues: [],
      selectedReasons: [],
      selectedSubReasons: [],
    }, () => {
      this.getSubActions();
      this.getValues();
      this.getReasons();
      this.getSubReasons();
    });
  }

  saveActionBank = (items: Item[]) => {
    this.setState({
      selectedActions: [...items],
      defaultActions: items,
      showEditActionModal: false,

      selectedSubActions: [],
      selectedValues: [],
      selectedReasons: [],
      selectedSubReasons: [],
    }, () => {
      this.getSubActions();
      this.getValues();
      this.getReasons();
      this.getSubReasons();
    });
  }

  saveSubAction = (items: Item[]) => {
    this.setState({ selectedSubActions: [...items] });
  }

  saveValue = (items: Item[]) => {
    this.setState({ selectedValues: [...items] });
  }

  saveReason = (items: Item[]) => {
    this.setState({ selectedReasons: [...items] });
  }

  saveSubReason = (items: Item[]) => {
    this.setState({ selectedSubReasons: [...items] });
  }

  showSavingDataMsg = () => {
    this.setState({ openSnackBar: true, snackBarMessage: "Updating keyboard data..." });
  }

  saveActionsAPI = () => {
    this.showSavingDataMsg();
    const requestMessage = this.createApiMessage("PUT");
    this.saveActionsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveActionsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        keyboard_id: this.customKeyboardId,
        action_bank: {
          default_action_id: this.state.selectedActions.map(itemElm => itemElm.id)
        }
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  saveSubActionsAPI = () => {
    this.showSavingDataMsg();
    const requestMessage = this.createApiMessage("PUT");
    this.saveSubActionsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveSubActionsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        keyboard_id: this.customKeyboardId,
        action_sub_actions: this.state.selectedActions.map(actionElm => ({
          default_action_id: actionElm.id,
          sub_action_id: this.state.selectedSubActions.filter(itemElm => itemElm.parent_id === actionElm.id).map(subActionElm => subActionElm.id)
        }))
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  saveValuesAPI = () => {
    this.showSavingDataMsg();
    const requestMessage = this.createApiMessage("PUT");
    this.saveValuesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveValuesEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        keyboard_id: this.customKeyboardId,
        values: this.state.selectedActions.map(actionElm => ({
          action_id: actionElm.id,
          value_ids: this.state.selectedValues.filter(itemElm => itemElm.parent_id === actionElm.id).map(subActionElm => subActionElm.id)
        }))
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  saveReasonsAPI = () => {
    this.showSavingDataMsg();
    const requestMessage = this.createApiMessage("PUT");
    this.saveReasonsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveReasonsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        keyboard_id: this.customKeyboardId,
        reasons: this.state.selectedActions.map(actionElm => ({
          action_id: actionElm.id,
          reason_ids: this.state.selectedReasons.filter(itemElm => itemElm.parent_id === actionElm.id).map(subActionElm => subActionElm.id)
        }))
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  saveSubReasonsAPI = () => {
    this.showSavingDataMsg();
    const requestMessage = this.createApiMessage("PUT");
    this.saveSubReasonsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveSubReasonsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        keyboard_id: this.customKeyboardId,
        reassub_reasonsns: this.state.selectedActions.map(actionElm => ({
          action_id: actionElm.id,
          sub_reason_ids: this.state.selectedSubReasons.filter(itemElm => itemElm.parent_id === actionElm.id).map(subActionElm => subActionElm.id)
        }))
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  saveCommentAPI = () => {
    this.setState({ loading: true });
    const requestMessage = this.createApiMessage("PUT");
    this.saveCommentCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveCommentEndPoint.replace(":keyboard_id", this.customKeyboardId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        keyboard: {
          comment: this.state.comment
        }
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  savePart2API = () => {
    this.showSavingDataMsg();
    const requestMessage = this.createApiMessage("PUT");
    this.savePart2DataCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.savePart2DataEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        keyboard: {
          id: this.customKeyboardId,
          comment: this.state.comment,
          default_actions: this.state.selectedActions.map(actionElm => ({
            id: actionElm.id,
            sub_action_ids: this.state.selectedSubActions.filter(itemElm => itemElm.parent_id === actionElm.id).map(subActionElm => subActionElm.id),
            value_ids: this.state.selectedValues.filter(itemElm => itemElm.parent_id === actionElm.id).map(subActionElm => subActionElm.id),
            reason_ids: this.state.selectedReasons.filter(itemElm => itemElm.parent_id === actionElm.id).map(subActionElm => subActionElm.id),
            sub_reason_ids: this.state.selectedSubReasons.filter(itemElm => itemElm.parent_id === actionElm.id).map(subActionElm => subActionElm.id),
          }))
        }
      })
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  savePart2Data = () => {
    this.savePart2API();
  }

  saveCompetitionCompatibility = async (competitionIds: Array<number>) => {
    const token = await getStorageData("authToken");

    const header = {
      "Content-Type": configJSON.jsonApiContentType,
      token
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveCompetitionCompatibilityCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.saveCompetitionCompatibilityURL.replace("keyboardId", this.customKeyboardId)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        competition_compatibility_ids: competitionIds
      })
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  showEditAction = () => this.setState({ showEditActionModal: true });
  hideEditAction = () => this.setState({ showEditActionModal: false });
  showActionBank = () => this.setState({ showActionBankModal: true });
  hideActionBank = () => this.setState({ showActionBankModal: false });

  showEditSubAction = () => this.setState({ showEditSubActionModal: true });
  hideEditSubAction = () => this.setState({ showEditSubActionModal: false });
  showSubActionBank = () => this.setState({ showSubActionBankModal: true });
  hideSubActionBank = () => this.setState({ showSubActionBankModal: false });

  showEditValues = () => this.setState({ showEditValuesModal: true });
  hideEditValues = () => this.setState({ showEditValuesModal: false });
  showValuesBank = () => this.setState({ showValuesBankModal: true });
  hideValuesBank = () => this.setState({ showValuesBankModal: false });

  showEditReasons = () => this.setState({ showEditReasonsModal: true });
  hideEditReasons = () => this.setState({ showEditReasonsModal: false });
  showReasonsBank = () => this.setState({ showReasonsBankModal: true });
  hideReasonsBank = () => this.setState({ showReasonsBankModal: false });

  showEditSubReasons = () => this.setState({ showEditSubReasonsModal: true });
  hideEditSubReasons = () => this.setState({ showEditSubReasonsModal: false });
  showSubReasonsBank = () => this.setState({ showSubReasonsBankModal: true });
  hideSubReasonsBank = () => this.setState({ showSubReasonsBankModal: false });

  hideAssignRolePosition = () => this.setState({ showAssignRolePositionModal: false })
  showAssignRolePosition = () => this.setState({ showAssignRolePositionModal: true })
  saveRolePosition = (staffList: Array<Staff>, positionList: Array<Position>) => {
    if (staffList.every(item => !item.selected) && positionList.every(item => !item.selected)) {
      this.setState({ showAssignRolePositionModal: false, showEmptyRolePositionModal: true })
    } else {
      this.setState({ tmpPositionList: positionList, tmpStaffList: staffList });
      this.saveRolesPositionApi(staffList.filter(item => item.selected).map(item => item.id), positionList.filter(item => item.selected).map(item => item.id))
    }
  }
  hideEmptyRolePosition = () => this.setState({ showAssignRolePositionModal: true, showEmptyRolePositionModal: false, showEmptyPlayerModal: false })
  saveEmptyRolePosition = () => {
    this.setState(prev => {
      const newState: S = {
        ...prev,
        tmpPlayerList: [],
        tmpPlayerStaffList: [],
        tmpPositionList: [],
        tmpStaffList: [],
      }

      if (prev.showEmptyPlayerModal) {
        newState.tmpPlayerList = prev.playerList.map(item => ({ ...item, selected: false }))
        newState.tmpPlayerStaffList = prev.tmpPlayerStaffList.map(item => ({ ...item, selected: false }))
      }

      if (prev.showEmptyRolePositionModal) {
        newState.tmpStaffList = prev.staffList.map(item => ({ ...item, selected: false }))
        newState.tmpPositionList = prev.positionList.map(item => ({ ...item, selected: false }))
      }

      return newState
    })

    if (this.state.showEmptyPlayerModal) {
      this.savePlayerApi([], [])
    }

    if (this.state.showEmptyRolePositionModal) {
      this.saveRolesPositionApi([], [])
    }
  }

  hideAssignTeam = () => {
    this.setState({ showAssignTeamModal: false })
  }
  showAssignedTeam = () => {
    this.setState({ showAssignTeamModal: true })
  }
  saveTeams = (teams: Array<Team>) => {
    if (teams.every(item => !item.selected)) {
      this.setState({ showAssignTeamModal: false, teams, tmpTeams: [] })
    } else {
      this.saveTeamApi(teams.filter(item => item.selected).map(item => item.id));
      this.setState({ tmpTeams: teams })
    }
  }

  showAssignPlayer = () => this.setState({ showAssignPlayerModal: true })
  hideAssignPlayer = () => this.setState({ showAssignPlayerModal: false })
  savePlayers = (staffList: Array<PlayerStaff>, playerList: Array<Player>) => {
    if (!staffList.some(item => item.selected) && !playerList.some(item => item.selected)) {
      this.setState({ showAssignPlayerModal: false, showEmptyPlayerModal: true })
    } else {
      this.setState({ tmpPlayerList: playerList, tmpPlayerStaffList: staffList })
      this.savePlayerApi(staffList.filter(item => item.selected).map(item => Number(item.id)), playerList.filter(item => item.selected).map(item => Number(item.id)));
    }
  }

  selectCompetitionCompatibility = (element: any) => {
    let selected = this.state.selectedCompetitionCompatibilities;

    if (selected.map(item => item.id).includes(element.id)) {
      selected = selected.filter(item => item.id !== element.id);
    } else {
      selected.push(element)
    }

    this.setState({
      selectedCompetitionCompatibilities: selected,
      competitionSelecting: element,
    })

    this.saveCompetitionCompatibility(selected.map(item => item.id))
  }

  disabledPartOneNextBtn = () => {
    const isAdmin = this.state.userRole === "team admin";
    const isTeamSelected = this.state.teams.some(item => item.selected);
    const isCompetitionSelected = this.state.selectedCompetitionCompatibilities.length > 0;
    let disabled = false;

    switch (this.state.part1StepIndex) {
      case 0:
        disabled = isAdmin ? !isTeamSelected : !isCompetitionSelected;
        break;

      case 1:
        disabled = isAdmin ? !isCompetitionSelected : false;
        break;

      default:
        break;
    }

    return disabled;
  }

  handlePartOneNextBtn = () => {
    const isAdmin = this.state.userRole === "team admin";

    if ((isAdmin && this.state.part1StepIndex < 3) || (!isAdmin && this.state.part1StepIndex < 2)) {
      this.setState(prev => ({ part1StepIndex: prev.part1StepIndex + 1 }))
    } else if ((isAdmin && this.state.part1StepIndex === 3) || (!isAdmin && this.state.part1StepIndex === 2)) {
      this.setState({ currentpart: 2 })
    }
  }

  handlePartOneBackBtn = () => {
    this.props.navigation.navigate("Cfcustomkeyboards")
  }

  filterUniqueItems = (items: Item[]) => {
    const seenIds = new Set<number>();

    return items.filter(item => {
      if (seenIds.has(item.id)) {
        return false;
      } else {
        seenIds.add(item.id);
        return true;
      }
    });
  }

  setPickedSubActionsBank = (items: any[]) => {
    const newSubActions: Item[] = [];
    this.state.selectedActions.forEach(selectedActionElm => {
      items.forEach(bankItem => {
        newSubActions.push({
          id: bankItem.id,
          name: bankItem.name,
          parent_id: selectedActionElm.id,
        })
      })
    })
    this.setState({ selectedSubActions: [], subActions: newSubActions })
  }

  setPickedValuesBank = (items: any[]) => {
    const newValues: Item[] = [];
    this.state.selectedActions.forEach(selectedActionElm => {
      items.forEach(bankItem => {
        newValues.push({
          id: bankItem.id,
          name: bankItem.name,
          parent_id: selectedActionElm.id,
        })
      })
    })
    this.setState({ selectedValues: [], values: newValues })
  }

  setPickedReasonsBank = (items: any[]) => {
    const newReasons: Item[] = [];
    this.state.selectedActions.forEach(selectedActionElm => {
      items.forEach(bankItem => {
        newReasons.push({
          id: bankItem.id,
          name: bankItem.name,
          parent_id: selectedActionElm.id,
        })
      })
    })
    this.setState({ selectedReasons: [], reasons: newReasons })
  }

  setPickedSubReasonsBank = (items: any[]) => {
    const newSubReasons: Item[] = [];
    this.state.selectedActions.forEach(selectedActionElm => {
      items.forEach(bankItem => {
        newSubReasons.push({
          id: bankItem.id,
          name: bankItem.name,
          parent_id: selectedActionElm.id,
        })
      })
    })
    this.setState({ selectedSubReasons: [], subReasons: newSubReasons })
  }

  part2ClickNext = () => {
    const { part2StepIndex, selectedActions } = this.state;
    if (part2StepIndex === 0 && !selectedActions.length) return;
    if (part2StepIndex === 4) {
      if (this.state.isUpdate) {
        return this.setState({ showUpdateKeyboardModal: true })
      } else {
        return this.savePart2Data();
      }
    }
    if (part2StepIndex <= 3) this.setState({ part2StepIndex: part2StepIndex + 1 });
  }

  hideUpdateKeyboardModal = () => {
    this.setState({ showUpdateKeyboardModal: false })
  }

  saveUpdateKeyboardModal = () => this.savePart2Data();

  closeSnackBar = () => {
    this.setState({ openSnackBar: false });
  }

  onCloseInformModalPress = () => {
    this.setState({
      informModal: {
        ...this.state.informModal,
        show: false,
      }
    });
    this.props.navigation.navigate("Cfcustomkeyboards");
  }

  getCreateKeyboardLabel = () => {
    const { part2StepIndex, isUpdate } = this.state;
    if (part2StepIndex < 4) return "Next";
    if (isUpdate) return "Update Keyboard";
    return "Create New Keyboard";
  }

  part2SubmitBtnClass = () => {
    const label = this.getCreateKeyboardLabel();

    if (label === "Next") {
      return "next-btn"
    }

    if (label === "Update Keyboard") {
      return "update-btn"
    }

    if (label === "Create New Keyboard") {
      return "finish-btn"
    }
  }
  // Customizable Area End
}
