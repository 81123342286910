import React from "react";

// Customizable Area Start
import { Box, Button, Dialog, Divider, Grid, IconButton, InputAdornment, Snackbar, Tab, Tabs, ThemeProvider, Tooltip, Typography, createTheme, styled } from "@material-ui/core";
import UserProfileController, { Props } from "./UserProfileController";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { addIcon, deleteIcon, calendarIcon, greenTick, defaultAvatar, eyeOff, eyeOn } from "./assets";
import InputField from "../../../components/src/InputField.web";
import DatePicker, { DateObject } from "react-multi-date-picker";
import DropDownSp from "../../../components/src/DropDownSp.web";
import ClearIcon from '@material-ui/icons/Clear';
import { Close } from "@material-ui/icons";
import { group_logo } from "../../landingpage/src/assets";


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        display:"inline-block",
        backgroundColor:"#1D1D1F",
        borderRadius:10,
      },
      indicator: {
        height: 1,
        backgroundColor: "#FF881A",
        display:"none"
      },
    },
    MuiTab: {
      root: {
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing:" -0.015em",
        color:"white",
        textAlign: "center",
        "@media (min-width:600px)":{
          minWidth:99,
        },
        '&.Mui-selected': {
          color: "#1D1D1F",
          backgroundColor:"#FF881A",
          borderRadius:10,
        },
      },
      textColorInherit:{
        color:"white",
        opaciity:1,
        '&.Mui-selected': {
          color: "#1D1D1F",
        },
      }
    },
  }
})
// Customizable Area End

export default class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSplash() {
    return (
      <Box
        style={{
          position:"absolute",
          height: "100%",
          width:"100%",
          zIndex:10,
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:"#1D1D1F"
        }}
      >
        <img src={group_logo} width={280} height={140} alt="bg-spinner" />
      </Box>
    )
  }
  renderNextPaymentDue=()=>{
    return (this.state.userRole!=="coach"&&<Box className="payment-due-wrapper">
                <Typography component="span" className="next-payment-due">
                  Next Payment Due:
                </Typography>

                <Typography component="span" className="due-date">
                  {this.state.subscriptionDueDate.validUntil||"No Active Plan"}
                </Typography>
              </Box>)
  }
  renderBuyMoreButton=()=>{
    return (
      this.state.userRole!=="coach"?<Box className="buy-more-btn" onClick={this.goToUserSubscriptions} data-test-id="buy-more">
                    <Typography component="span" className="buy-more-text">
                      Buy More
                    </Typography>
                  </Box>:null
    )
  }
  renderGenderField=()=>{
    return (
              <Box className="input-field-container">
                          <Typography
                            className="label-container"
                            children="Gender"
                          />
                             <Box className="genderContainer">
                          <Button
                             disabled={!this.state.onEdit}
                            variant="contained"
                            data-test-id='gender-btn-male'
                            className={`gender-btn ${this.state.isMaleBtnChecked
                              ? "gender-btn-checked"
                              : "gender-btn-unchecked"
                              }`}
                            onClick={() => this.handleGender("male")}
                          >
                            M
                          </Button>
                          <Button
                            disabled={!this.state.onEdit}
                            data-test-id='gender-btn-female'
                            variant="contained"
                            className={`gender-btn ${this.state.isFemaleBtnChecked
                              ? "gender-btn-checked"
                              : "gender-btn-unchecked"
                              }`}
                            onClick={() => this.handleGender("female")}
                          >
                            F
                          </Button>
                          <InputField
                            disabled={!this.state.onEdit}
                           data-testId="inputChangeGender"
                            isHideMsgError={true}
                            className="MuiFilledInput-underline gender-input-box"
                            onChange={(event: {target: {value: string}}) => this.handleChangeGender(event)}
                            placeholder="Other please specify"
                            type="text"
                            value={(!this.state.isMaleBtnChecked && !this.state.isFemaleBtnChecked)?this.state.formValue.gender:''}
                          />

                        </Box>
                        
                        <Box>
                          {<span className="validationMsg" >{this.state.errorMessageOn.gender ? this.state.errorMessageOn.gender : ""}</span>}
                        </Box>
                        </Box>
    )
  }
  renderEditProfile=()=>{
    return <Box className="content">

    <Box className="user-avatar-upload">
      <img src={ this.state.userAvatar || defaultAvatar } alt="Avatar" width={122} height={122} style={{ borderRadius: '50%' }} />

      <Box className="upload-avatar-form">
        <Typography
          component="span"
          className="upload-avatar-title"
        >
          User Picture
        </Typography>

        <Box className="upload-btn" onClick={this.handleClickChooseFile} 
          data-test-id="upload-btn">
          <Typography component="span" className="upload-btn-text">
            Choose file...
          </Typography>

          <input data-test-id="file-picker" ref={this.fileInputRef} type="file" id="file-input" style={{ display: 'none' }} onChange={this.handleFileChange} />

          <img src={addIcon} alt="Add" width={18.69} />
        </Box>

        <Typography
          component="span"
          className="supported-format">
          <Typography component="span" 
            className="text-white"
            >
            Available Formats
          </Typography>{" "}
          (JPEG | JPG | PNG | GIF | TIFF | TIF | BMP | SVG | RAW | PSD | EPS)
        </Typography>
      </Box>
    </Box>
    <Box marginTop={4} marginLeft={1.5}>
    <Typography component="span" className="content-title" id="edit-profile">
              Profile Details
      </Typography>
    </Box>
    <Box className="profile-form">
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} className="input-field-col">
            <Box className="input-field-container">
            <Typography className="label-style">
               Full Name
              </Typography>
              <InputField
                className="MuiFilledInput-underline inputField"
                name="fullName"
                type="text"
                value={this.state.formValue.fullName}
                onChange={this.changeHandler}
                data-test-id="fullname-input"
                validationMsg={this.state.errorMessageOn.fullName}
                disabled={!this.state.onEdit}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} className="input-field-col">
            <Box className="input-field-container">
            <Typography className="label-style">
                Email
              </Typography>
              <InputField
                className="MuiFilledInput-underline inputField"
                name="email"
                type="email"
                value={this.state.formValue.email}
                disabled={!this.state.onEdit}
                validationMsg={this.state.errorMessageOn.email}
                onChange={this.changeHandler}
                data-test-id="email-input"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="input-field-col">
            <Box className="input-field-container">
            <Typography className="label-style">
                Team Name
              </Typography>
              <InputField
                data-test-id="team-name-input"
                className="MuiFilledInput-underline inputField"
                name="teamName"
                type="text"
                value={this.state.formValue.teamName}
                onChange={this.changeHandler}
                validationMsg={this.state.errorMessageOn.teamName}
                disabled={!this.state.onEdit}
              />
            </Box>
          </Grid>
          
          <Grid item xs={12} md={6} className="input-field-col" style={{marginTop:'6px'}}>
            <Box className={`input-field-container${this.state.onEdit ? "" : " hide-calendar-icon"}`}>
              <Typography className="label-style">
                Date of Birth
              </Typography>


              {/* istanbul-ignore-next */}
              <DatePicker
                data-test-id="date-picker"
                format="MM/DD/YYYY"
                minDate={this.getDateRange().minDate}
                maxDate={this.getDateRange().maxDate}
                value={this.state.formValue.dateOfBirth}
                onChange={this.handleDateInputField}
                placeholder="MM/DD/YYYY"
                render={(value,openCalendar)=> <div
                  className="input-date"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <input readOnly value={value || ""} onClick={openCalendar} disabled={!this.state.onEdit} />
                  <IconButton onClick={openCalendar} className="calendar-icon" disabled={!this.state.onEdit}>
                    <img src={calendarIcon} className="calendar-icon-img" />
                  </IconButton>
                </div> }
                inputClass="custom-input"
                arrow={false}
                disabled={!this.state.onEdit}
                currentDate={new DateObject(new Date(this.state.initialDateOpen))}
              />

              <Box>
                {
                  <span
                    className="validationMsg"
                    style={{
                      visibility: this.state.errorMessageOn
                        .dateOfBirth
                        ? "visible"
                        : "hidden",
                    }}
                  >
                    {this.state.errorMessageOn.dateOfBirth
                      ? this.state.errorMessageOn.dateOfBirth
                      : "error"}
                  </span>
                }
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} className="input-field-col">
            {this.renderGenderField()}
          </Grid>

          <Grid item xs={12} md={6} className="input-field-col">
            <Box className="input_field_container">
            <DropDownSp
                value={this.state.formValue.country}
                data-test-id="countryDropdown"
                label="Select Country"
                option={this.state.countryData.map((country) => ({
                  value: country.name,
                  label: country.name,
                }))}
                name="country"
                handleChangeFunction={this.handleChangeCountry}
                isDisabled={!this.state.onEdit}
                validationMsg={this.state.errorMessageOn.country}/>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className="inputFieldCol">
            <Box className="input_field_container">
            <DropDownSp
              value={this.state.formValue.state}
              data-test-id="stateDropdown"
              label="Select State"
              option={this.state?.statesName.map((state) => ({
                value: state,
                label: state,
              }))}
              isDisabled={!this.state.onEdit}
              name="state"
              handleChangeFunction={this.handleChangeState}
              validationMsg={this.state.errorMessageOn.state}
            />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            className="bottom-btn-container"
          >
              {this.state.onEdit && (<Button
                data-test-id='back-btn'
                variant="outlined"
                className="back-btn"
                onClick={() => this.setState({ onEdit: false })}
              >
                Back
              </Button>)}

            <Box className="edit-btn" onClick={this.handleEditProfile} data-test-id="edit-btn" style={{ backgroundColor: this.state.onEdit ? "#FF881A" : "#252527" , width:'200px', height:'55px', textAlign:'center'}}>
              <Typography
                component="span"
                className="edit-btn-text"
              >
                {this.state.onEdit ? "Save" : "Edit Profile"}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Box>
  }
  renderProfileDetails=()=>{
    return <Box style={{display:"flex",flexDirection:"column",gap:32}}>
      <Typography component="span" className="content-title">
              Profile Details
      </Typography>
      <div className="profile-card">
        <img src={this.state.userAvatar||defaultAvatar} alt="Profile Picture" className="profile-picture"/>
        <div className="profile-info">
            <div className="profile-role">{this.state.userRole}</div>
            <div className="profile-name">{this.state.formValue.fullName}</div>
            <div className="profile-email">{this.state.formValue.email}</div>
        </div>
        </div>
        <div className="profile-details-card">
        <div className="profile-detail">
            <span className="detail-title">Full Name</span>
            <span className="detail-value">{this.state.formValue.fullName}</span>
        </div>
        <div className="profile-detail">
            <span className="detail-title">Username</span>
            <span className="detail-value">{this.state.user_name}</span>
        </div>
        <div className="profile-detail">
            <span className="detail-title">Role</span>
            <span className="detail-value"style={{textTransform:"capitalize"}}>{this.state.userRole}</span>
        </div>
        <div className="profile-detail">
            <span className="detail-title">Gender</span>
            <span className="detail-value" style={{textTransform:"capitalize"}}>{this.state.formValue.gender}</span>
        </div>
        <div className="profile-detail">
            <span className="detail-title">Email</span>
            <span className="detail-value">{this.state.formValue.email}</span>
        </div>

        {(!!this.state.esport_title)&&<div className="profile-detail">
            <span className="detail-title">Esports Title</span>
            <span className="detail-value">{this.state.esport_title}</span>
        </div>
        }
       {(!!this.state.in_game_role_name) && <div className="profile-detail">
            <span className="detail-title">In-game Role</span>
            <span className="detail-value">{this.state.in_game_role_name}</span>
        </div>}
        {(this.state.userRole==="coach"||this.state.userRole==="team admin")&&<div className="profile-detail">
            <span className="detail-title">Team Name</span>
            <span className="detail-value">{this.state.formValue.teamName}</span>
        </div>}
        {(!!this.state.sport_title) && <div className="profile-detail">
            <span className="detail-title">Sports Title</span>
            <span className="detail-value">{this.state.sport_title}</span>
        </div>
        }
        {(!!this.state.sport_position) && <div className="profile-detail">
            <span className="detail-title">Position</span>
            <span className="detail-value">{this.state.sport_position}</span>
        </div>
        }
        <div className="profile-detail">
            <span className="detail-title">Country</span>
            <span className="detail-value">{this.state.formValue.country}</span>
        </div>
        <div className="profile-detail">
            <span className="detail-title">State</span>
            <span className="detail-value">{this.state.formValue.state}</span>
        </div>
        <div className="profile-detail">
            <span className="detail-title">DOB</span>
            <span className="detail-value">{this.state.formValue.dateOfBirth}</span>
        </div>
    </div>
    </Box>
  }
  renderChangePasswordSection=()=>{
    return <Box style={{display:"flex",flexDirection:"column",gap:32}}>
    <Typography component="span" className="content-title">
            Change Password
    </Typography>
    <div style={{display:"flex",alignItems:"center",gap:"20px 64px",flexWrap:"wrap"}}>
         <Grid item xs={12} md={6} className="inputFieldCol" style={{flex:"0 1 418px"}}>
                      <InputField
                          data-test-id="txtInputCurrentPassword"
                          label="Old Password"
                          name="currentPassword"
                          type= {
                            !this.state.enableCurrentPassword ? "password" : "text"
                          }
                          className={`MuiFilledInput-underline inputField ${this.state.errorMessageOn.currentPassword?"errorField":""} `}
                          onChange={this.changeHandler}
                          validationMsg={this.state.errorMessageOn.currentPassword}
                          iconChangeState={this.state.enableCurrentPassword}
                          value={this.state.formValue.currentPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="togglePassword"
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowCurrentPassword}
                                  edge="end"
                                >
                                 {!this.state.enableCurrentPassword?( <img src={eyeOff} width={32}/>
                                  ) :(<img src={eyeOn} width={32}/>)}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        </Grid>
                        <Grid item xs={12} md={6} className="inputFieldCol" style={{flex:"0 1 418px"}}>
                      <InputField
                         data-test-id="txtInputNewPassword"
                          label="New Password"
                          name="newPassword"
                          type= {
                            !this.state.enableNewPassword ? "password" : "text"
                          }
                          className={`MuiFilledInput-underline inputField ${this.state.errorMessageOn.newPassword?"errorField":""} `}
                          validationMsg={this.state.errorMessageOn.newPassword}
                          iconChangeState={this.state.enableNewPassword}
                          value={this.state.formValue.newPassword}
                          onChange={this.changeHandler}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="togglePassword"
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowNewPassword}
                                  edge="end"
                                >
                                  {!this.state.enableNewPassword?( <img src={eyeOff} width={32}/>
                                  ) :(<img src={eyeOn} width={32}/>)}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        </Grid>
                        <Grid item xs={12} md={6} className="inputFieldCol" style={{flex:"0 1 418px"}} >
                      <InputField
                          data-test-id="txtInputConfirmPassword"
                          label="Confirm Password"
                          name="confirmPassword"
                          type= {
                            !this.state.enableConfirmPassword ? "password" : "text"
                          }
                          value={this.state.formValue.confirmPassword}
                          className={`MuiFilledInput-underline inputField ${this.state.errorMessageOn.confirmPassword?"errorField":""} `}
                          onChange={this.changeHandler}
                          validationMsg={this.state.errorMessageOn.confirmPassword}
                          iconChangeState={this.state.enableConfirmPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  data-test-id="togglePassword"
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowConfirmPassword}
                                  edge="end"
                                >
                                   {!this.state.enableConfirmPassword?( <img src={eyeOff} width={32}/>
                                  ) :(<img src={eyeOn} width={32}/>)}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        </Grid>
                        </div>
                        <Grid
                            item
                            xs={12}
                            md={12}
                            className="bottom-btn-container"
                          >
                      <Button className="edit-btn" onClick={this.handleChangePassword} data-test-id="change-pass" style={{ backgroundColor:  "#FF881A",color:"#1D1D1F"  }}>
                        <Typography
                          component="span"
                          className="edit-btn-text"
                          style={{color:"#1D1D1F"}}
                        >
                          Change Password
                        </Typography>
                      </Button>

          </Grid>
      
  </Box>
  }
  renderProfile=()=>{
     return (<Box className="left-content-wrapper">

            <Box className="content-container">
              <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",paddingBottom:32}}>
            <Tabs value={this.state.tab} onChange={this.onTabChange} data-test-id="tabs">
                        <Tab label="My Profile" value="profile" />
                        <Tab label="Edit Profile" value="edit" />
                        <Tab label="Change Password" value="password" />
                </Tabs>
                <Box className="delete-btn-container" onClick={this.onDeleteAccount} data-testid="delete-btn" data-test-id="delete-btn">
                  Delete Profile
                  <img src={deleteIcon} alt="Delete" width={32} />
                </Box>
              </div>
              {this.state.tab==="edit"&&this.renderEditProfile()}
              {this.state.tab==="profile"&&this.renderProfileDetails()}
              {this.state.tab==="password"&&this.renderChangePasswordSection()}
            </Box>
          </Box>)
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
      <StyledUserProfileContainer>
        <NavigationMenu
          navigation={this.props.navigation}
          id="NavigationMenu"
          currentTabProp="settings"
          userData={{
            userName:this.state.formValue.fullName,
            teamName:this.state.formValue.teamName,
            avatar:this.state.userAvatar
          }}
          profileUpdate={this.state.isProfileUpdated}
          />
        
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={this.state.toastOpen}
          onClose={this.hideToast}
          autoHideDuration={3000}
        >
          <StyledSnackbarContent>
            <Typography component="span">
              {this.state.toastMessage}

              {this.state.toastSuccess ? <img src={greenTick} width={33} alt="success" /> : <ClearIcon color="error" style={{ width: '33px' }} />}
            </Typography>
          </StyledSnackbarContent>
        </Snackbar>

        <Dialog
          open={this.state.deleteAccountModal}
          PaperProps={{
            style: {
              border: "unset",
              margin: 'auto',
              background: "transparent",
              maxWidth: "unset"
            },
          }}
          BackdropProps={{
            style: {
              backdropFilter: "blur(5px)", // Adjust the pixel value to increase or decrease the blur effect
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Adjust the background color and opacity
            }
          }}
        >
          <StyledFolderModal style={{width: '780px'}}>
            <IconButton
              data-test-id="on-close-delete-modal"
              style={{ cursor: "pointer" }}
              onClick={this.closeDeleteAccount}
              aria-label="close">
              <Close style={{ color: "#676767" }} />
            </IconButton>

            <Typography variant="h6">Delete Account!</Typography>
            
            <Typography variant="body1" style={{marginTop: '24px'}}>
              Are you sure, you want to delet this Account ?
            </Typography>

            <Typography variant="body1" style={{marginTop: '24px'}}>
              This can not be undone.
            </Typography>
            
            <Divider style={{ width: '496px', margin: "24px auto", backgroundColor: '#FF881A',height:2 }} />
            
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button
                data-test-id="close-delete-modal"
                variant="outlined"
                style={{
                  borderRadius: 8,
                  color: "#FF881A",
                  borderColor: "#FF881A",
                  fontFamily: "Montserrat",
                  fontSize: 18,
                  width: "200px",
                  padding: "10px 16px 10px 16px",
                  gap: 8,
                  fontWeight: 600,
                  textTransform: 'none',
                  lineHeight: "22px",
                  height: '55px',
                  cursor: "pointer",
                }}
                onClick={this.closeDeleteAccount}
              >
                Cancel
              </Button>
              <Button
                data-testid="delete-account"
                variant="contained"
                style={{
                  backgroundColor: "#FF1919",
                  color: "#1D1D1F",
                  borderRadius: 8,
                  fontSize: 18,
                  padding: "10px",
                  gap: 8,
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  lineHeight: "22px",
                  width: "200px",
                  height: '55px',
                  marginLeft: '24px',
                  cursor: "pointer",
                  textTransform: 'none'
                }}
                onClick={this.handleClickDelete}
              >
                Delete
              </Button>
            </div>
          </StyledFolderModal>
        </Dialog>

        <Box className="content-wrapper">
        { this.state.loadingScreen && this.renderSplash() }
         {this.renderProfile()}

          {!this.state.isInvitedUser&&<Box className="right-content-wrapper">

            <Box className="content-container d-flex flex-col h-full">
              <div style={{display:"flex",justifyContent:"space-between"}}>
            <Typography component="span" className="subscription-title">
              Your Subscription
            </Typography>
            {this.renderBuyMoreButton()}
              </div>
            {this.state.totalStorage ? ( <div style={{display:"flex",flexDirection:"column",gap:20,marginTop:50}}>
              <Box className="my-storage-wrapper">
                  <Box className="my-storage-info">
                    <Typography
                      component="span"
                      className="my-storage-progress-title"
                      >
                      Team Storage
                    </Typography>
                    <Box className="used-space-info">
                      <Box style={{ display: "flex",alignItems:"center" }}>
                        <Box className="orange-box" />
  
                        <Typography className="used-space-text" component="span">
                          Used
                        </Typography>
                      </Box>
  
                      <Box style={{ display: "flex", alignItems:"center" }}>
                        <Box className="grey-box" />
  
                        <Typography className="used-space-text" component="span">
                          Total space
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                <Box className="my-storage">
                  <svg width={"100%"} height={20.1} style={{borderRadius:10}}>
                    <rect
                      id="track"
                      x="0"
                      y="0"
                      width="100%"
                      height={20.1}
                      fill="#313132"
                    />
                    <rect
                      fill="#FE881A"
                      width={`${this.state.storageUsedPercentage}%`}
                      height="20.1"
                    />
                  </svg>
                   <Typography component="span" className="my-storage-text">
                    {this.state.storageUsedPercentage}% Full
                  </Typography>
                </Box>

              </Box>

              <Box className="storage-by-month-wrapper">
                <Typography component="p" className="chart-title">
                  Storage Usage By Month
                </Typography>

                <Box className="chart">
                  {this.state.chartData.map((item) => (
                    <Box className="chart-column" key={item.id}>
                      <Tooltip title={`${item.initialUsage}GB`} placement="top">
                        <Box
                          style={{
                            width: "100%",
                            height: `calc(100% / 100 * ${item.usage})`,
                            borderRadius: "8px",
                            backgroundColor:
                            "#313132",
                            position: "relative",
                          }}
                        >
                          {item.id === "5" && (
                            <Box
                              style={{
                                position: "absolute",
                                top: "0",
                                left: "100%",
                                width: `calc(100% * 12 - (100% * ${Number(
                                  item.id
                                )}))`,
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                            </Box>
                          )}
                        </Box>
                      </Tooltip>

                      <Typography component="span" className="month-text">
                        {item.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              {this.renderNextPaymentDue()}
              </div>):(<div style={{display:"flex",gap:20,marginTop:50,justifyContent:'center',alignContent:'center'}}>
                <Typography
                      component="span"
                      className="my-storage-progress-title"
                      >
                      No Subscriptions
                    </Typography>
                </div>)}
           
            </Box>
          </Box>}
        </Box>
      </StyledUserProfileContainer>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledSnackbarContent = styled(Box)(({ theme }) => ({
  width: 'fit-content',
  boxSizing: 'border-box',
  height: '64px',
  marginTop: '105px',
  borderRadius: '42px',
  padding: '0 16px 0 28px',
  backgroundColor: '#18181D',

  "& span": {
    height: '100%',
    fontFamily: 'Poppins',
    display: 'inline-flex',
    alignItems: 'center',
    lineHeight: '24.69px',
    justifyContent: 'space-between',
    fontWeight: '400',
    fontSize: '16px',
    color: '#CFD1D4',
    width: '100%'
  },

  "& img": {
    marginLeft: '5px'
  }
}))

const StyledFolderModal = styled(Box)(({ theme }) => ({
  background: "#1D1D1F",
  borderRadius: "10px",
  boxShadow: "-5px 4px 12px 0px #00000040 inset",
  padding: "40px",
  position: "relative",

  "& .MuiPaper-root": {
    maxWidth: "800px",
    // width: "700px",
  },

  "& .MuiIconButton-root": {
    height: "24px",
    width: "24px",
    padding: "4px",
    position: "absolute",
    top: "16px",
    right: "16px",
  },

  "& .MuiTypography-h6": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "28px",
    textAlign: "center",
    color: "#CFD1D4",
    marginBottom: 32,
  },

  "& .MuiTypography-body1": {
    fontFamily: "Poppins",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "26px",
    textAlign: "center",
    color: "#676767",
    // margin: "24px auto",
    // width: "700px"
  },

}))

const StyledUserProfileContainer = styled(Box)(() => ({
  minHeight: "100vh",
  paddingBottom: "20px",
  boxSizing: "border-box",
  overflow: "hidden",
  position:"relative",
// View Profile Details

"& .profile-card":{
  display: "flex",
    alignItems: "center",
    gap:16,
    borderRadius: "10px",
},
  "& .profile-picture":{
    width: "140px",
    height: "140px",
    borderRadius: "50%",
    marginRight: "20px",
  },


  "& .profile-info":{
    display: "flex",
    flexDirection: "column",
  },

  "& .profile-role":{
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "32px",
    letterSpacing:" -0.02em",
    color:"#FF881A",
    textTransform:"capitalize",

  },

  "& .profile-name":{
    fontFamily: "Poppins",
    fontSize: "25px",
    fontWeight: 500,
    lineHeight: "37.5px",
    letterSpacing:" 0.01em",
    color:"#CFD1D4"
  },

  "& .profile-email":{
    fontFamily: "Poppins",
    fontSize: "22px",
    fontWeight: 500,
    lineHeight: "22.29px",
    letterSpacing:"-0.02em",
    color:"#CFD1D4",
    textAlign:"left",
  },
  "& .profile-details-card":{
    display:"flex",
    flexWrap:"wrap",
    justifyContent:"flex-start",
    gap:35,
    maxWidth:950
  },
  "& .profile-detail": {
    display:"flex",
    flexDirection:"column",
    minWidth:200,
    gap:4,
},
"& .detail-title": {
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "28px",
  color:"#676767",
  textAlign:"left",
},

"& .detail-value": {
  fontFamily: "Poppins",
  fontSize: "20px",
  fontWeight: 400,
  lineHeight: "24px",
  color:"#CFD1D4",
  textAlign:"left",
},


  // End View Profile Details
  "& .input-field-container.hide-calendar-icon .input-date input": {
    cursor:"not-allowed",    
  },

  "& .inputField.errorField": {
    border: "1px solid #FF1919",
  },
  "& .MuiTypography-root .lableStyle":{
    fontFamily: "DM Sans",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing:" -0.02em",
    textAlign: "left",
    color:"#555555",
  },

  "& .input-date input": {
    paddingLeft: '24px !important',
    background: "#252527",

  },

  "& .MuiFilledInput-input": {
    paddingLeft: '30px !important',
    boxShadow: "4px 4px 4px 0px #00000040 inset",
    color:"#CFD1D4",
    fontFamily:" DM Sans",
    fontSize: "20px",
    fontWeight: 400,
    letterSpacing: "-0.02em",
    textAlign: "left",
  },

  "& .calendar-icon": {
    display: 'hidden'
  },

  "& .MuiFilledInput-underline": {

    "& .Mui-disabled":{
      cursor:"not-allowed"
    }
  },

  "& .content-title":{
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 400,
    lineHeight: "28px",
    letterSpacing:" -0.02em",
    color:"#CFD1D4"
  },

  "& .subscription-title":{
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "22.29px",
    letterSpacing:" -0.02em",
    color:"#CFD1D4"
    
  },
  

  "& .input-date": {
    maxWidth: '100%'
  },

  "& .select-container": {

    fontFamily: 'DM Sans',
    background: "#252527",
  },

  '& .select-container [class*="-control"]': {
    paddingLeft: '22px',
    boxShadow: "4px 4px 4px 0px #00000040 inset",
  },
  '& .select-container [class*="-control"] [class*="-singleValue"]':{
    color:"#CFD1D4",
    fontFamily:" DM Sans",
    fontSize: "20px",
    fontWeight: 400,
    letterSpacing: "-0.02em",
    textAlign: "left",
  },

  "& .content-wrapper": {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: "32px",
    boxSizing: "border-box",
    padding: "32px",

  },

  // left box
  "& .left-content-wrapper": {

    boxSizing: "border-box",
    width:"100%"
  },

  "& .content-container": {
    borderRadius: "10px",
    backgroundColor: "#252527",
    padding: "56px 62px",
    boxSizing: "border-box",
    height:"100%"
  },

  "& .content": {
    width: "100%",
    padding: "18px 46px 123px 40px",
    borderRadius: "20px",
    backgroundColor: "#1E1E1F",
    position: "relative",
    boxSizing: "border-box",
    boxShadow: "6px 9px 22px 0px #0000004D inset",
  },

  "& .delete-btn-container": {
    padding: '6px',
    alignItems:"center",
    gap:8,
    fontFamily:"Montserrat",
    display:"flex",
    color:"red",
    fontSize:16,
    fontWeight:400,
    borderRadius: '10px',
    backgroundColor: '#1D1D1F',
    boxSizing: 'border-box',
    cursor: 'pointer',
    border:"1px solid red"
  },

  "& .user-avatar-upload": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    boxSizing: "border-box",
  },

  "& .upload-avatar-form": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "8px",
    boxSizing: "border-box",
  },

  "& .upload-avatar-title": {
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "20px",
    color: "white",
    marginLeft:5,
    boxSizing: "border-box",
  },

  "& .upload-btn": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "175px",
    padding: "8px 7px 9px 19px",
    borderRadius: "10px",
    border: "0.6px solid #FF881A",
    boxSizing: "border-box",
    cursor: 'pointer'
  },

  "& .upload-btn-text": {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24.69px",
    color: "#CFD1D4",
    boxSizing: "border-box",
  },

  "& .supported-format": {
    maxWidth: "280px",
    fontFamily: "Montserrat",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing: "0.10000000149011612px",
    color: "#8EA2AB",
    boxSizing: "border-box",
  },

  "& .text-white": {
    color: "white",
  },

  "& .input-field-col": {
    padding: "0 12px",
    boxSizing: "border-box",
  },

  "& .input-field-container .select-container .css-lokebmr-indicatorSeparator":
  {
    display: "none",
  },

  "& .label-style": {
    fontFamily: "DM Sans",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing:" -0.02em",
    textAlign: "left",
    color:"#555555",
    marginBottom:6,
    marginLeft:8
  },

  "& .label-container": {
    fontFamily: "DM Sans",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "20px",
    letterSpacing:" -0.02em",
    textAlign: "left",
    color:"#555555",
    marginBottom:6,
    marginLeft:8
  },

  "& .genderContainer": {
    display: "flex",
    alignItems: "center",
    gap: '16px'
  },

  "& .gender-btn": {
    borderRadius: "10px",
    boxShadow: " 4px 4px 4px 0px #00000040 inset",
    fontSize: "16px",
    height: "34px",
    width: "34px",
    minWidth: "auto",
    cursor: "pointer",
    borderBottom: "1px solid #FF881A",
  },
  "& .gender-btn-checked": {
    background: "#FF881A",
    color:"white",
  },
  "& .gender-btn-unchecked": {
    background: "#1D1D1F",
    color: "#CFD1D4",
  },

  "& .genderContainer .MuiBox-root": {
    width: "100%",
    "& .MuiInputBase-root input::placeholder": {
      opacity: 1,
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "16px",
      color: "#CFD1D4"
    }
  },
  "& .no-border": {
    border: "none",
  },

  "& .gender-btn-selected": {
    color: "#FF881A",
  },

  "& .profile-form": {
    boxSizing: "border-box",
    marginTop: "30px",
  },

  // date picker css start
  "& .rmdp-top-class": {
    width: "300px",
  },
  "& .rmdp-calendar": {
    border: "1px solid #FF881A",
    backgroundColor: "#1D1D1F",
    boxShadow: "none",
    width: "100%",
  },
  "& .rmdp-day-picker > div:first-child": {
    width: "100%",
  },
  "& .rmdp-day": {
    color: "#CFD1D4",
    fontSize: "14px",
    fontFamily: `"Poppins", sans-serif`,
  },
  "& .rmdp-week-day": {
    color: "#CFD1D4",
    fontSize: "14px",
    fontFamily: `"Poppins", sans-serif`,
  },
  "& .rmdp-header-values": {
    color: "#FF881A",
    fontWeight: 700,
  },
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: "#FBB26E",
  },
  "& .rmdp-month-picker, .rmdp-year-picker": {
    color: "#FF881A",
    fontWeight: 700,
    backgroundColor: "#1D1D1F",
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight)": {
    backgroundColor: "#FF881A",
    boxShadow: "none",
    fontWeight: 500,
  },
  "& .rmdp-day:not(.rmdp-disabled, .rmdp-day-hidden) span:hover": {
    backgroundColor: "#FF881A",
  },
  "& .rmdp-day.rmdp-disabled": {
    cursor: 'not-allowed',
    color: "#7F7F7F !important"
  },
  "& .rmdp-arrow": {
    border: "solid #FF881A",
    borderWidth: "0 2px 2px 0",
  },
  "& .rmdp-arrow-container:hover": {
    backgroundColor: "transparent",
    boxShadow: "none",
    border: "none",
    borderWidth: "none",
  },

  "& .rmdp-container ": {
    width: "100%",
    color: "#CFD1D4",
    "& .input-date": {
      background: "#252527",
      boxShadow: "4px 4px 4px 0px #00000040 inset",
      borderRadius: "10px",
      borderBottom: "1px solid #FF881A",
      position: "relative",
    },
    "& .input-date input": {
      width: "100%",
      height: "50px",
      border: "unset",
      padding: "0 12px",
      color:"#CFD1D4",
    fontFamily:" DM Sans",
    fontSize: "20px",
    fontWeight: 400,
    letterSpacing: "-0.02em",
    textAlign: "left",
    boxShadow: "4px 4px 4px 0px #00000040 inset",
    backgroundColor: "#1D1D1F",
    borderRadius:"10px"

    },
    "& .input-date button": {
      position: "absolute",
      right: "0",
    },
    "& .input-date input:focus-visible": {
      outline: "unset",
    },
  },
  "& .icon-tabler-calendar-event ": {
    right: "16px !important",
  },
  "& .rmdp-input": {
    height: "56px",
    width: "100%",
    color: "#CFD1D4",
    border: "none",
    borderBottom: "1px solid #FF881A",
    // boxShadow: "4px 4px 4px 0px #00000040 inset",
    borderRadius: "10px",
    margin: "1px 0",
    padding: "2px 5px",
    fontSize: "16px",
    fontWeight: 400,
  },

  // datepicker css end

  "& .bottom-btn-container": {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
    justifyContent: "center",
    boxSizing: "border-box",
  },
  "& .back-btn":{
    color: "#FF881A",
    borderColor: "#FF881A",
    borderRadius: 8,
    fontSize: 18,
    padding: "10px 16px 10px 16px",
    gap: 8,
    fontFamily: "Montserrat",
    fontWeight: 600,
    lineHeight: "normal",
    width: "200px",
    height: '55px'
  },
  "& .edit-btn": {
    borderRadius: "10px",
    boxSizing: "border-box",
    cursor: "pointer",
    height: "47px",
  },

  "& .edit-btn-text": {
    height: "100%",
    display: "inline-flex",
    alignItems: "center",
    boxSizing: "border-box",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "-0.02em",
    color: "white",
  },

  "& .change-pw-btn": {
    borderRadius: "10px",
    backgroundColor: "#252527",
    boxSizing: "border-box",
    cursor: "pointer",
    height: "47px",
    padding: "0 10px",
  },

  "& .change-pw-btn-text": {
    height: "100%",
    display: "inline-flex",
    alignItems: "center",
    boxSizing: "border-box",
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "-0.02em",
    color: "white",
  },

  // right box
  "& .right-content-wrapper": {
    flexBasis: "calc(85% - 16px)",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  "& .d-flex": {
    display: "flex",
  },

  "& .flex-col": {
    flexDirection: "column",
  },



  "& .my-storage-wrapper": {
    width: "100%",
    boxSizing: "border-box",
    padding: "15px 24px 33px 25px",
    backgroundColor: "#1D1D1F",
    display:"flex",
    flexDirection:"column",
    gap:56

  },

  "& .my-storage": {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection:"column",
    alignItems: "center",
    gap:4
  },

  "& .my-storage-text": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "30px",
    color: "#CFD1D4",
    textAlign: "center",
    letterSpacing: "-0.015em",
  },



  "& .my-storage-info": {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    boxSizing: "border-box",
    marginTop: "8px",
  },

  "& .used-space-info": {
    display: "flex",
    height: "100%",
    justifyContent: "space-between",
    boxSizing: "border-box",
    gap: "10px",
  },

  "& .orange-box": {
    width: "20px",
    height: "20px",
    boxSizing: "border-box",
    backgroundColor: "#FF881A",
    borderRadius: "4px",
  },

  "& .grey-box": {
    width: "20px",
    height: "20px",
    boxSizing: "border-box",
    backgroundColor: "#252527",
    borderRadius: "4px",
  },

  "& .used-space-text": {
    height: "100%",
    boxSizing: "border-box",
    display: "inline-flex",
    alignItems: "center",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#CFD1D4",
    marginLeft: "10px",
    letterSpacing: "-0.015em",
    
  },

  "& .my-storage-progress-title": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "-0.015em",
    color:"#CFD1D4"
  },

  "& .buy-more-btn": {
    borderRadius: "10px",
    padding: "6px 10px",
    backgroundColor: "#FF881A",
    boxSizing: "border-box",
    cursor: "pointer",
    height: "36px",
  },

  "& .buy-more-text": {
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "1.08px",
    color:"#1D1D1F",
  },

  "& .h-full": {
    height: "100%",
  },

  "& .storage-by-month-wrapper": {
    width: "100%",
    boxSizing: "border-box",
    padding: "15px 24px 33px 25px",
    borderRadius: "10px",
    backgroundColor: "#1D1D1F",
  },

  "& .chart-title": {
    fontFamily: "Poppins",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "30px",
    color: "#CFD1D4",
    letterSpacing: "-0.015em",
  },

  "& .chart": {
    marginTop: "25px",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "230px",
  },

  "& .chart-column": {
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    gap: "4px",
    justifyContent: "end",
    width: "5.5%",
  },

  "& .month-text": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "15px",
    letterSpacing: "-0.015em",
    color: "#CFD1D4",
    textAlign:"center",
  },

  "& .max-usage": {
    whiteSpace: "nowrap",
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "20px",
    color: "#CFD1D4",
    marginTop: "-10px",
    marginLeft: "5px",
  },

  "& .payment-due-wrapper": {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    borderRadius: "10px",
    backgroundColor: "#1D1D1F",
  },

  "& .next-payment-due": {
    ffontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    letterSpacing: "-0.015em",
    color:"#CFD1D4"
  },

  "& .due-date": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "30px",
    color: "#FF881A",
  },

  '& .validationMsg': {
    fontFamily: 'Poppins',
    color: '#FF1919',
    float: 'left',
    fontWeight: 400,
    fonstSize: '1rem',
    margin: '6px 0'
  },
}));
// Customizable Area End