import React from "react";

// Customizable Area Start
import {
  Container,
  Box,Grid,
  Button,
  Typography,styled
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import {svg1,svg2,svg3,svg4,svg5,svg7,logo, defaultAvatar, settingIcon} from "./assets";
import AppBar from '@material-ui/core/AppBar';
import MenuItem from '@material-ui/core/MenuItem';
import Videos from "../../videos/src/Videos.web";
import Menu from '@material-ui/core/Menu';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import NotificationBell from '../../../components/src/Notification/NotificationBell.web'
const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 10,
    border: "1px solid #FF881A",
    marginTop: theme.spacing(8),
    width: 250,
    backgroundColor: "#1D1D1F",
    color:"#CFD1D4",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      [theme.breakpoints.down('lg')]: {
        width: 200,
      },
      [theme.breakpoints.down('md')]: {
        width: 150,
      },
      [theme.breakpoints.down('sm')]: {
        width: 100,
      },
      "& .MuiMenu-list": {
      padding: "4px 0",
      margin: "10px",
    },
    "& .MuiMenuItem-root": {
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        marginRight: theme.spacing(1.2),
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
        marginRight: theme.spacing(1),
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 8,
      },
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      "&:hover": {
        backgroundColor: "#FF881A",
        border: "1px solid #FF881A",
        borderRadius: 5,
        color: "#1D1D1F",
      },
    },
  },
}));
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  myProfile = () =>{
    return <Button data-test-id="dashboard"
        className={this.state.currentTab === "dashboard" ? "currentTabStyle" : "centerItems"}
        onClick={()=> { this.handleCurrentTab("dashboard"); this.props.navigation.navigate("Dashboard")}}>
        <Box className={this.state.currentTab === "dashboard" ?"myhoverSelected " :"myhover"}>
        <img src={svg1} className="gridItem3Img" /> 
        <Typography className="text">Dashboard</Typography></Box>
        </Button>}
        renderStats=()=>{
          return   <Box style={{ cursor: "pointer" }}>
            <Box className={this.state.currentTab === "stats" ? 
            "statsSelected" : "statsCurrent"}>
            <Box data-test-id="stats"
            style={{display:"flex",alignItems:"center",fontFamily:"poppins"}}
            id="demo-customized-button"
            aria-controls={this.state.anchorEl ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={this.state.anchorEl ? "true" : undefined}
            onClick={(event)=>this.handleClick(event,"stats")}>
            <img src={svg3} style={{paddingRight:5}} className = {this.state.currentTab === "stats" ? "statsFilter" : "gridItem3Img"}/>
            Stats</Box> </Box>
            </Box>
        }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { anchorEl2 } = this.state;
    const open2 = Boolean(anchorEl2);
    return (
     <StyledGridItem>
       <Box  sx={{ display:"flex",margin: "0 40px", alignItems:"center", justifyContent:"center", flexGrow: 1}}>
      <AppBar position="static" style={{background: "#252527",borderRadius:"10px"}}>
       <Grid container style={webStyle.gridContainer}>
        <Grid item xl={2} lg={2} md={2} sm={2}className="gridItem1">
          <img src={this.state.profile?.team_profile||logo} height={56} width={56} style={{borderRadius:"50%"}} />
          <Box style={{paddingLeft:10}}>
          <Typography className="logoItem1" >{this.state.profile?.team_name}</Typography>
          <Typography className="logoItem2" >{this.state.profile?.full_name}</Typography></Box>
        </Grid>
        <Grid item  xl={8} lg={8} md={8} sm={8} className="gridItem2">
        {this.myProfile()} 
          <Button data-test-id="video" 
          className={this.state.currentTab === "video" ? "currentTabStyle" : "centerItems"}
          onClick={()=>{this.handleCurrentTab("video"); this.props.navigation.navigate("ContentManagement")}}>
            <Box className={this.state.currentTab === "video" ?"myhoverSelected " :"myhover"}>
          <img src={svg2} className="gridItem3Img"/>
          <Typography className="text">Video Center</Typography></Box></Button>
        {this.renderStats()}
          <Button data-test-id="team"
          className={this.state.currentTab === "team" ? "currentTabStyle" : "centerItems"}
           onClick={()=>{this.navigateToTeam();}}> 
           <Box className={this.state.currentTab === "team" ?"myhoverSelected " :"myhover"}>
          <img src={svg4} className="gridItem3Img"/>
          <Typography className="text">My Team</Typography> </Box> 
          </Button>
          <Button onClick={()=>this.handleCurrentTab("schedule")} 
           data-test-id="schedule"
           className={this.state.currentTab === "schedule" ? "currentTabStyle" : "centerItems"}>
          <Box className={this.state.currentTab === "schedule" ?"myhoverSelected " :"myhover"}>
          <img src={svg5} className="gridItem3Img"/>
          <Typography className="text">Schedule</Typography> </Box>
          </Button>
          
        </Grid>
        <Grid item  xl={2} lg={2} md={2} sm={2} style={webStyle.gridItem3}>

        <Box 
          onClick={this.goToSetting.bind(this)}
          style={{ cursor: "pointer" }}
          data-test-id="setting-btn"
        >
          <img src={settingIcon} className="gridItem3Img"/>
        </Box>

        <Box style={{ cursor: "pointer" }}>
          <img src={svg7} className="gridItem3Img"/>
        </Box>

        <NotificationBell data-testid="notifications" onViewAll={this.goToNotifications} />
    
          <Typography>  
            <Button
            style={{backgroundColor:"#252527"}}
            id="profile-button"
            aria-controls={open2 ? "profile-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open2 ? "true" : undefined}
            variant="contained"
            disableElevation
            data-test-id="profileMenu"
            onClick={(event)=>this.handleClick2(event,"profile")}
          >
             <img alt="Remy Sharp" 
             src={(this.state.profile?.photo || defaultAvatar)} 
           className={this.state.currentTab === "profile" ? "selected" :"unSelected"}  
           style={{height:56,width:56,borderRadius:"50%"}}
             />
          </Button>
            <StyledMenu
              data-test-id="menu2"
              id="profile-menu"
              MenuListProps={{
                "aria-labelledby": "profile-button",
              }}
              anchorEl={anchorEl2}
              open={open2}
              onClose={this.handleClose2}
            >
               
              <MenuItem onClick={this.goToHelpCentre}data-test-id="menuClose21">
                Support
                </MenuItem>
              <MenuItem   data-test-id="menuClose22" onClick={this.handleClose2}>Switch Team</MenuItem>
              <MenuItem   data-test-id="menuClose23" onClick={() => this.handleLogout()}>Logout</MenuItem> 
            </StyledMenu>
            </Typography>

        </Grid>
       </Grid>
      </AppBar>
    </Box>
     </StyledGridItem>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const StyledGridItem = styled('div')(({ theme }) => ({
  '& .svg-container:hover .ring':{
    stroke:"#FF881A"
  },
  '& .selected':{
    border: "1px solid #FF881A"
  },
  '& .unSelected':{
    border: "1px solid #252527"

  },'& .unSelected:hover':{
    border: "1px solid #FF881A"
  },
  '& .statsSelected':{
    display:"flex",
    alignItems:"center",
    textTransform:"none",
    color:"#1D1D1F",
    backgroundColor:"#FF881A",
    borderRadius:10,
    fontSize:20,
    padding:"6px 16px",
    [theme.breakpoints.down('lg')]: {
      padding:"6px 12px",
      fontSize: 14,
    },
    [theme.breakpoints.down('md')]: { 
      padding:"6px 8px",
      fontSize: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 8,
    },
  },
  '& .statsBtn':{
    filter:"grayscale(255)",
    display:"flex",
    color: "#CFD1D4",
     backgroundColor: "#252527",
    textTransform:"none",
    padding:"6px 16px",
    fontSize:20,
    [theme.breakpoints.down('lg')]: {
      padding:"6px 12px",
      fontSize: 14,
    },
    [theme.breakpoints.down('md')]: { 
      fontSize: 12,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft:6,
      fontSize: 10,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft:4,
      fontSize: 8,
    },
  },
  '& .statsCurrent':{
    padding:"6px 16px",
    filter:"grayscale(255)",
    color: "#CFD1D4",
    backgroundColor: "#252527",
    textTransform:"none",
    fontSize:20,
    display:"flex",
    [theme.breakpoints.down('lg')]: {
      padding:"6px 12px",
      fontSize: 14,
    },
    [theme.breakpoints.down('md')]: { 
      fontSize: 12,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      paddingLeft:6,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 8,
      paddingLeft:4,
    },
  },
  '& .statsFilter':{
    paddingRight:5,
    width:"40px",
    filter:"brightness(0.3)",
    [theme.breakpoints.down('lg')]: {
      width:"35px",
    },
    [theme.breakpoints.down('md')]: {
      width:"25px",
    },
    [theme.breakpoints.down('sm')]: {
      width:"15px",
    },[theme.breakpoints.down('xs')]: {
      width:"15px",
    },
  },
  '& .statsCurrent:hover':{
    color:"#FF881A",
    filter:"brightness(100%)",
  },
  '& .centerItems':{
    alignItems:"center",
      display:"flex",
      textTransform:"none",
      color:"#CFD1D4",
  },
  '& .myhover':{
      display:"flex",
      alignItems:"center",
      filter:"grayscale(255)",
    },
    '& .myhoverSelected':{
      display:"flex",
      alignItems:"center",
      filter:"brightness(0.3)",
  },
    '& .myhover:hover':{
      display:"flex",
      alignItems:"center",
      filter:"brightness(100%)",
      color:"#FF881A"
    },
      '& .currentTabStyle': {
          color:"#1D1D1F",
          textTransform:"none",
          backgroundColor:"#FF881A",
          borderRadius:10,
      },
  '& .logoItem1': {
    fontWeight: 700,
    lineHeight: "30px",
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      lineHeight: "30px",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: "20px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      lineHeight: "15px",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 8,
      lineHeight: "10px",
    },
  },
  '& .logoItem2': {
    fontWeight:500,
    lineHeight: "16px",
    [theme.breakpoints.down('lg')]: {
      fontSize: 14,
      lineHeight: "14px",
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: "12px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      lineHeight: "10px",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 8,
      lineHeight: "8px",
    },
  },
  '& .moreIcon': {
    fontSize:"40px",
    paddingLeft:3,
   [theme.breakpoints.down('lg')]: {
     fontSize:"35px",
   },
   [theme.breakpoints.down('md')]: {
    fontSize:"25px",
   },
   [theme.breakpoints.down('sm')]: {
    fontSize:"15px",
   },
   [theme.breakpoints.down('xs')]: {
    fontSize:"15px",
   },
 },
 '& .logo':{
  [theme.breakpoints.down('sm')]: {
    width:"25px",
  },
  [theme.breakpoints.down('xs')]: {
    width:"20px",
  },
 },
  '& .gridItem3Img': {
     width:"40px",
    [theme.breakpoints.down('lg')]: {
      width:"35px",
    },
    [theme.breakpoints.down('md')]: {
      width:"25px",
    },
    [theme.breakpoints.down('sm')]: {
      width:"15px",
    },
    [theme.breakpoints.down('xs')]: {
      width:"15px",
    },
  },
  '& .text': {
    fontSize:20,
    paddingLeft:10,
    [theme.breakpoints.down('lg')]: {
      paddingLeft:10,
      fontSize: 14,
    },
    [theme.breakpoints.down('md')]: { 
      paddingLeft:8,
      fontSize: 12,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft:6,
      fontSize: 10,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft:4,
      fontSize: 8,
    },
},
  '& .gridItem2': {
      display:"flex",
      alignItems:"center",
      color: "#CFD1D4",
      justifyContent:"center",
      gap:20,
      [theme.breakpoints.down('lg')]: {
        fontSize: 14,
        gap:14,
      },
      [theme.breakpoints.down('md')]: { 
        fontSize: 12,
        gap:12,
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: 10,
        gap:10,
      },
      [theme.breakpoints.down('xs')]: {
        gap:8,
        fontSize: 8,
      },
  },
  '& .gridItem1' :{
    display:"flex",
    alignItems:"center",
    color: "#CFD1D4",
    fontSize:18,
    paddingLeft:10,
    [theme.breakpoints.down('md')]: {
      fontSize:14,
      paddingLeft:6,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize:10,
      paddingLeft:4,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize:8,
      paddingLeft:2,
    },
  }
}));
const webStyle = {
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },
  gridContainer:{
    height : 90,
    padding:15,
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },
  gridItem1:{
    display:"flex",
    alignItems:"center",
    color: "#CFD1D4",
    fontSize:18,
    paddingLeft:10,
  },
  gridItem3:{
    display:"flex",
    alignItems:"center",
    color: "#CFD1D4",
    justifyContent:"space-evenly"
  },
  centerItems :{
    display:"flex",
    alignItems:"center",
   texttransform:"none",
    color:"#CFD1D4",
  },
  currentTabStyle :{
  color:"#1D1D1F",
  texttransform:"none",
  backgroundColor:"#FF881A",
  borderRadius:10,
  }
};
// Customizable Area End
