import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Divider,
  IconButton,
} from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { defaultIcon,deleteIcon } from "../../../blocks/notifications/src/assets";

// Notification object type
interface Notification {
  text: string;
  link?: string;
  time: string;
  isRead?: boolean;
}

// Define the prop types for NotificationPopup
interface NotificationPopupProps {
  handleClose: () => void;
  onViewAll: () => void;
}

// Styles to match the screenshot
const useStyles = makeStyles((theme: Theme) => ({
  popoverContent: {
    backgroundColor: "#252527",
    borderRadius: 10,
    width: 440,
    border: "1px solid #FF881A",
  },
  notificationHeader: {
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #333",
    color: "#CFD1D4",
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  notificationHeaderText: {
    display: "flex",
    justifyContent: "space-between",
    color: "#CFD1D4",
    fontSize: 16,
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  notificationText: {
    color: "#CFD1D4",
    display: 'flex',
    flexDirection: 'column',
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: 14,
    lineHeight: "16px",
  },
  iconButton: {
    color: "#FF881A",
  },
  viewAllButton: {
    textAlign: "center",
    color: "#FF881A",
    padding: theme.spacing(2),
    borderRadius: 8,
    transition: "background-color 0.3s ease, transform 0.3s ease",  // Smooth transition
    "&:hover": {
      backgroundColor: "rgba(255, 136, 26, 0.1)", // Light hover effect
      transform: "scale(1.05)", // Slightly enlarge button on hover
    },
  },
  scrollableList: {
    maxHeight: 400,
    overflowY: "auto",
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #333',
    transition: "background-color 0.3s",
  },
  unreadDot: {
    position: "absolute",
    top: "-5px",  /* Adjust position based on your design */
    right: "-5px",
    width: "10px",  /* Size of the dot */
    height: "10px",
    backgroundColor: "orange",  /* Color of the unread dot */
    borderRadius: "50%",
    border: "2px solid #222", /* Optional: add a border to match the background */
  },
  link: {
    color: '#FF881A',
    cursor: 'pointer',
    fontSize: '14px',
    textDecoration:"underline",
  },
  notificationTime: {
    color: '#666', // Lighter gray for timestamps
    fontSize: '12px',
    marginTop: '4px',
  },
  iconWrapper:{
    position:"relative",
  },
}));

const NotificationPopup: React.FC<NotificationPopupProps> = ({ handleClose,onViewAll }) => {
  const classes = useStyles();

  const notifications: Notification[] = [
    {
      text: "Your account information has been successfully updated.",
      time: "2 seconds ago",
      isRead:true,
    },
    {
      text: "Jen(Player) has uploaded a new video for Cricket match. ",
      link: "Click here to view the video",
      time: "30 minutes ago",
    },
    {
      text: "New report for Harry Watson is now available for Soccer Game. ",
      link: "Click here to view the player's report",
      time: "12 July 2024 at 11:30 am",
    },
    {
      text: "You have received a new direct message from Jack Adison.",
      time: "12 July 2024 at 11:30 am",
    },
    {
      text: "Your account has been locked due to multiple failed login attempts. Please reset your password or contact support for assistance.",
      time: "12 July 2024 at 11:30 am",
      isRead:true,
    },
    {
      text: "A new scouting report for Football match has been created. ",
      link: "Click here to view the report",
      time: "12 July 2024 at 11:30 am",
    },
  ];

  return (
    <Box className={classes.popoverContent}>
      <Box className={classes.notificationHeader}>
        <Typography variant="h6" className={classes.notificationHeaderText}>Notifications</Typography>
        <Button style={{ color: "#CFD1D4",textTransform:"capitalize",fontFamily:"Poppins",fontSize:12,fontWeight:500 }} onClick={handleClose}>
          Mark all as read
        </Button>
      </Box>

      {/* Notifications List */}
      <List className={classes.scrollableList}>
        {notifications.length === 0 ? (
          <Box p={2} textAlign="center">
            <Typography variant="body1" style={{ color: "#666" }}>
              No new notifications
            </Typography>
          </Box>
        ) : (
          notifications.map((notification, index) => (
            <ListItem key={index} className={classes.listItem}>
                  <ListItemIcon >
                  <div className={classes.iconWrapper}>
                  <img src={ defaultIcon} alt="notification-icon" height={30} width={30} />
                  
                  {/* Conditionally show the dot for unread notifications */}
                  {!notification.isRead && <span className={classes.unreadDot}></span>}
                  </div>
                  </ListItemIcon>
                  <ListItemText
                    className={classes.notificationText}
                    primary={
                      <>
                      
                       <span className={classes.notificationText}>
                        {notification.text}
                        </span> 
                        {notification.link && (
                         
                            <span className={classes.link}>
                              {notification.link}
                            </span>
                          
                        )}
                      </>
                    }
                    secondary={
                      <Typography variant="body2" className={classes.notificationTime}>
                        {notification.time}
                      </Typography>
                    }
                  />
                  <IconButton className={classes.iconButton}>
                    <img src={deleteIcon} alt="delete button" height={24} width={24}/>
                  </IconButton>
                </ListItem>
          ))
        )}
      </List>

      <Divider />

      {/* View All Button */}
      <Box className={classes.viewAllButton} onClick={onViewAll}>
        <Button style={{ color: "#FF881A",fontSize:12,fontFamily:"Poppins",fontWeight:500}}>View All</Button>
      </Box>
    </Box>
  );
};

export default NotificationPopup;
