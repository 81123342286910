import React, { useState } from "react";
import { IconButton, Badge, Popover } from "@material-ui/core";
import {notificationBell} from '../../../blocks/notifications/src/assets'
import NotificationPopup from "./NotificationPopup.web";

interface NotificationBellProps {
  onViewAll: () => void;
}

const NotificationBell: React.FC<NotificationBellProps> = ({ onViewAll }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {/* Notification Bell Icon with Badge */}
      <IconButton onClick={handleClick}>
        <img src={notificationBell} height={32} width={32}/>
      </IconButton>

      {/* Notification Popup */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <NotificationPopup handleClose={handleClose} onViewAll={onViewAll} />
      </Popover>
    </>
  );
}

export default NotificationBell;

