Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Videos";
exports.labelBodyText = "Videos Body";
exports.exampleVideoURL = "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
exports.btnExampleTitle = "CLICK ME";
exports.videosInFolderURL = "bx_block_content_management/folder_creations";
exports.getMethod = "GET";
exports.postMethod = "POST";
exports.deleteMethod = "DELETE";
exports.putMethod = "PUT";

exports.getVideoDetailEndPoint = "bx_block_bulk_uploading/attachments";
exports.getEventsEndPoint = "bx_block_bulk_uploading/video_events";
exports.createEventsEndPoint = "bx_block_bulk_uploading/video_events";
exports.getUsersForAddEventEndPoint = "account_block/get_users_for_add_event"
exports.eventsEndPoint = "bx_block_bulk_uploading/add_event_comments";
exports.deleteEventEndPoint = "bx_block_bulk_uploading/add_event_comments";
exports.getActionsKeyBoardEndPoint = "bx_block_account_groups/groups/find_actions"
exports.jsonApiContentType = "application/json"
exports.finishMarkingEndPoint = "bx_block_bulk_uploading/add_event_comments/finish_marking";
exports.getDashboardDataEndpoint="account_block/dashboard"
exports.commentEventEndpoint = "bx_block_bulk_uploading/add_event_comments";
exports.bookmarkEndPoint = "bx_block_content_management/bookmarks";
exports.bulkDeleteVideosEndPoint = "bx_block_bulk_uploading/attachments/bulk_delete";
exports.markGameFolderEndPoint = "bx_block_content_management/folder_creations/mark_attribute";
exports.markVideosEndPoint = "bx_block_bulk_uploading/attachments/mark_attribute";
// Customizable Area End
